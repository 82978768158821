import React from 'react'
import { NavLink } from 'react-router-dom'
import BrandLogo from "../../assets/img/logo_small.png"
import Avatar from "../../assets/img/Arienne-avatar.png"
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

const Sidebar = () => {

	const logo = useSelector((state: RootState) => state.employer.Logo);
	const companyName = useSelector((state: RootState) => state.employer.CompanyDetails?.Name);
	const companyEmail = useSelector((state: RootState) => state.employer.Email);

	const resolveLogo = (suffix: string) => {
		return process.env.REACT_APP_S3_ENDPOINT + suffix;
	}

	return (
		<div className="d-flex flex-column employer-sidebar">
			<a href="/" className="nd-logo p-3">
				<img alt='Naukri Daddy Logo' src={BrandLogo} />
			</a>

			<ul className="nav nav-pills flex-column mt-1 mb-auto">
				<li className="nav-item">
					<NavLink to={'/employer/dashboard'} className="nav-link" aria-current="page">
						<i className="fa-solid fa-house"></i>
						Dashboard
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to={'/employer/profile'} className="nav-link" aria-current="page">
						<i className="ri-building-2-line"></i>
						Company Profile
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to={'/employer/applicants'} className="nav-link" aria-current="page">
						<i className="fa-solid fa-users"></i>
						All Applicants
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to={'/employer/jobs'} className="nav-link" aria-current="page">
						<i className="fa-solid fa-clipboard-list"></i>
						Job Listing
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to={'/employer/billing'} className="nav-link" aria-current="page">
						<i className="fa-regular fa-calendar"></i>
						Billing
					</NavLink>
				</li>

				<li className="nav-item">
					<NavLink to={'/employer/settings'} className="nav-link" aria-current="page">
						<i className="fa-solid fa-gear"></i>
						Settings
					</NavLink>
				</li>
				<li className="nav-item">
					<NavLink to={'/employer/faqs'} className="nav-link" aria-current="page">
						<i className="fa-regular fa-circle-question"></i>
						FAQ's
					</NavLink>
				</li>
			</ul>

			<div className="d-flex align-items-center px-2 mb-3">
				<div className='icon-naukary'>
					<img src={logo} alt="" width="48" height="48" className="rounded-circle me-2" /></div>
				<div className='about-naukary px-1'>
					<div className='heading m-0'>{companyName}</div>
					<span className='paragraph'>{companyEmail}</span>
				</div>



			</div>
		</div>
	)
}

export default Sidebar