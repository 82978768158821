import { Button, Col, Form, Row } from "react-bootstrap"
import {useForm} from 'react-hook-form'
import {type SettingSocialModel} from '../../../types/setting'
import React,{useState,useRef} from "react"
import axios from "../../../utils/axios";


const SocialLinks = ({activeTabs,onNext,data={}}:any) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<SettingSocialModel>(data);
  

const socialLinksSubmit = async (overviewData:any) => { 
    try {
        console.log("coming");
        
       let response = await axios.post('/api/employer/saveSocialLinksSetting', overviewData);
    } catch (error) {
        console.log(error);
        
    }

}

    return ( 
    <form onSubmit={handleSubmit(socialLinksSubmit)} className="step-section-one">
      <div className="socialLink-section">
        <Row className="d-flex gap-5">
            <Col lg='3' md="12">
                <h5 className="">Basic Information</h5>
                <p className="para">
                    This is company information that you can update anytime.
                </p>
            </Col>
            <Col lg='8' md='12'>
                <Form>
                    <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="p-0 m-0 mb-1">
                            <span className="fw-bold">Instagram</span>
                        </Form.Label>
                        <Form.Control type="text"  {...register('Instagram',{required : 'Instagram is required !'})}
                        id="Instagram" placeholder="https://www.instagram.com/"  />
                    </Form.Group>

                    <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="p-0 m-0 mb-1">
                            <span className="fw-bold">Twitter</span>
                        </Form.Label>
                        <Form.Control type="text"  {...register('Twitter',{required : 'Twitter is required !'})}
                         placeholder="https://twitter.com/nomad/" />
                    </Form.Group>

                    <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="p-0 m-0 mb-1">
                            <span className="fw-bold">Facebook</span>
                        </Form.Label>
                        <Form.Control type="text"  {...register('FaceBook',{required : 'FaceBook is required !'})}
                         placeholder="https://web.facebook.com/nomad/" />
                    </Form.Group>

                    <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="p-0 m-0 mb-1">
                            <span className="fw-bold">LinkedIn</span>
                        </Form.Label>
                        <Form.Control type="text"  {...register('LinkedIn',{required : 'LinkedIn is required !'})}
                         placeholder="Enter your LinkedIn Address" />
                    </Form.Group>


                    <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlInput1"
                    >
                        <Form.Label className="p-0 m-0 mb-1">
                            <span className="fw-bold">Youtube</span>
                        </Form.Label>
                        <Form.Control
                            type="text"  {...register('Youtube',{required : 'Youtube is required !'})}
                            placeholder="Enter your Youtube Address"
                        />
                    </Form.Group>
                </Form>
            </Col>
        </Row>
        <div id="horizontal-line"></div>
        <div className="end-btn"><Button className="primary-btn" type="submit">Save Changes</Button></div>
    </div> 
    </form>)
}

export default SocialLinks;