import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface TeamMembers {
    FullName: string;
    Email: string;
    CurrentRole: string;
    Links: {
        LinkedInUrl: string;
        InstagramUrl: string;
    };
    MemberPicture: string;
}

interface Employer {
    Logo?: string;
    Email?: string;
    CompanyDetails?: {
        Name: string;
        ContactNumber: string;
        Identification: any; // Consider specifying a more detailed type if possible
        Website: string;
        Locations: string[];
        Employees: string;
        Industry: string;
        DateFounded: string;
        TechStack: string[];
        AboutCompany: string;
    };
    SocialLinks?: {
        Instagram: string;
        Twitter: string;
        Facebook: string;
        LinkedIn: string;
        Youtube: string;
    };
    Team?: TeamMembers[];
}

// Define the initial state based on the state type
const initialState: Employer = {};

export const employerSettingsSlice = createSlice({
    name: 'employer',
    initialState,
    reducers: {
        // Reducer to correctly update employer settings
        updateEmployer: (state, action: PayloadAction<Partial<Employer>>) => {
            // If you want to replace the whole state
            return { ...state, ...action.payload };

            // OR for mutating part of the state directly:
            // Object.assign(state, action.payload);
        },
        // Optionally, add more specific reducers for targeted updates
    },
});

// Export actions to be used in UI components
export const { updateEmployer } = employerSettingsSlice.actions;

export default employerSettingsSlice.reducer;
