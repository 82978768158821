import { Container } from "react-bootstrap";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Link } from "react-router-dom";

export default function Dashboard() {
  return (
    <>
      <Header />
     <div className="dashboard-page">
        <div className="first-container">
           <Container>
           <div className="pages-links"><Link to='/'>Homepage</Link> / <Link style={{color:"#25324B"}} to='/dashboard'>Dashboard</Link></div>
            <div className='first-section-wish'>
            <div><h4>Good Morning, Manny</h4>
            <p className='para'>Here is job applications status from July 19 - July 25.</p>
            </div>
            <div className='calender'>July 19 -  July 25  <i className="fa-solid fa-calendar"></i></div>
        </div>
           </Container>
        </div>
        <Container className="dashboard-container">
            hello
        </Container>
     </div>
      <Footer />
    </>
  );
}
