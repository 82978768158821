import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollTop";

const CookiesPolicy = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <div className="policies-section">
        <div className="first-row">
          <h1> COOKIES POLICY</h1>
        </div>
        <Container>
          <div className="above-section">
            <p>
              This Cookies Policy (the <span className="fw-bold">‘Policy’</span>
              ) explains how we use cookies and other tracking technologies to
              recognize you when you visit{" "}
              <Link to="https://naukridaddy.com">
                {" "}
                https://naukridaddy.com{" "}
              </Link>{" "}
              (the <span className="fw-bold">‘Website’</span> ). It explains
              what these technologies are and why we use them, as well as your
              right to control our use of them.
            </p>
          </div>
          <div className="list-section">
            <ol type="a">
              <li>Does naukridaddy.com use cookies?</li>
              <p>
                <span className="fw-bold">Naukridaddy.com</span> utilizes
                cookies and other tracking technologies to discern your identity
                and interests, retain your preferences, and observe your
                interactions on our Website. These technologies are employed for
                managing access to specific content, ensuring the security of
                the Website, and handling any requests you may have.
              </p>
              <p>
                In overseeing our Website and for research purposes, we have
                engaged third-party service providers to monitor and analyze
                statistical usage and volume data from our Website users. These
                providers use persistent cookies to enrich user experience,
                supervise Website content, and evaluate how users navigate and
                engage with the Website.
              </p>

              <li>What are cookies?</li>
              <p className="mt-2">
                Cookies constitute small data files that are deposited on your
                computer or mobile device during your Website visits. Website
                owners widely employ cookies to ensure the functionality and
                efficiency of their sites, and to furnish reporting information.
              </p>
              <p>
                Those cookies established by the website owner, in this
                instance, naukridaddy.com, are termed "first-party cookies."
                Conversely, cookies initiated by entities other than the website
                owner are referred to as "third-party cookies." These
                third-party cookies facilitate the provision of external
                features or functionalities, such as advertising, interactive
                content, and analytics, either directly on the website or
                through it. The entities responsible for these third-party
                cookies can identify your computer both when it visits the
                specific website and when it accesses certain other websites.
              </p>
              <p>
                Cookies can be categorized as either "persistent" or "session"
                cookies. Persistent cookies are designed to recognize you as a
                familiar user, facilitating a seamless return to the Website or
                interaction with our services without the need for repeated
                sign-ins. These cookies persist in your browser even after
                signing in, and the Website accesses them when you revisit our
                sites or partner sites utilizing our services. In contrast,
                session cookies have a brief duration, lasting only for the
                duration of your session, typically encompassing your current
                visit to a website or browser session.
              </p>
              <li>What types of cookies do we use?</li>
              <ol type="i">
                <li>
                  <p className="fw-bold m-0">Necessary cookies</p>
                </li>
                <p>
                  Necessary cookies allow us to offer you the best possible
                  experience when accessing and navigating through our Website
                  and using its features. For example, these cookies let us
                  recognize that you have created an account and have logged
                  into that account.
                </p>
                <li>
                  <p className="fw-bold m-0">Functionality cookies</p>
                </li>
                <p>
                  Functionality cookies let us operate the Website in accordance
                  with the choices you make. For example, we will recognize your
                  username and remember how you customized the Website during
                  future visits.
                </p>
                <li>
                  <p className="fw-bold m-0">Analytical cookies</p>
                </li>
                <p>
                  These cookies enable us and third-party services to collect
                  aggregated data for statistical purposes on how our visitors
                  use the Website. These cookies do not contain personal
                  information such as names and email addresses and are used to
                  help us improve your user experience of the Website.
                </p>
              </ol>
              <li className="pt-1">What are third-party cookies?</li>
              <p>
                In addition to our own cookies, we may also use various
                third-party cookies to report usage statistics of the Service,
                deliver advertisements on and through the Service, and so on.
                Some of the key third-party services Google Analytics for
                analytical purposes, Facebook Pixel for advertising. These
                third-party service providers have their privacy policies
                addressing how they use such information. The Company absolves
                itself from any liability that may arise for using third-party
                cookies whatsover.
              </p>

              <li>How to control cookies?</li>
              <p>
                Managing Your Cookie Preferences: Upon your first visit to our
                website, you will be prompted to accept or decline the use of
                cookies. This consent can be managed and modified at any time.
              </p>
              <p>
                Most web browsers offer settings preferences that allow you to
                control cookies. Opting to disable these cookies, however, does
                not mean you will be exempt from internet advertising; rather,
                the ads you encounter may not be tailored to your specific
                interests. It's important to note that even if third-party
                cookies are turned off, you might still receive personalized
                advertisements and recommendations while online.
              </p>
              <p>
                Various methods are available for managing the cookies set on
                your devices, though essential cookies, crucial for website
                functionality, cannot be deactivated. Should you choose to
                disallow specific cookies, it may impact your access to the
                website, compromising its performance, features, or services.
              </p>
              <p>
                The management of such cookies can also be done through privacy
                settings in the web browser you use. It's essential to be aware
                that blocking all cookies via browser settings might restrict
                your access to certain sections of our website or other sites.
              </p>
              <li>Compliance with Data Protection Regulations</li>
              <p>
                We are committed to protecting your privacy and handling your
                data in an open and transparent manner as per our privacy
                policy.
              </p>
              <li>Changing your Cookie Settings</li>
              <p>
                The browser settings for changing your cookies settings are
                usually found in the 'options' or 'preferences' menu of your
                internet browser. Otherwise, you should use the 'Help' option in
                your internet browser for more details.
              </p>
              <li>Opt-Out Options</li>
              <p>
                If you wish to decline or control the cookies that are set by
                our website or any other website, you can do so through your
                browser settings. Each browser offers different functionalities
                and options, but you can typically find these settings in the
                'options' or 'preferences' menu of your browser. Please note
                that if you choose to decline cookies, you may not be able to
                use the full functionalities of our website.
              </p>
              <li>Notification of Policy Updates</li>
              <p>
                We may update our Cookies Policy from time to time. We will
                notify you of any changes by posting the new Cookies Policy on
                this page. We will also send an alert via email and/or a
                prominent notice on our Service, prior to the change becoming
                effective and update the 'effective date' at the top of this
                Cookies Policy.
              </p>
              <li>Contact us</li>
              <p>Email:[●]</p>
            </ol>
          </div>

          <div className="d-flex align-items-center justify-content-center fw-bold">
            <h4>Thank You for using the Site!</h4>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default CookiesPolicy;
