import type { RouteObject } from "react-router-dom";
import { useRoutes } from "react-router-dom";
// import Home from "../pages/Home";
// import Login from "../pages/Login";
// import Dashboard from "../pages/Dashboard";
import Signup from "../pages/Signup";
import CompleteSignup from "../pages/CompleteSignup";
import Login from "../pages/Login";
import EmployerRoot from "../pages/EmployerRoot";
import { Container } from "react-bootstrap";
import EmployerDashboard from "../components/employer/Dashboard";
import EmployerProfile from "../components/employer/Profile";
import EmployerApplicants from "../components/employer/Applicants";
import EmployerJoblisting from "../components/employer/JobListing";
import EmployerBilling from "../components/employer/Billing";
import EmployerSettings from "../components/employer/Settings";
import PostJob from "../components/employer/PostJob";
import PageNotFound from "../pages/PageNotFound";
import RouteGuard from "../components/common/RouteGuard";
import Homepage from "../pages/Homepage";
import FindTalent from "../pages/FindTalent";
import FindCompany from "../pages/FindCompany";
import FilterCompany from "../pages/FilterCompany";
import JobDetails from "../pages/JobDetails";
import CompanyProfile from "../pages/CompanyProfile";
import PrivacyPolicy from "../pages/static/PrivacyPolicy";
import TermsUse from "../pages/static/TermsUse";
import FraudAlert from "../pages/static/FraudAlert";
import GriveancePolicy from "../pages/static/GriveancePolicy";
import CookiesPolicy from "../pages/static/CookiesPolicy";
import SelfAnalysis from "../pages/SelfAnalysis";
import Dashboard from "../pages/Dashboard";
import SetNewPassword from "../pages/SetPassword";
import HelpDocs from "../pages/static/HelpDocs";
import PricingPlans from "../pages/static/PricingPlans";
import BlogsAndResources from "../pages/static/BlogsAndResources";
import GuidesAndTutorials from "../pages/static/GuidesAndTutorials";

export default function AppRoutes() {
	let routes: RouteObject[] = [
		{
			path: "/",
			element: <Homepage />,
		},
		{
			path: "/login",
			element: <Login />,
		},
		{
			path: "/signup",
			element: <Signup />,
		},
		{
			path: "/complete-candidate-info",
			element: <CompleteSignup />,
		},
		{
			path: "/dashboard",
			element: <Dashboard />,
		},

		{
			path: "/jobs",
			element: <FindTalent />
		},
		{
			path: "/find-companies",
			element: <FilterCompany />
		},
		{
			path: "/companies",
			element: <FindCompany />
		},
		{
			path: "/job-details",
			element: <JobDetails />
		},
		{
			path: "/company-profile/:id",
			element: <CompanyProfile />
		},
		{
			path: '/set-password/:token',
			element: <SetNewPassword />
		},
		{
			path: "/projects",
			element: <SelfAnalysis />
		},
		{
			path: "/privacy-policy",
			element: <PrivacyPolicy />
		},
		{
			path: "/terms-use",
			element: <TermsUse />
		},
		{
			path: "/fraud-alert",
			element: <FraudAlert />
		},
		{
			path: "/griveance-policy",
			element: <GriveancePolicy />
		},
		{
			path: "/cookies-policy",
			element: <CookiesPolicy />
		},
		{
			path: "/help-docs",
			element: <HelpDocs />
		},
		{
			path: "/pricing-plans",
			element: <PricingPlans />
		},
		{
			path: "/blogs-and-resources",
			element: <BlogsAndResources />
		},
		{
			path: "/guides-and-tutorials",
			element: <GuidesAndTutorials />
		},
		// {
		// 	path: "/cookies-policy",
		// 	element: <CookiesPolicy />
		// },
		// {
		// 	path: "/cookies-policy",
		// 	element: <CookiesPolicy />
		// },
		// {
		// 	path: "/cookies-policy",
		// 	element: <CookiesPolicy />
		// },
		{
			path: '/employer',
			element: <RouteGuard setOn='employer'><EmployerRoot /></RouteGuard>,
			children: [
				{
					path: 'dashboard',
					element: <EmployerDashboard />
				},
				{
					path: 'profile',
					element: <EmployerProfile />
				},
				{
					path: 'applicants',
					element: <EmployerApplicants />
				},
				{
					path: 'jobs',
					element: <EmployerJoblisting />
				},
				{
					path: 'billing',
					element: <EmployerBilling />
				},
				{
					path: 'settings',
					element: <EmployerSettings />
				},
				{
					path: "post-job",
					element: <PostJob />
				}
			]
		},
		{
			path: '*',
			element: <PageNotFound />
		}
	];

	let element = useRoutes(routes);
	return (<Container fluid className="p-0 m-0">{element}</Container>);
}
