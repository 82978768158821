import React from 'react'
import SadImage from '../assets/img/sad svg image.svg'
import { Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
const NoResults = ({ onGoBack }: any) => {
  const navigate = useNavigate()
  return (
    <>
      <div className='no-result-section'>
        {/* <img src={SadImage} width={"300px"} alt="" /> */}

        <h1>No Result Found</h1>
        <p>We couldn't find what you're looking  for. Try changing the search crtiteria</p>
        {/* <Button onClick={() => { onGoBack ? onGoBack() : navigate("/") }} className='primary-btn w-25'>Go Back</Button> */}
      </div>
    </>
  )
}

export default NoResults