import { Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";
import Sidebar from "../components/employer/Sidebar";
import EmployerTopbar from "../components/employer/Topbar";
import axios from "../utils/axios";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateEmployer } from "../slices/employer";

const EmployerRoot = () => {
	const dispatch = useDispatch();

	const fetchEmployerProfile = async () => {
		try {
			const { data } = await axios.get('/api/employer/profile');
			dispatch(updateEmployer(data));
		} catch (error) {
			console.log(error);

		}
	}

	useEffect(() => {
		fetchEmployerProfile()
	}, [])

	return (
		<div className="employer-onboard">
			<Sidebar />
			<div className="side-bar-right">
				<EmployerTopbar />
				<div className="page-content">
					<Outlet />
				</div>
			</div>
		</div>
	)
}

export default EmployerRoot;