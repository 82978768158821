
import { Button, FormGroup, FormLabel, Row } from 'react-bootstrap'
import axios from "../../utils/axios";
import { Link, useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useForm } from 'react-hook-form';

interface JobseekerSignupData {
    FullName: string;
    Email: string;
    Password: string;
}

const JobseekerSignup = () => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<JobseekerSignupData>();
    const [errorMsg, setErrMessage] = useState('');

    const onSubmit = async (signupData: JobseekerSignupData) => {
        try {
            setErrMessage('');
            const { data } = await axios.post('/api/jobseeker/register', signupData);
            if (data.status === 'conflict') return setErrMessage(data.message);
            else navigate('/')
        } catch (error) {
            console.error(error);
        }
    }

    const rules = {
        FullName: register('FullName', {
            required: "Full name is required",
            maxLength: { value: 50, message: 'Full name cannot have more than 50 characters' },
        }),
        Email: register('Email', {
            required: "Email is required",
            pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address"
            }
        }),
        Password: register('Password', {
            required: "Password is required",
        })
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>Full Name</FormLabel>
                <input {...rules.FullName} className='form-control rounded-5' type="text" placeholder='Enter your full name' />
                {errors.FullName && <span className="error">{'' + errors.FullName.message}</span>}
            </FormGroup>

            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>Email Address</FormLabel>
                <input {...rules.Email} className='form-control rounded-5' type="text" placeholder='Enter email address' />
                {errors.Email && <span className="error">{'' + errors.Email.message}</span>}
            </FormGroup>

            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>Password</FormLabel>
                <input {...rules.Password} className='form-control rounded-5' type="password" placeholder='Enter password' />
                {errors.Password && <div className="error">{'' + errors.Password.message}</div>}
            </FormGroup>

            <Button className='login_button w-100 rounded-5 mt-3 mb-2' type="submit">Continue</Button>
            {errorMsg ? <div className="error">{'' + errorMsg}</div> : ''}
            <p className='text-secondary fs-6 pt-3 px-3'>Already have an account?  <Link className='px-2 fw-bold' style={{ color: "#1FA0FF" }} to="/login">Login</Link></p>
        </form>
    )
}


export default JobseekerSignup;