import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import JobInformation from "./job/JobInformation";
import JobDescription from "./job/JobDescription";
import PerksAndBenefits from "./job/PerksAndBenefits";
import axios from "../../utils/axios";
import { useNavigate } from "react-router-dom";


const PostJob = () => {
	const [step, setStep] = useState('information');
	const [job, setJob] = useState({});
	const navigate = useNavigate();

	const onJobInformation = (data: any) => setJob({ ...job, ...data });
	const onJobDescription = (data: any) => setJob({ ...job, ...data });

	const postJob = async (perks: any) => {
		try {
			const jobInfo = { ...job, PerksAndBenefits: perks }
			await axios.post('/api/job/add', jobInfo);
			navigate('/employer/jobs')
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<div className="post-job-section">
			<div className="back-btn">
				<Button className="">
					<i className="fa-solid fa-arrow-left"></i>
				</Button>
				<div className="heading">Post a Job</div>
			</div>
			<div className="steps">
				<div className={step == 'information' ? 'step1 active d-flex gap-3' : 'step1 d-flex gap-3'}>
					<div className="step-icon">
						<i className="fa-solid fa-briefcase"></i>
					</div>
					<div className="step-info">
						<p className="">Step 1/3</p>
						<h5>Job Information</h5>
					</div>
				</div>
				<div className={step == 'description' ? 'step1 active d-flex gap-3' : 'step1 d-flex gap-3'}>
					<div className="step-icon">
						<i className="fa-solid fa-clipboard-list"></i>
					</div>
					<div className="step-info">
						<p className="">Step 2/3</p>
						<h5>Job Description</h5>
					</div>
				</div>
				<div className={step == 'perks' ? 'step1 active d-flex gap-3' : 'step1 d-flex gap-3'}>
					<div className="step-icon">
						<i className="fa-solid fa-gift"></i>
					</div>
					<div className="step-info">
						<p className="">Step 3/3</p>
						<h5>Perks &amp; Benefits</h5>
					</div>
				</div>
			</div>
			{step == 'information' ? <JobInformation onNext={onJobInformation} setStep={setStep} /> : ''}
			{step == 'description' ? <JobDescription onNext={onJobDescription} setStep={setStep} /> : ''}
			{step == 'perks' ? <PerksAndBenefits onNext={postJob} /> : ''}
		</div>
	);
}

export default PostJob;
