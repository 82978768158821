import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Button, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Vodafone from "../../src/assets/img/vodafone-2017-logo.png";
import Intel from "../../src/assets/img/intel-3.png";
import Tesla from "../../src/assets/img/tesla-9 1.png";
import Amd from "../../src/assets/img/amd-logo-1.png";
import Talkit from "../../src/assets/img/talkit 1.png";
import Dashboard_screen from "../../src/assets/img/dashboard-screen.png";
import Quates from '../../src/assets/img/quote-up.png'
import ClientImage from "../../src/assets/img/Ellipse 1.png"
import ClientImage2 from "../../src/assets/img/Ellipse 1 (1).png"
import Footer from "../components/Footer";
import SearchComponent from "../components/Search";
import axios from "../utils/axios";
import Header from "../components/Header";
import useScrollToTop from "../hooks/useScrollTop";
import Slider from '../components/common/Slider'
import image1 from '../assets/img/work.png'
// import image2 from '../assets/img/nomad-team.png'
// import image3 from '../assets/img/nomad-2.png'

function Homepage() {
	const slides = [
		{ title: "Slide 1", description: "Building Teams, Building Success: Let Us Find Your Next Star Performer.", image: image1 },
		{ title: "Slide 2", description: "Empower Your Business: Hire Exceptional Talent, Drive Results.", image: image1 },
		{ title: "Slide 3", description: "Strategic Hiring for Business Growth and Innovation.", image: image1 },
		{ title: "Slide 4", description: "Partner with Us to Find Your Next Top Talent.", image: image1 }
	];



	const [categories, setCategories] = useState([])
	const navigate = useNavigate()
	useScrollToTop();
	const fetchTopCategories = async () => {
		try {
			const { data } = await axios.get('/api/search/categories');
			setCategories(data);

		} catch (error) {
			console.log(error);

		}
	}

	const openJobByCategory = (key: string) => {
		navigate('/jobs?category=' + encodeURIComponent(key))
	}

	const onSearchTrigger = (inp1: string, inp2: string) => {
		if (!inp1) return;
		let searchStr = '?term=' + encodeURIComponent(inp1);
		if (inp2) searchStr += '&location=' + encodeURIComponent(inp2);
		navigate('/jobs' + searchStr)
	}

	useEffect(() => {
		fetchTopCategories()
	}, [])



	return (
		<>
			<Header />

			<div className="homepage-section">
				{/* <Navbar expand="lg" className="bg-transparent home-nav-bar">
					<Container className="nav-bar">
						<div className="main-brand">
							<Navbar.Brand className="" href="/">
								<div className="">
									<img src={StaticLogo} alt="Logo" width="160px" />
								</div>
							</Navbar.Brand>
						</div>
						<Navbar.Toggle className="" aria-controls="" />
						<div className="nav-right">


							<Navbar.Collapse id="">
								{user ? (
									<>
										<Nav
											className=""
										// style={{ maxHeight: "370px", fontSize: "16px", lineHeight: "1" }}
										>
											<div className="nav-items d-flex gap-3">
												<Link className="" to="/dashboard">
													Dashboard
												</Link>
												<Link className="" to="/find_talent">
													Find Talent
												</Link>
												<Link className="" to="/companies">
													Companies
												</Link>
												<Link className="" to="/my_application">
													My Application
												</Link>
												<Link className="" to="/self_analysis">
													Self Analysis
												</Link>
											</div>
											<div className="profile">
												<div className="user-img">
													<img
														src={UserImage}
														alt=""
														width={"50px"}
													/>
												</div>
												<div className="user-name">

													<h6>Mr. Manny Yug</h6>

													<p

													>
														mannyyug@gmail.com
													</p>
												</div>
												<div className="icon-down">
													<Button className="bg-transparent border-0">
														<i className="fa-solid fa-chevron-down"></i>
													</Button>
												</div>
											</div>
										</Nav>
									</>
								) : (
									<>
										<Nav
											className=""
										// style={{ maxHeight: "300px", fontSize: "16px", lineHeight: "1" }}
										>
											<div className="nav-items d-flex gap-3">
												<Link className="active" to="/find-talent">
													Find Talent
												</Link>
												<Link className="" to="/companies">
													Companies
												</Link>
												<Link className="" to="/community">
													Community
												</Link>
												<Link className="" to="/projects">
													Projects
												</Link>
											</div>
											<div className="nav-buttons">
												<Button className="btn1">
													{" "}
													<Link className="" to="/">
														<span className=""> Log In</span>
													</Link>
												</Button>
												<Button className="btn2">
													{" "}
													<Link className="" to="/signup">
														<span className=""> Sign Up</span>
													</Link>
												</Button>{" "}
											</div>
										</Nav>
									</>
								)}
							</Navbar.Collapse>
						</div>
					</Container>
				</Navbar> */}
				<Container>
					{/* hero section start */}
					<div className="hero-section">
						{/* left side in hero */}
						<div className="left-hero-section">
							<div className="big-paragraph">
								<span style={{ position: 'relative', zIndex: 1 }}>Job Portal for Developer, Designer, and Marketers</span>
								{/* <div className="fading-box"></div> */}
								<div className="gradient-circle"></div>
							</div>
							<div className="para">Search job like a Daddy!</div>
							<SearchComponent isJobSearch={true} onSearchTrigger={onSearchTrigger} />


						</div>
						{/* right-side in hero */}
						<div className="right-hero-section">
							<div className="jobpost-box">
								<div className="icon-job"><i className="fa-solid fa-briefcase"></i></div>
								<div className=""><h5>20,583+</h5></div>
								<p>Job Posted</p>
							</div>
							{/* <img style={{width:"100%", height:"100%", backgroundAttachment:"fixed"}} src={heroBanner} ></img> */}
						</div>
					</div>
					<div className="trusted-section">
						<div className="">
							<h6>Trusted by:</h6>
						</div>
						<div className="d-flex gap-5 mt-3">
							<div>
								<img alt="" src={Vodafone}></img>
							</div>
							<div>
								<img alt="" src={Intel}></img>
							</div>
							<div>
								<img alt="" src={Tesla}></img>
							</div>
							<div>
								<img alt="" src={Amd}></img>
							</div>
							<div>
								<img alt="" src={Talkit}></img>
							</div>
						</div>
					</div>
					{/* job category section start */}
					<div className="category-section">
						<div className="d-flex justify-content-between">
							<div className="">
								<h1>
									Explore by <span style={{ color: "#1FA0FF" }}>category</span>
								</h1>
							</div>
							<div className="simple-btn d-flex align-items-end gap-2">
								<Button onClick={() => navigate('/jobs')} className="px-0 bg-transparent">
									<span>Show all jobs</span>{" "}
									<i className="mx-2 fa-solid fa-arrow-right"></i>{" "}
								</Button>
							</div>
						</div>
						<Row className="gx-2 gy-2">
							{categories.map((category: any, index: number) => {
								return (
									<Col md={3} onClick={() => openJobByCategory(category.key)} key={index + '_category'} className="job-cate-box mx-2 my-2">
										<div>
											<i className="fa-solid fa-pen-ruler"></i>
										</div>
										<h5>{category.key}</h5>
										<p className="para">
											{category.doc_count} jobs available
											<i className="mx-2 fa-solid fa-arrow-right"></i>
										</p>
									</Col>
								)
							})}
						</Row>

						{/* <div className="d-flex flex-wrap gap-4 align-items-center justify-content-between">
							{categories.map((category: any, index: number) => {
								return (<div onClick={() => openJobByCategory(category.key)} key={index + '_category'} className="job-cate-box d-flex flex-column gap-2">
									<div>
										<i className="fa-solid fa-pen-ruler"></i>
									</div>
									<h5>{truncateString(category.key, 18)}</h5>
									<p className="para">
										{category.doc_count} jobs available
										<i className="mx-2 fa-solid fa-arrow-right"></i>
									</p>
								</div>)
							})}
						</div> */}
					</div>
					{/* posting job poster section start */}
					<div className="posting-job-poster">
						<div className="start-post-job">
							<div className="">
								<h1>Start posting jobs today</h1>
							</div>
							<p>Start your job posting to get candidates</p>
							<Button><Link to='/signup'>Sign Up For Free</Link></Button>
						</div>
						<div className="dashboard-img">
							<img src={Dashboard_screen} width={"500px"} alt=""></img>
						</div>
					</div>


					{/* find work section start */}
					<div className="findwork-section">
						<div className="find-work-text d-flex flex-column justify-content-center align-items-center">
							<h1>Find great work</h1>
							<p>
								Meet clients you’re excited to work with and take your career or
								business to new heights.
							</p>

						</div>
						<div className="gradient-circle"></div>
						<Slider slides={slides} />
					</div>

					<div className="client-review-part">
						<div className="d-flex flex-column justify-content-center align-items-center">
							<h1>Client say’s that</h1>
							<p>
								Meet clients you’re excited to work with and take your career or
								business to new heights.
							</p>
						</div>
						<div className="gradient-circle"></div>
						<div className="d-flex gap-5  justify-content-between mt-4">
							<div className="client-review-box">
								<div className="quates"><img src={Quates} width={"30px"} alt=""></img></div>
								<div><h4>“One of the advantages of utilizing freelancers is finding talent with different skills quickly as our needs change.”</h4></div>
								<div className="line-with-circles"></div>
								<div className="d-flex align-items-center gap-3">
									<div className="client-image"><img src={ClientImage} alt="" width={"55px"}></img></div>
									<div>
										<h6>Davis Rosser</h6>
										<div className="para">Director of Content Experience</div>
									</div>
								</div>
							</div>
							<div className="client-review-box">
								<div className="quates"><img src={Quates} width={"30px"} alt=""></img></div>
								<div><h4>“Nguli enables us to differentiate ourselves from our competitors and produce content at a higher caliber.”</h4></div>
								<div className="line-with-circles"></div>
								<div className="d-flex align-items-center  gap-3">
									<div className="client-image"><img src={ClientImage2} alt="" width={"55px"}></img></div>
									<div>
										<h6>Tiana Geidt</h6>
										<div className="para">Director of Content Experience</div>
									</div>
								</div>
							</div>
						</div>
					</div>

				</Container>
				<div className="footer-part">
					<Container>
						<div className="footer-text">
							<h1>Find your next great opportunity!</h1>
							<p>We are taking action to help our freelancers, our clients, and the people of the world and so can you.</p>
						</div>

						<Button onClick={() => navigate('/login')}>Get Started</Button>

					</Container>

				</div>


				<Footer />

			</div>
		</>
	);
}

export default Homepage;
