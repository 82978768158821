import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { Container } from 'react-bootstrap'
import useScrollToTop from '../../hooks/useScrollTop';

const PricingPlans = () => {
    useScrollToTop();
    return (
        <>
            <Header />
            <div className="policies-section">
                <div className="first-row">
                    <h1>PRICING PLANS</h1>
                </div>
                <Container>
                    <div className='list-section'>
                        <section className='mb-4 p-3'>
                            <p>
                                At NaukriDaddy, we offer flexible pricing plans designed to meet the diverse needs of recruiters and
                                employers. Whether you&#39;re a small business looking to make your first hires or a large corporation with
                                extensive recruitment needs, we have a plan that&#39;s right for you. Explore our pricing options below:
                            </p>
                        </section>


                        <section className='mb-4 p-3'>
                            <article>
                                <h5>1. Basic Plan:</h5>
                                <ul>
                                    <li>Ideal for small businesses and startups</li>
                                    <li>Limited access to job postings and candidate profiles</li>
                                    <li>Basic applicant tracking system (ATS) features</li>
                                    <li>Affordable monthly subscription fee</li>
                                </ul>
                            </article>
                            <article >
                                <h5>2. Standard Plan:</h5>
                                <ul>
                                    <li>Suitable for growing companies with moderate recruitment needs</li>
                                    <li>Expanded access to job postings and candidate profiles</li>
                                    <li>Advanced ATS features, including resume parsing and candidate tagging</li>
                                    <li>Priority customer support</li>
                                    <li>Competitive monthly or annual subscription fee</li>
                                </ul>
                            </article>
                            <article >
                                <h5>3. Premium Plan:</h5>
                                <ul>
                                    <li>Tailored for large enterprises and organizations with high-volume recruitment requirements</li>
                                    <li>Unlimited access to job postings and candidate profiles</li>
                                    <li>Advanced AI matching technology for precise candidate recommendations</li>
                                    <li>Customizable branding and employer profile features</li>
                                    <li>Dedicated account manager and premium customer support</li>
                                    <li>Flexible pricing options based on usage and requirements</li>
                                </ul>
                            </article>
                        </section>

                        <section className='mb-4 p-3'>
                            <h4>Custom Plans:</h4>
                            <article>
                                <ul>
                                    <li>For businesses with unique or specialized recruitment needs</li>
                                    <li>Customizable plans tailored to your specific requirements</li>
                                    <li>Additional features and services available upon request</li>
                                    <li>Contact our sales team to discuss your needs and receive a personalized quote</li>
                                </ul>
                            </article>

                        </section>

                        <section className='mb-4 p-3'>
                            <h4>Additional Services:</h4>
                            <article>
                                <ul>
                                    <li>Job promotion and featured listings for increased visibility</li>
                                    <li>Resume database access for proactive candidate sourcing</li>
                                    <li>Recruitment consulting and training services</li>
                                    <li>Custom integrations and API access for seamless workflow integration</li>
                                </ul>
                            </article>

                        </section>

                        <section className='mb-4 p-3'>
                            <h4>Free Trial:</h4>
                            <article>
                                <ul>
                                    <li>Not sure which plan is right for you? Sign up for a free trial to test drive our platform and explore its
                                        features risk-free.</li>
                                    <li>No credit card required. Simply sign up and start using NaukriDaddy today!</li>
                                </ul>
                            </article>

                        </section>

                        <section className='mb-4 p-3'>
                            <h4>How to Get Started:</h4>
                            <article>
                                <ul>
                                    <li>Visit our pricing page to compare plans and choose the one that best fits your needs.</li>
                                    <li>Sign up for an account and start your free trial or select a subscription plan to get started
                                        immediately.</li>
                                    <li>Need assistance? Contact our sales team for personalized guidance and support.</li>
                                </ul>
                            </article>

                        </section>

                        <section className='p-3'>
                            <p>Unlock the power of NaukriDaddy&#39;s recruitment platform and take your hiring efforts to the next level.
                                Choose the plan that&#39;s right for you and start finding the perfect candidates today!</p>

                            <p>Sincerely,</p>
                            <p>The NaukriDaddy Team</p>
                        </section>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default PricingPlans