import { Container } from "react-bootstrap";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollTop";

const PrivacyPolicy = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <div className="policies-section">
        <div className="first-row">
          <h1>PRIVACY POLICY</h1>
        </div>
        <Container>
          <div className="above-section">
            <p>
              THIS PRIVACY POLICY ("
              <span className="fw-bold">PRIVACY POLICY</span>") SETS FORTH THE
              MANNER OF COLLECTION, USE AND DISCLOSURE OF INFORMATION GATHERED
              ON THE SITE. PLEASE READ THIS PRIVACY POLICY CAREFULLY. BY
              CONTINUING TO USE THE SERVICES, OR ACCESS THE SITE You AGREE TO
              THE TERMS OF THIS PRIVACY POLICY. IF You DO NOT AGREE TO SAME, You
              MAY NOT ACCESS OR USE THE SITE OR AVAIL OF THE SERVICES. ACCESS
              AND USE OF THE SITE IS SUBJECT TO THE TERMS AND CONDITIONS
              ("TERMS") ACCESSIBLE ON THE SITE.
            </p>
            <p>
              <span className="fw-bold">
                Naukridaddy HR Solutions Private Limited
              </span>
              , a private limited company with its registered office at A- 88
              Adhyapak Nagar Nangloi, Nangloi, West Delhi, New Delhi,
              Delhi-110041, India (hereinafter shall be referred to as
              <span className="fw-bold"> “ Company ”</span> ,{" "}
              <span className="fw-bold">“ Naukridaddy ”</span> ,
              <span className="fw-bold">“ We ”</span> ,{" "}
              <span className="fw-bold">“ Us ”</span> ,{" "}
              <span className="fw-bold">“ Our ”</span> and terms of similar
              meaning) owns and operates{" "}
              <Link to="https://naukridaddy.com/signup">
                https://naukridaddy.com/signup
              </Link>{" "}
              including any subdomains thereof (hereinafter collectively
              referred to as <span className="fw-bold"> &quot;Site&quot;</span>
              );
            </p>
            <p>
              This Privacy Policy does not apply to the Websites of Our business
              partners, corporate affiliates or to any other third parties, even
              if their Websites are linked to the Site. We recommend You review
              the respective privacy statements of the other parties with whom
              You interact.
            </p>
            <p>
              By accessing the Site or otherwise using the Site, You
              (hereinafter referred to as <span className="fw-bold">“You”</span>
              , <span className="fw-bold">“Your”</span>,{" "}
              <span className="fw-bold">“User”</span>,{" "}
              <span className="fw-bold">“Guest/s”</span> as applicable)
              expressly consent to Our collection, storage, use and disclosure
              of the Information (as defined hereunder) in accordance with the
              terms of this Privacy Policy.
            </p>
            <p>
              As We update, improve and expand the Site, this Privacy Policy may
              change at Our sole discretion without notice, so please refer back
              to it periodically as You will be deemed to be governed by the
              terms of such modified Privacy Policy, as it comes into effect
              from time to time. By using Our Site, You are deemed to have read,
              acknowledged and accepted all the provisions stated in this
              Privacy Policy, including any changes that We may make from time
              to time.
            </p>
            <p>
              Please note: all information in this privacy notice applies to You
              unless otherwise indicated. In this Privacy Policy the term
              <span className="fw-bold"> 'Personal Data' </span> shall mean any
              data about an individual/ organization/company who is identifiable
              by or in relation to such data.
            </p>
            <p>
              We encourage You to carefully peruse this Privacy Policy in
              conjunction with any supplementary and more detailed information
              that may be presented to You on various occasions when We gather
              or handle Personal Data on the Site. This will ensure that You are
              cognizant of the methods and objectives underlying the processing
              of Your Personal Data.
            </p>
          </div>
          <div className="list-section">
            <ol>
              <li>
                TYPE OF INFORMATION COLLECTED
                <ol type="a">
                  <li>
                    <p>
                      To access/utilize the Site, the Users are required to
                      provide certain personal, non-personal and other
                      information, as indicated below (such information and
                      correspondences hereinafter collectively referred to as
                      <span className="fw-bold">“Information”</span>):
                    </p>
                    <ol type="i">
                      <li>
                        <p className="fw-bold px-2 m-0">Personal Data </p>
                        <p className="px-2">
                          Categories of Personal Data we may require include:
                        </p>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>{" "}
                          Name, email, password, country, city, contact number,
                          and affiliated company/organization upon registration
                          on the Site.
                        </p>
                        <p className="d-flex  align-items-center">
                          <i className="px-2 fa-regular fa-circle-check"></i>
                          Company and its authorized representative information,
                          department information, internal organization
                          structure, Resume information, work experience,
                          education, current/past remuneration, and a copy of
                          the resume upon enrolling on the Site.
                        </p>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>Log
                          information, location details, survey responses, and
                          optional details like caste and eligibility for
                          affirmative action programs.
                        </p>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>
                          Information exchanged with the Company or other users.
                        </p>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>{" "}
                          Technical information about the system, IP Address and
                          interactions via cookies.
                        </p>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>
                          Information shared through linked social media
                          accounts.
                        </p>
                        <h6 className="fw-bolder">Please note :</h6>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>The
                          provided list is illustrative, not exhaustive.
                        </p>
                        <p>
                          <i className="px-2 fa-regular fa-circle-check"></i>
                          Publicly available information is not considered
                          sensitive.
                        </p>
                        <p className="d-flex  align-items-center">
                          <i className="px-2 fa-regular fa-circle-check"></i>
                          User-generated content in public areas of the Site is
                          not personally identifiable under this Privacy Policy.
                          We do not ensure the accuracy or moderation of such
                          content but reserve the right to remove offensive,
                          discriminatory, or inappropriate material at our
                          discretion.
                        </p>
                      </li>
                      <li>
                        <p className="fw-bold px-2 m-0">
                          Non-Personal Information{" "}
                        </p>
                        <p className="px-2">
                          We collect non-personal information during Site
                          visits, including domain, host, IP address, operating
                          system, browser type, and internet service provider.
                          This information is not personally identifiable.
                        </p>
                      </li>
                      <li>
                        <p className="fw-bold px-2 m-0">
                          Automatic Information{" "}
                        </p>
                        <p className="px-2">
                          For enhanced Site responsiveness, we utilize cookies
                          and electronic tools to gather data on individual
                          interests. This includes server logs with details like
                          web requests, IP addresses, browser type, language,
                          date, time, and unique browser identifiers primarily
                          for user authentication.
                        </p>
                      </li>
                      <li>
                        <p className="fw-bold px-2 m-0">Mobile </p>
                        <p className="px-2">
                          When accessing the Site via mobile, we may obtain
                          location and device information, using it to provide
                          service-related suggestions if needed.
                        </p>
                      </li>
                      <li>
                        <p className="fw-bold px-2 m-0">User Communications </p>
                        <p className="px-2">
                          Emails and other communications sent to us are
                          retained to process inquiries, respond to requests,
                          and enhance services.
                        </p>
                      </li>
                    </ol>
                  </li>
                  <li>
                    <p>
                      In case You choose to decline to submit any Information,
                      We may not be able to provide You access to the Site or
                      certain aspects of the Site. We will not be liable and/or
                      responsible for the denial of providing access to the Site
                      to You on account of the inadequacy of Information
                      provided by You.
                    </p>
                  </li>
                  <li>
                    <p>
                      When You register with the Site, We may contact You from
                      time to time about updates of Information to provide You
                      such features that We believe may benefit/interest You.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                USE OF INFORMATION
                <ol type="a">
                  <li>
                    <p>
                      We do not sell, trade, or otherwise transfer to third
                      parties Your Information. This does not include trusted
                      third parties who assist Us in operating Our Site,
                      conducting Our business, or servicing You. We may also
                      release Your information when We believe release is
                      appropriate to comply with the law, enforce Our Site
                      policies, or protect Ours’ or others’ rights, property, or
                      safety. However, non-personally identifiable visitor
                      information may be provided to other parties for
                      marketing, advertising, or other uses.
                    </p>
                  </li>
                  <li>
                    <p>
                      We may process the Information You provide in the
                      following manner:
                    </p>
                    <ol type="i">
                      <li>
                        <p>
                          Personalizing Your experience, enhancing Site
                          features, and improving user-friendliness.
                        </p>
                      </li>
                      <li>
                        <p>
                          Facilitating service delivery, including job alerts,
                          and social media communications.
                        </p>
                      </li>
                      <li>
                        <p>
                          Complying with our Privacy Policy and applicable law
                          in information processing.
                        </p>
                      </li>
                      <li>
                        <p>
                          Utilizing market research for product/service
                          refinement and marketing purposes.
                        </p>
                      </li>
                      <li>
                        <p>
                          Addressing criminal activities, fraud, and breaches of
                          Naukridaddy&#39;s Terms of Use.
                        </p>
                      </li>
                      <li>
                        <p>
                          Analyzing information to suggest relevant
                          products/services and improve Site functionality.
                        </p>
                      </li>
                      <li>
                        <p>
                          Contacting you, customizing communication, and using
                          provided email for updates and information.
                        </p>
                      </li>
                      <li>
                        <p>
                          Preventing fraud, and illegal activities, and
                          protecting Site security, integrity, and users.
                        </p>
                      </li>
                      <li>
                        <p>
                          Allowing customized messages from professionals, and
                          partners, and publishing user-provided testimonials or
                          reviews.
                        </p>
                      </li>
                      <li>
                        <p>
                          Complying with legal obligations, government
                          authorities, resolving disputes, and troubleshooting.
                        </p>
                      </li>
                    </ol>
                  </li>
                </ol>
              </li>
              <li>
                SHARING OF INFORMATION
                <ol type="a">
                  <li>
                    <p>
                      For the purposes of operating the Site and providing
                      services, we may share Your Personal Data with business
                      partners, administrators, vendors, agents, service
                      providers, or affiliates as per applicable law. In cases
                      of business disposition, such as mergers or sales or
                      business acquisition, we may transfer the collected
                      information to the relevant third party.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company unequivocally declares no sale of Your
                      personal data to any third party. Exceptions include legal
                      obligations or justified business purposes under relevant
                      legal frameworks.
                    </p>
                  </li>
                  <li>
                    <p>
                      Access to Your Personal Data is restricted to employees
                      requiring it for job responsibilities. Disclosure
                      circumstances include sharing with affiliates for internal
                      business, with potential recruiters based on resume
                      alignment, and to third parties for legal, regulatory, or
                      judicial requirements. In situations like mergers,
                      acquisitions, or collaborations with third-party service
                      providers and marketing partners, we ensure their
                      obligation to safeguard Your Personal Data and comply with
                      confidentiality commitments. Transfer of Personal Data
                      beyond India&#39;s borders adhere to pertinent data
                      protection laws.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                DATA RETENTION
                <ol type="a">
                  <li>
                    <p>
                      Your information will only be retained by us for the
                      length of time necessary to fulfill our legal requirements
                      or to fulfill our obligations to you. When there are legal
                      reasons to maintain the information, like an ongoing
                      contractual relationship or accounting needs, or when
                      there is a legal storage need, like bookkeeping
                      regulations, we are unable to delete Your information
                    </p>
                  </li>
                  <li>
                    <p>
                      We retain Personal Data for as long as needed or permitted
                      in light of the purpose(s) for which it was obtained and
                      consistent with applicable law. The criteria for
                      determining the duration for which We will keep Your
                      Personal Data are as follows: (i) We will retain copies of
                      Your Personal Data in a form that permits identification
                      only for as long as is necessary in connection with the
                      purposes set out in this Privacy Policy, including for the
                      length of time We have an ongoing relationship with You
                      and provide the Site to You; (ii) if there is a legal
                      obligation to which We are subject (for example, certain
                      laws require Us to keep records of Your transactions for a
                      certain period of time before We can delete them); or
                      (iii) keeping in view the legitimate interests of the
                      Company (litigation or regulatory investigations).
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                SECURITY
                <ol type="a">
                  <li>
                    <p>
                      We use reasonable security measures to protect Information
                      from unauthorized access, maintain data accuracy and help
                      ensure the appropriate use/disclosure of Information. We
                      host the Site in a secure server environment that uses
                      firewalls and other advanced technology to prevent
                      interference or access from outside intruders.
                    </p>
                  </li>
                  <li>
                    <p>
                      We have implemented meticulous technical, organizational,
                      and security protocols to avert the inadvertent loss,
                      misuse, or unauthorized access, alteration, or disclosure
                      of Your Personal Data. Furthermore, access to Your
                      Personal Data is restricted to individuals such as
                      employees, agents, contractors, and select third parties,
                      only when essential for legitimate business purposes and
                      on a need-to-know basis. These authorized entities will
                      exclusively process Your Personal Data based on Our
                      explicit instructions and are strictly bound by rules of
                      confidentiality.
                    </p>
                  </li>
                  <li>
                    <p>
                      Additionally, We have established comprehensive procedures
                      to address any suspected data security breaches. In
                      situations where legal obligations dictate, We commit to
                      promptly notifying You and relevant regulatory bodies of
                      any suspected breaches.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                LINKS TO THIRD PARTY SITES AND ADVERTISING
                <ol type="a">
                  <li>
                    <p>
                      The Site may include links to third-party
                      applications/Websites/Sites (
                      <span className="fw-bold">“Third Party Sites”</span>).
                      Such Third-Party Sites are governed by their respective
                      privacy policies, which are beyond Our control. Once You
                      leave our Site, use of any information You provide is
                      governed by the privacy policy of the operator of the
                      Third-Party Site You are visiting. That policy may differ
                      from Ours. If You can&#39;t find the privacy policy of any
                      of these Third-Party Sites, You should contact the
                      relevant Third Party Site directly for more information.
                    </p>
                  </li>
                  <li>
                    <p>
                      We do not provide any Personal Data or personally
                      identifiable information to third party websites /
                      advertisers / ad-servers without Your consent.
                    </p>
                  </li>
                  <li>
                    <p>
                      We use third-party service providers to display ads on Our
                      behalf across the internet and sometimes on this Site.
                      They may collect anonymous information about Your visits
                      to Our Site. They may also use information about Your
                      visits to this and other Websites to target advertisements
                      for goods and services.
                    </p>
                  </li>
                  <li>
                    <p>
                      This anonymous Information is collected through the use of
                      a pixel tag, which is standard technology used by most
                      major Websites in the industry. No personally identifiable
                      information is collected or used in this process.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                CHILDREN
                <ol type="a">
                  <li>
                    <p>
                      To use the Site, You agree that You must be the minimum
                      age as described in clause 8.2 or older.
                    </p>
                  </li>
                  <li>
                    <p>
                      The minimum age for these purposes shall be 18 (eighteen)
                      years, however, where local laws require that You must be
                      older for the Company to be able to lawfully provide the
                      services over the Site to You then that older age shall
                      apply as the applicable minimum age.
                    </p>
                  </li>
                  <li>
                    <p>
                      Where You are under the age of 18 (eighteen) years or the
                      age of majority in Your jurisdiction, You must use the
                      Site under the supervision of Your parent or legal
                      guardian, wherein the parent or legal guardian shall be
                      responsible for the Site access.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                NOTICE
                <ol type="a">
                  <li>
                    <p>
                      Every request made to the User for consent with regard to
                      the processing of the Personal Data shall be accompanied
                      or preceded by a notice given by the Company to the User,
                      informing the User of the Personal Data and the purpose
                      for which the Personal Data is proposed to be processed.
                      Further, the notice shall include the right to withdraw
                      and the right to seek grievance redressal by the User.
                    </p>
                  </li>
                  <li>
                    <p>
                      The notice shall also include the manner in which the User
                      may make a complaint to the Data Protection Board as may
                      be prescribed under the provisions of law time being in
                      force.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                CONFIDENTIALITY AND SECURITY
                <ol type="a">
                  <li>
                    <p>
                      Safeguarding the security and confidentiality of Your
                      Personal Data holds paramount importance for the Company,
                      and substantial resources have been invested to ensure the
                      protection and confidentiality of Your personal data. In
                      engaging external service providers as processors,
                      adherence to the Company&#39;s standards is mandated.
                      Irrespective of the location of the transfer or storage of
                      Your Personal Data, We undertake all reasonably necessary
                      measures to ensure the secure preservation of Personal
                      Data.
                    </p>
                  </li>
                  <li>
                    <p>
                      Our commitment extends to complying with the stipulations
                      of the Information Technology Act, 2000 and Digital
                      Personal Data Protection Act, 2023, and the associated
                      Rules or any other law for the time being in force,
                      assuring the protection and preservation of Your privacy.
                      By accepting the terms of this Privacy Policy, You
                      expressly acknowledge that the standards and practices
                      implemented by Us are reasonable and adequate for the
                      protection of Your Personal Data.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                SOCIAL MEDIA
                <ol type="a">
                  <li>
                    <p>
                      The Company manages channels, pages, and accounts on
                      various social media platforms to inform, assist, and
                      engage with customers. The company actively observes and
                      archives comments and posts on these channels pertaining
                      to its operations, aiming to enhance the quality of its
                      products and services.
                    </p>
                  </li>
                  <li>
                    <p>
                      It is imperative to refrain from communicating the
                      following information to the Company through these social
                      media Sites:
                    </p>
                    <p className="d-flex  align-items-center">
                      <i className="px-2 fa-regular fa-circle-check"></i>
                      sensitive personal data including (i) special categories
                      of personal data meaning any information revealing racial
                      or ethnic origin, political opinions, religious or
                      philosophical beliefs, or trade union membership, and the
                      processing of genetic data, biometric data for the purpose
                      of uniquely identifying a natural person, data concerning
                      health or data concerning a natural person&#39;s sex life
                      or sexual orientation and (ii) other sensitive personal
                      data such as criminal convictions and offences and
                      national identification number ;
                    </p>
                    <p>
                      <i className="px-2 fa-regular fa-circle-check"></i>
                      excessive, inappropriate, offensive or defamatory content.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company is not responsible for any information posted
                      on those Sites and the information posted by its employees
                      unless on its behalf. The Company is only responsible for
                      its own use of the Personal Data received through such
                      Sites.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                RIGHTS AND DUTIES OF THE USER
                <ol type="a">
                  <li>
                    <p>
                      Users, having given consent, are entitled to request a
                      comprehensive summary of their personal data from the
                      Company. This includes details about the processing
                      activities undertaken and information about entities with
                      whom their data has been shared.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users possess the right to correct, complete, update, or
                      erase their personal data, unless its retention is
                      necessary for the original purpose or for legal
                      compliance. The Company commits to erasing personal data
                      upon user request unless specific legal obligations
                      dictate otherwise.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case of the user's death or incapacity, they retain the
                      right to nominate another individual to act on their
                      behalf. This nominee would then have the authority to
                      exercise the user's rights in accordance with applicable
                      laws and shall abide by the privacy policy.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users have the right to access a readily available means
                      of grievance redressal provided by the Company. However,
                      users are obligated to exhaust internal avenues for
                      grievance resolution before approaching external bodies,
                      such as the Data Protection Board of India.
                    </p>
                  </li>
                  <li>
                    <p>
                      When the processing of personal data is based on user
                      consent, the user can withdraw that consent at any time.
                      The process for withdrawing consent should be as easy as
                      the initial consent-giving process.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users are obliged to comply with all applicable laws while
                      interacting with the Company's services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users must refrain from impersonating another person when
                      providing their personal data for any specified purpose.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users are required to provide full and accurate
                      information without suppressing any material details when
                      submitting personal data for any documentation, unique
                      identifiers, or proofs.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users are prohibited from registering false or frivolous
                      grievances or complaints with the Company or the Data
                      Protection Board of India.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users should furnish only information that is verifiably
                      authentic while exercising the right to correction or
                      erasure under the provisions of the applicable law.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                ACCOUNT PROTECTION
                <p>
                  The secret to Your account or profile is Your password. You
                  will be completely accountable for all actions taken using
                  Your login, as well as for maintaining the security of Your
                  password. Never give out Your password to anybody. You may
                  lose significant control over Your personal information and
                  may be subject to legally binding acts done on Your behalf if
                  You disclose Your password or personal information with
                  others. You are also solely liable for any actions taken under
                  Your username. You should so change Your password right away
                  if Your password has been compromised for whatever reason.
                </p>
              </li>
              <li>
                DISCLAIMER
                <ol type="a">
                  <li>
                    <p>
                      The Company does not store any account related information
                      or any credit / debit card details. The Company shall not
                      be liable for any loss or damage sustained by Users as a
                      result of any disclosure (inadvertent or otherwise) of any
                      information concerning the User's account, credit cards or
                      debit cards in the course of any online transactions or
                      payments made for any products and/or services offered
                      through the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                      b.In case any Personal Data is shared by You with the
                      Company, which is not requested by the Company during
                      registration, (whether mandatorily or optionally), the
                      Company will not be liable for any information security
                      breach or disclosure in relation to such information.
                    </p>
                  </li>
                </ol>
              </li>
            </ol>
          </div>
          <div className="d-flex align-items-center justify-content-center fw-bold">
            <h4>Thank You for using the Site!</h4>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
