import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchComponent from "../components/Search";
import axios from "../utils/axios";
import useScrollToTop from "../hooks/useScrollTop";
import NoResults from "../components/NoResults";
import ReactPaginate from 'react-paginate';

const FindTalent = () => {
	useScrollToTop();
	const [collapsable, setCollapsable] = useState<any>({
		typeOfEmployment: true,
		categories: true,
		jobLevel: true,
		salaryRange: true
	})

	const handleCollapse = (key: any) => {
		setCollapsable({ ...collapsable, [key]: !collapsable[key] })
	}

	const [query, setQuery] = useState<any>({
		term: [],
		location: [],
		category: [],
		skill: [],
		salaryRange: [],
		typeOfEmployment: [],
		pageNumber: 1
	});
	const [jobs, setJobs] = useState<any>([]);

	const [filters, setFilters] = useState<any>({});
	const [total, setTotal] = useState(0);
	const [totalPages, setTotalPages] = useState(0);

	const navigate = useNavigate();

	const handleCategoryChange = (category: string) => {
		const newCategories = query.category.includes(category)
			? query.category.filter((c: string) => c !== category)
			: [...query.category, category];
		onFilterChange({ category: newCategories });
	};

	const handleEmpChange = (empType: string) => {
		const newValues = query.typeOfEmployment.includes(empType)
			? query.typeOfEmployment.filter((c: string) => c !== empType)
			: [...query.typeOfEmployment, empType];
		onFilterChange({ typeOfEmployment: newValues });
	};

	const handleSkillChange = (skill: string) => {
		const newValues = query.skill.includes(skill)
			? query.skill.filter((c: string) => c !== skill)
			: [...query.skill, skill];
		onFilterChange({ skill: newValues });
	};

	const handleSalaryChange = (salary: string) => {
		const newValues = query.salaryRange.includes(salary)
			? query.salaryRange.filter((c: string) => c !== salary)
			: [...query.salaryRange, salary];
		onFilterChange({ salaryRange: newValues });
	};

	const updateUrlWithQueries = (queries: any) => {
		const searchParams: any = new URLSearchParams();
		Object.keys(queries).forEach((key: string) => {
			if (queries[key] && queries[key].length) searchParams.set(key, queries[key].join(","));
		})
		navigate(`/jobs?${searchParams.toString()}`);
	};

	const onFilterChange = (newFilter: any) => {
		const updatedQuery = { ...query, ...newFilter };
		setQuery(updatedQuery);
		updateUrlWithQueries(updatedQuery);
	};

	const onPageChange = ({ selected }: any) => {
		setQuery({ ...query, pageNumber: selected + 1 });
		onSearchTrigger()
	}

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const initialQueries = {
			term: searchParams.get("term")?.split(',') || [],
			location: searchParams.get("location")?.split(',') || [],
			category: searchParams.get("category")?.split(",") || [],
			skill: searchParams.get("skill")?.split(",") || [],
			salaryRange: searchParams.get("salaryRange")?.split(",") || [],
			typeOfEmployment: searchParams.get("typeOfEmployment")?.split(",") || [],
			pageNumber: 1
		};
		setQuery(initialQueries); // This will cause the query state to update
		onSearchTrigger(undefined, undefined, initialQueries);
	}, []); // This ensures it only runs once on component mount


	useEffect(() => {
		onSearchTrigger(undefined, undefined, query);
	}, [query]);

	// Similar functions for employment types, skills, and salary ranges

	const onSearchTrigger = async (job?: string, loc?: string, iq?: any) => {
		try {
			if (job) setQuery({ ...query, term: job.split(',') })
			if (loc) setQuery({ ...query, location: loc.split(',') })
			let options: any = { ...iq };
			let finalFilter: any = iq ? { ...options } : { ...query };
			const { data } = await axios.post("/api/search/jobs", finalFilter);
			setFilters(data?.aggregations);
			setJobs(data.jobs);
			setTotal(data.totalHits);
			setTotalPages(data.totalPages);
		} catch (error) {
			console.log(error);
		}
	};

	const openJob = ({ _source }: any) => {
		const searchParams = new URLSearchParams();
		const jobQuery: any = {
			title: [_source.title],
			category: _source.category,
			salaryRange: [_source.salaryRange],
			skill: _source.skill,
			typeOfEmployment: _source.typeOfEmployment,
			location: _source.location,
			internal: [_source.jobId]
		}
		Object.keys(jobQuery).forEach((key: string) => {
			if (jobQuery[key] && jobQuery[key].length) searchParams.set(key, jobQuery[key].join(","));
		})

		// searchParams.set("internal", _source.jobId); // TBD
		navigate("/job-details?" + searchParams.toString());
	};

	const truncateString = (str: string, maxLength: number, appendStr = '...') => {
		if (str.length <= maxLength) {
			return str;
		}
		return str.substring(0, maxLength - appendStr.length) + appendStr;
	}


	return (
		<>
			<Header />
			<div className="find-talent">
				<div className="first-container">
					<div className="find-talent-upper">
						<div>
							<h1>
								{" "}
								Find your{" "}
								<span className="text-skyblue border-bottom-skyblue pb-1">
									dream job
								</span>
							</h1>
						</div>
						<div className="big-para">
							Find your next career at companies like HubSpot, Nike, and Dropbox
						</div>
						<SearchComponent onSearchTrigger={onSearchTrigger} inp1={query.term.toString()} inp2={query.location.toString()} isJobSearch={true} page={"talent"} />
						
					</div>
					<p className="para">Popular : UI Designer, UX Researcher, Android, Admin</p>
				</div>

				{/* second_main container- start */}
				<div className="container">
					{/* second container- start */}
					{jobs.length !== 0 && <div className="allJobs-container ">
						<div className="dropdown-section">
							<div className="d-flex justify-content-between">
								<h6>Type of Employment </h6>{" "}
								<Button onClick={() => { handleCollapse('typeOfEmployment') }} className="mt-1">
									<i className={collapsable.typeOfEmployment ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.typeOfEmployment && (
								<form className="mb-3">
									{filters?.employment_type_agg?.buckets.map(
										(employment: any, index: number) => {
											return (
												<div key={"employmenttype_" + index}>
													<input
														checked={query.typeOfEmployment.includes(
															employment.key
														)}
														onChange={() => handleEmpChange(employment.key)}
														type="checkbox"
														name="EmploymentType"
														id={employment.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={employment.key + "_checkboxes"}
													>
														{truncateString(employment.key, 18)}
														{"(" + employment.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}

								</form>
							)}

							<div className="d-flex justify-content-between mt-4">
								<h6>Categories </h6>{" "}
								<Button onClick={() => { handleCollapse('categories') }} className="mt-1">
									<i className={collapsable.categories ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.categories && (
								<form className="mb-3">
									{filters?.category_agg?.buckets.map(
										(categories: any, index: number) => {
											return (
												<div key={"category_" + index}>
													<input
														checked={query.category.includes(categories.key)}
														onChange={() => handleCategoryChange(categories.key)}
														type="checkbox"
														name="Category"
														id={categories.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={categories.key + "_checkboxes"}
													>
														{truncateString(categories?.key, 20)}
														{"(" + categories?.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}

								</form>
							)}

							<div className="d-flex justify-content-between mt-4">
								<h6>Job Level </h6>{" "}
								<Button onClick={() => { handleCollapse('jobLevel') }} className="mt-1">
									<i className={collapsable.jobLevel ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.jobLevel && (
								<form className="mb-3">
									{filters?.skill_agg?.buckets.map(
										(skill: any, index: number) => {
											return (
												<div key={"skill_" + index}>
													<input
														checked={query.skill.includes(skill.key)}
														onChange={() => handleSkillChange(skill.key)}
														type="checkbox"
														name="Skill"
														id={skill.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={skill.key + "_checkboxes"}
													>
														{truncateString(skill?.key, 20)}
														{"(" + skill?.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}

								</form>
							)}

							<div className="d-flex justify-content-between mt-4">
								<h6>Salary Range </h6>{" "}
								<Button onClick={() => { handleCollapse('salaryRange') }} className="mt-1">
									<i className={collapsable.salaryRange ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.salaryRange && (
								<form className="">
									{filters?.salary_range_agg?.buckets.map(
										(salary: any, index: number) => {
											return (
												<div key={"salary_" + index}>
													<input
														checked={query.salaryRange.includes(salary.key)}
														onChange={() => handleSalaryChange(salary.key)}
														type="checkbox"
														name="full-time"
														id={salary.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={salary.key + "_checkboxes"}
													>
														{salary?.key.split("to")[0]}-
														{salary?.key.split("to")[1]} Lakhs
														{"(" + salary?.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}
								</form>
							)}
						</div>
						<div className="allJobs-section">
							<div className="first-row">
								<div className="all-jobs">
									<h3 className="">All Jobs</h3>
									<p className="para">
										Showing  {jobs.length} results of {total}
									</p>
								</div>
								<div className="sorted-by">
									<span className="para">Sort by : </span>
									<span>
										<select className="">
											<option>Most relevant</option>
											<option>Date posted</option>
										</select>
									</span>

								</div>
							</div>
							{jobs?.map((job: any, index: number) => {
								return (
									<div
										key={"jobs_" + index}
										className="box-assistant primary-border"
									>
										<div className="assistant_side d-flex gap-2">
											<div className="icon_assistant">
												<img
													alt=""
													height={"65px"}
													src={job._source.companyLogo}
												/>
											</div>
											<div className="">
												<h6>{job?._source?.title}</h6>
												{job?._source?.company}{" "}
												<span className="rounded-5 align-text-bottom"> . </span>
												<span className="para">
													{job?._source?.location[0]}
												</span>
												<div className="buttons d-flex flex-wrap gap-2 mt-2">
													{job?._source?.typeOfEmployment.map(
														(employment: any) => {
															return (
																<>
																	<div className="full-time-btn">
																		{employment}
																	</div>
																	<span className="divider-right"></span>
																</>
															);
														}
													)}

													{job?._source?.category.map((category: any) => {
														return (
															<div className="design-btn">{category}</div>
														);
													})}
												</div>
											</div>
										</div>
										<div className="button_side">
											<Button
												onClick={() => openJob(job)}
												type="button"
												className="primary-btn w-100"
											>
												Apply
											</Button>
											<div className="d-flex gap-1 mt-2">
												<b className="m-0 p-0">
													{job?._source?.applicants} applied
												</b>
												<p className="para">
													{" "}
													of {job?._source?.capacity} capacity{" "}
												</p>
											</div>
										</div>
									</div>
								);
							})}

							{totalPages > 1 && <ReactPaginate
								breakLabel="..."
								nextLabel="Next"
								onPageChange={onPageChange}
								pageRangeDisplayed={5}
								pageCount={totalPages}
								previousLabel="Previous"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
							/>}
						</div>
					</div>}
					{jobs.length === 0 &&
						<NoResults onGoBack={() => navigate(-1)} />}
				</div>
				<Footer />
			</div>
		</>
	);
};

export default FindTalent;
