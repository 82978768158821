import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
// import "../assets/css/HomeNav.css";
import { Button } from "react-bootstrap";
import StaticLogo from "../assets/img/White Final.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import UserImage from "../../src/assets/img/Aaron-avatar.png";
export default function Header() {
  let user = false;

  const navigate = useNavigate()

  return (
    <>
      <div className="header"  >
        <Navbar expand="lg" className="home-nav-bar">
          <Container className="nav-bar">
            <div className="main-brand">
              <Navbar.Brand className="">
                <Link to="/">
                  <div className="">
                    <img src={StaticLogo} alt="Logo" width="200px" />
                  </div>
                </Link>
              </Navbar.Brand>
            </div>
            <Navbar.Toggle className="" aria-controls="" />
            <div className="nav-right">
              <Navbar.Collapse id="">
                {user ? (
                  <>
                    <Nav
                      className="me-auto "
                    // style={{ maxHeight: "370px", fontSize: "16px", lineHeight: "1" }}
                    >
                      <div className="nav-items d-flex gap-3">
                        <Link className="" to="/dashboard">
                          Dashboard
                        </Link>
                        <Link className="" to="/find_talent">
                          Find Talent
                        </Link>
                        <Link className="" to="/companies">
                          Companies
                        </Link>
                        <Link className="" to="/my_application">
                          My Application
                        </Link>
                        <Link className="" to="/self_analysis">
                          Self Analysis
                        </Link>
                      </div>
                      <div className="profile">
                        <div className="user-img">
                          <img src={UserImage} alt="" width={"50px"} />
                        </div>
                        <div className="user-name">
                          <h6>Mr. Manny Yug</h6>

                          <p>mannyyug@gmail.com</p>
                        </div>
                        <div className="icon-down">
                          <Button className="bg-transparent border-0">
                            <i className="fa-solid fa-chevron-down"></i>
                          </Button>
                        </div>
                      </div>
                    </Nav>
                  </>
                ) : (
                  <>
                    <Nav
                      className=""
                    // style={{ maxHeight: "300px", fontSize: "16px", lineHeight: "1" }}
                    >
                      <div className="nav-items d-flex gap-3">
                        <NavLink className="" to="/jobs">
                          Find Talent
                        </NavLink>
                        <NavLink className="" to="/companies">
                          Companies
                        </NavLink>
                        {/* <NavLink className="" to="/community">
                          Community
                        </NavLink> */}
                        <NavLink className="" to="/projects">
                          Projects
                        </NavLink>
                      </div>
                      <div className="nav-buttons">
                        <Button onClick={() => navigate('/login')} className="btn1">
                          <span className=""> Login</span>
                        </Button>
                        <div className="divider-nav"></div>
                        <Button onClick={() => navigate('/signup')} className="btn2">
                          <span className=""> Sign Up</span>
                        </Button>{" "}
                      </div>
                    </Nav>
                  </>
                )}
              </Navbar.Collapse>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
}
