import React, { useEffect, useRef, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';

import Header from '../components/Header'
import Footer from '../components/Footer'
import axios from "../utils/axios";
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';

interface Registration {
    FullName: string;
    Email: string;
    PhoneNumber: string;
    Gender: string;
    DateOfBirth: string;
    JobTitle: string;
    JobLocation: string;
    PreferredLocation: string;
    NoticePeriod: number;
    CurrentSalary: number;
    ExpectedSalary: number;
    ProfileSummary: string;
    TermsAgreed: boolean;
}

const CompleteSignup = (props: any) => {
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<Registration>({ mode: 'onTouched' });
    const parser = new DOMParser();

    // const [value, setValue] = useState('');
    const [summaryLength, setSummaryLength] = useState(0);
    const [resume, setResume] = useState<any>()
    const resumeInput = useRef<any>()

    const rules = {
        FullName: register('FullName', {
            required: 'Full name is required',
            maxLength: { value: 50, message: 'Full name cannot have more than 50 characters' }
        }),
        Email: register('Email', {
            disabled: true
        }),
        PhoneNumber: register('PhoneNumber', {
            required: 'Phone number is required',
        }),
        Gender: register('Gender', {}),
        DateOfBirth: register('DateOfBirth', {
            required: 'Date of birth is required',
        }),
        JobTitle: register('JobTitle', {}),
        JobLocation: register('JobLocation', {}),
        PreferredLocation: register('PreferredLocation', {}),
        NoticePeriod: register('NoticePeriod', {}),
        CurrentSalary: register('CurrentSalary', {}),
        ExpectedSalary: register('ExpectedSalary', {}),
        ProfileSummary: register('ProfileSummary', {}),
        TermsAgreed: register('TermsAgreed', {
            required: 'Please check the above checkbox!'
        }),
    }




    const wordCount = (html: string) => {
        const doc = parser.parseFromString(html, 'text/html');
        const text = doc.body.textContent || "";
        setSummaryLength(text.length)
    }

    const onResumeSelected = (event: any) => {
        setResume(event.target.files[0]);
    }

    const onSubmit = async (regData: Registration) => {
        try {
            const formData = new FormData();
            formData.append('resume', resume)
            formData.append('details', JSON.stringify(regData));
            await axios.post('/api/jobseeker/details', formData);
            navigate('/dashboard');
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        const GetCandidateInfo = async () => {
            try {
                const { data } = await axios.get('/api/jobseeker/details')
                console.log(data);
                setValue('FullName', data.FullName)
                setValue('Email', data.ContactDetails.Email)
            } catch (error) {
                console.log(error);

            }
        }

        GetCandidateInfo()
    }, [setValue])

    return (
        <>
            <Header />
            <div style={{  }} className='container complete-signup-page'>
                <h4 className=''> Candidate Registration Details </h4>
               <div id ='horizontal-line'></div>
                <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className=''>Full Name</Form.Label> 
                    <Form.Control {...rules.FullName} className='w-100' type="text" placeholder='Enter your fullname' />
                    {errors.FullName && <div className="error">{'' + errors.FullName.message}</div>}
                    </Form.Group>
                    <div className='row'>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''> Email Address</Form.Label> 
                            <Form.Control {...rules.Email} disabled className='w-100 ' type="text" placeholder='Enter your email address' />
                            {errors.Email && <div className="error">{'' + errors.Email.message}</div>}
                            </Form.Group>   </div>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''>Phone number</Form.Label> 
                            <Form.Control {...rules.PhoneNumber} className='w-100 ' type="number" placeholder='Enter your phone number' />
                            {errors.PhoneNumber && <div className="error">{'' + errors.PhoneNumber.message}</div>}
                            </Form.Group>  </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''> Gender </Form.Label> 
                            <Form.Select {...rules.Gender} className='w-100' >
                                <option>Male</option>
                                <option>Female</option>
                             
                            </Form.Select>
                            {errors.Gender && <div className="error">{'' + errors.Gender.message}</div>}
                            </Form.Group> </div>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''>Date of Birth </Form.Label> 
                            <Form.Control {...rules.DateOfBirth} className='w-100 ' type="date" placeholder='' />
                            {errors.DateOfBirth && <div className="error">{'' + errors.DateOfBirth.message}</div>}
                            </Form.Group> </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''>Current or Previous job title</Form.Label> 
                            <Form.Control {...rules.JobTitle} className='w-100 ' type="text" placeholder='UX Designer' />
                            </Form.Group> </div>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''>Job Location</Form.Label> 
                            <Form.Control {...rules.JobLocation} className='w-100 ' type="text" placeholder='Noida' />
                            </Form.Group>  </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''> Preferred Location</Form.Label> 
                            <Form.Control {...rules.PreferredLocation} className='w-100 ' type="text" placeholder='Delhi NCR, Banglore, Mumbai' />
                            </Form.Group> </div>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''>Notice Period in Week</Form.Label> 
                            <Form.Control {...rules.NoticePeriod} step={1} min={0} className='w-100 ' type="number" placeholder='6' />
                            </Form.Group> </div>
                    </div>
                    <div className='row'>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''> Current Salary in lakh</Form.Label> 
                            <Form.Control {...rules.CurrentSalary} step={1} min={0} className='w-100 ' type="number" placeholder='16' />
                            </Form.Group></div>
                        <div className='col-6'>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className=''>Expected Salary</Form.Label> 
                            <Form.Control {...rules.ExpectedSalary} step={1} min={0} className='w-100 ' type="number" placeholder='30' />
                            </Form.Group> </div>
                    </div>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label className=''><h6>Attach Your Resume</h6></Form.Label>
                    <Form.Control onChange={onResumeSelected} ref={resumeInput} type="file" hidden />
                    <Button onClick={() => resumeInput.current.click()} style={{
                        border: "2px dashed rgb(45, 150, 231)",
                        padding: "40px",
                        color:"#515B6F",
                        backgroundColor:"#F8F8FD"
                    }} className='w-100   fw-bold rounded-1'> <span style={{ color: "#1FA0FF", fontSize: "18px" }} className="px-2">
                            <i className="fa-solid fa-paperclip"></i>
                        </span> Attach Resume / CV</Button>
                        </Form.Group>

                    <Form.Group className="" controlId="exampleForm.ControlTextarea1">
                        <Form.Label className="">
                           <h6> About Me Summary</h6>
                        </Form.Label>
                        <ReactQuill className='rounded-2' placeholder='Please write a short summary about yourself...' theme="snow" onChange={(v) => { setValue('ProfileSummary', v); wordCount(v) }} />
                    </Form.Group>
                    <div style={{  marginBottom: "30px" }} className="d-flex para mt-2  justify-content-between">
                        <span className="">Maximun 500 characters</span>
                        <span  className="">{summaryLength}/500</span>
                    </div>
                    <div className='d-flex align-items-center'>
                        <Form.Check {...rules.TermsAgreed} className='largerCheckbox' type='checkbox' />
                        <label   className="px-2 big-para ">By sending the request you confirm that you accept our <Link to="/terms-use" target={'_blank'}>Terms of Service</Link> and <Link to="/privacy-policy" target={'_blank'}>Privacy Policy</Link></label>
                    </div>
                    {errors.TermsAgreed && <div className="error">{'' + errors.TermsAgreed.message}</div>}
                    <div className=''>
                        <Button type='submit'  className="primary-btn w-50 m-auto mt-5">
                            Submit Application
                        </Button>
                    </div>
                </Form>


            </div>
            <Footer />
        </>
    )
}

export default CompleteSignup
