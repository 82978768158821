import { Button } from "react-bootstrap";


const EmployerApplicants = () => {

    return (<>
<div className="applicants-section">
<div className="applicants-header">
            <div className="">
                <h4>Total Applicants : 19</h4>
            </div>
            <div className="">
                <div className="search-box primary-border">
                    <i className="fa-solid fa-magnifying-glass"></i> <input type="search" placeholder="Search Applicants"></input>
                </div>
                <Button className="filter-btn  primary-border">
                    <i className="fa-solid fa-arrow-down-short-wide"></i> Filter
                </Button>
            </div>
        </div>
        
</div>
    </>)
}

export default EmployerApplicants;