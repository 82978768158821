// src/app/store.ts
import { configureStore } from '@reduxjs/toolkit';
import employerReducer from './slices/employer';

export const store = configureStore({
    reducer: {
        employer: employerReducer,
        // Add other slices as needed
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
