import { Button } from "react-bootstrap";
import axios from "../utils/axios";
import Autocomplete from "./common/AutoComplete";
import { useEffect, useState } from "react";

const SearchComponent = ({ page, onSearchTrigger, isJobSearch }: any) => {
    const searchParams = new URLSearchParams(window.location.search)
    const [jobKeyword, setJobKeyword]: any = useState(searchParams.get('term') || '')
    const [locationKeyword, setLocationJobKeyword]: any = useState(searchParams.get('location') || '')

    const fetchSuggestions = async (inputValue: string) => {
        const { data } = await axios.get(`/api/search/suggestions?keyword=${inputValue}&area=${isJobSearch ? 'job' : 'company'}`);
        return data;
    }

    const fetchLocationSuggests = async (inputValue: string) => {
        const { data } = await axios.get(`/api/search/suggestions?keyword=${inputValue}&area=${isJobSearch ? 'job' : 'company'}&type=location`);
        return data;
    }

    const onJobSearch = () => {
        onSearchTrigger && onSearchTrigger(jobKeyword.toString(), locationKeyword.toString());
    }

    return (<div className={"shadow-sm search-buttons d-flex justify-content-between" + (page ? ' custom-gap' : '')}>
        <div className="search-job" style={{width:"100%"}}>
            <i className="fa-solid fa-magnifying-glass"></i>{" "}
            <Autocomplete defaultValues={jobKeyword} placeholder={isJobSearch ? "Search for jobs..." : 'Search for companies...'} onValue={setJobKeyword} suggestionCallback={fetchSuggestions} />
        </div>
        <div className="search-job" style={{width:"100%"}}>
            <i className="fa-solid fa-location-dot"></i>{" "}
            <Autocomplete defaultValues={locationKeyword} placeholder="Location" onValue={setLocationJobKeyword} suggestionCallback={fetchLocationSuggests} />
        </div>
        
            <Button className="search-submit-btn" onClick={onJobSearch}>
                <i className="fa-solid fa-magnifying-glass"></i> Search
            </Button>
       
    </div>)
}

export default SearchComponent;