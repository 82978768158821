import { Button } from 'react-bootstrap'
import CheckIcon from "../assets/img/check-arrow image.png"

const EmailVerified = ({ goBack }: any) => {

    return (
        <>
            <div className='suceess-section'>
                <div className='success-box'>
                    <div className='check-arrow'><img src={CheckIcon} alt="" /></div>
                    <h4 className=''>Email Verified</h4>
                    <div className='para'>We've successfully verified your email address. Please login to continue.</div>
                    <Button onClick={() => window.location.href='/login?target=employer'} type="button" className='mail-btn w-75 m-auto rounded-5 '>Login</Button>
                    {/* <div className='para'>Back to <a style={{ textDecoration: "none", color: "#377DFF", gap: "10px" }} href='javascript:void(0)'>Login</a></div> */}
                </div>
            </div>
        </>
    )
}

export default EmailVerified