import { Button, Col, Form, Row } from "react-bootstrap"
import QuillInput from "../../common/QuillInput"
import { useForm } from "react-hook-form";
import { type SettingOverviewModel } from "../../../types/setting";
import React, { useEffect, useRef, useState } from 'react'
import axios from "../../../utils/axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const OverviewSettings = ({ activeTabs, onNext, data = {} }: any) => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<SettingOverviewModel>(data);
    const [companyLogo, setCompanyLogo] = useState<any>(null)
    const resumeInput = useRef<any>()
    const [logo, setLogo] = useState<any>()
    const [dateFounded, setDateFoundedDate] = useState<any>()
    const [aboutCompany, setAboutCompany] = useState<any>()
    
    const onResumeSelected = (event: any) => {

        const reader = new FileReader()
        setLogo(event.target.files[0])
        reader.onloadend = () => {
            setCompanyLogo(reader.result);
        };

        // Read the selected file as a data URL
        if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]);

        }
    }

    const postOverviewSetting = async (overviewdata: any) => {
        try {
            const formData = new FormData();
            formData.append('Logo', logo)
            overviewdata['DateFounded'] = dateFounded
            overviewdata['AboutCompany'] = aboutCompany
            formData.append('details', JSON.stringify(overviewdata));

            // const { data : {url} } = await axios.post('/api/employer/getSignedUrl',{name:logo.name,contentType :logo.type});
            // let formData1 = new FormData()
            // formData1.append('file',logo)
            // let response  = await axios.put(url,formData1)
            // console.log(response);

            const { data } = await axios.post('/api/employer/saveOverviewSetting', formData);

        } catch (error) {
            console.log(error);
        }
    }
    return (
        <form onSubmit={handleSubmit(postOverviewSetting)} className="step-section-one">
            <div className="overview-section">
                <Row>
                    <Col lg md="12">
                        <h5 className="">Basic Information</h5>
                        <p className="para">
                            This is company information that you can update anytime.
                        </p>
                    </Col>
                </Row>
                <div id="horizontal-line"></div>

                <Row className="gap-5">
                    <Col lg="3" md="10">
                        <h6 className="">Company Logo</h6>
                        <p className="para">
                            This image will be shown publicly as company logo.
                        </p>
                    </Col>
                    <Col lg="6" md="10" className="d-flex align-items-center gap-5">
                        <div className="company-file">
                            <div className="file-logo">
                                {!companyLogo ? <i className="fa-solid fa-image"></i> : <img  src={companyLogo} alt="" />}
                            </div>
                            <div style={{ color: "#515B6F" }} className="">
                                <span style={{ color: "#1FA0FF", fontWeight: "500" }}>
                                    <input onChange={onResumeSelected} ref={resumeInput} type="file" hidden />
                                    <span onClick={() => resumeInput.current.click()} >
                                        {!companyLogo ? 'Select Logo' : 'Change Logo'}
                                    </span>

                                </span>
                            </div>
                            {/* <p className="para">SVG, PNG, JPG or GIF (max. 400 x 400px)</p> */}
                        </div>
                    </Col>
                </Row>
                <div id="horizontal-line"></div>

                <Row className="gap-5">
                    <Col lg="3" md="10">
                        <h6 className="">Company Details</h6>
                        <p className="para">
                            Introduce your company core info quickly to users by fill up
                            company details
                        </p>
                    </Col>
                    <Col lg="6" md="10" className="d-flex flex-column">
                        <Form>
                            <Form.Group
                                className="mb-4"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label className="p-0 m-0 mb-1">
                                    <span className="fw-bold">Company Name</span>
                                </Form.Label>
                                <Form.Control type="text" id="CompanyName" placeholder="Company Name"
                                    {...register('Name', { required: 'Company name is required !' })} />
                            </Form.Group>

                            <Form.Group
                                className="mb-3"
                                controlId="exampleForm.ControlInput1"
                            >
                                <Form.Label className="p-0 m-0 mb-1">
                                    <span className="fw-bold">Website</span>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    id="CompanyWebsite"
                                    placeholder="https://www.naukri-daddy.com"
                                    {...register('Website', { required: 'Website link is required !' })}
                                />
                            </Form.Group>
                            <Form.Label className="p-0 m-0 mb-1">
                                <span className="fw-bold">Location</span>
                            </Form.Label>
                            <Form.Select {...register('Locations')} className="mb-3" size="lg">
                                <option value='England'>England</option>
                                <option value='Japan'>Japan</option>
                                <option value='Austrila'>Australia</option>
                            </Form.Select>
                            <Row className="d-flex justify-content-between flex-wrap mb-3">
                                <Col style={{ width: "49%" }} sm="12">
                                    <Form.Label className="p-0 m-0 mb-1">
                                        <span className="fw-bold">Employee</span>
                                    </Form.Label>
                                    <Form.Select {...register('Employees')} size="lg">
                                        <option value="1-50">1 - 50</option>
                                        <option value="1-100">1 - 100</option>
                                        <option value="1-200">1 - 200</option>
                                    </Form.Select>
                                </Col>
                                <Col style={{ width: "49%" }} sm="12">
                                    <Form.Label className="p-0 m-0 mb-1">
                                        <span className="fw-bold">Industry</span>
                                    </Form.Label>
                                    <Form.Select {...register('Industry')} size="lg">
                                        <option value='Technology'>Technology</option>
                                        <option value='Development'>Development</option>
                                        <option value='Business'>Business</option>
                                    </Form.Select>
                                </Col>
                            </Row>
                            <Row className="d-flex justify-content-between align-items-center flex-wrap mb-3">
                                
                                <Form.Label className="p-0 m-0 mb-1">
                                    <span className="fw-bold">Date Founded</span>
                                </Form.Label>

                                <DatePicker className="form-control"
                                    minDate={new Date()}
                                    dateFormat={'dd/MM/yyyy'}
                                    placeholderText="Select a due date"

                                    selected={dateFounded} onChange={(date) => setDateFoundedDate(date)}></DatePicker>


                            </Row>

                            <Form.Label className="p-0 m-0 mb-1">
                                <span className="fw-bold">Tech Stack</span>
                            </Form.Label>
                            <Form.Select  {...register('TechStack')} className="mb-3" size="lg">
                                <option value='HTML 5'>HTML 5</option>
                                <option value='CSS 3'>CSS 3</option>
                                <option value='Javascript'>JavaScript</option>
                            </Form.Select>
                        </Form>
                    </Col>
                </Row>
                <div id="horizontal-line"></div>
                <Row className="">
                    <Col lg="3" md="10">
                        <h6 className="">About Company</h6>
                        <p className="para">
                            Brief description for your company. URLs are hyperlinked.
                        </p>
                    </Col>
                    <Col lg="9" md="10" style={{ paddingLeft: "55px" }}>
                        <Form.Label className="p-0 m-0 mb-1">
                            <span className="fw-bold">Description</span>
                        </Form.Label>
                        <QuillInput placeholder='' onChange={(value: string) => setAboutCompany(value)} theme="snow" />
                    </Col>
                </Row>
                <div id="horizontal-line"></div>

                <div className="end-btn">
                    <Button className="primary-btn" type="submit">Save Changes</Button>
                </div>
            </div>
        </form>)
}

export default OverviewSettings;