import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import CheckIcon from "../assets/img/check-arrow image.png"
export default function SuccessSignup() {
  const navigate = useNavigate();

  return (
    <>
      <div className='suceess-section'>

        <div className='success-box'>
          <div className='check-arrow'><img src={CheckIcon} alt="" /></div>
          <h4 className=''>Successfully created your account </h4>
          <div className='para'>We've created your account. Kindly login and complete your profile.</div>
          <Button onClick={() => navigate('/login?target=employer')} type="button" className='mail-btn w-75 m-auto rounded-5 '>Login</Button>
          <div className='para'>Back to <Link style={{ textDecoration: "none", color: "#377DFF", gap: "10px" }} className='' to="/">Main Site</Link></div>
        </div>
      </div>
    </>
  )
}
