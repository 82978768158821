import { Button, Col, Form, InputGroup, Row } from "react-bootstrap";
import DualRangeSlider from "./DualRangeSlider";
import { useState } from "react";
import AsyncSelect from 'react-select/async';
import axios from "../../../utils/axios";
import { useForm } from "react-hook-form";
import { type JobInformationModel } from "../../../types/Job";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const JobInformation = ({ setStep, onNext, data = {} }: any) => {

    const { register, handleSubmit, setValue, formState: { errors } } = useForm<JobInformationModel>(data);

    const fetchCategories = async (inputValue: string) => {
        const { data } = await axios.get('/api/job/categories?keyword=' + inputValue);
        return data.map((d: any) => { return { value: d._id, label: d.Name } })
    }

    const fetchSkills = async (inputValue: string) => {
        const { data } = await axios.get('/api/job/skills?keyword=' + inputValue);
        return data.map((d: any) => { return { value: d._id, label: d.Name } })
    }

    const [minValue, setMinValue] = useState(30000);
    const [maxValue, setMaxValue] = useState(50000);
    const [startDate, setStartDate] = useState();

    const handleMinChange = (e: any) => {
        const value = Math.min(Number(e.target.value), maxValue);
        setMinValue(value);
    };

    const handleMaxChange = (e: any) => {
        const value = Math.max(Number(e.target.value), minValue);
        setMaxValue(value);
    };

    const onCategoryChange = (categories: any) => setValue('Categories', categories.map((c: any) => c.value));
    const onSkillsChange = (skills: any) => setValue('RequiredSkills', skills.map((c: any) => c.value));

    const onSubmit = (data: any) => {
        onNext && onNext(data);
        setStep('description');
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="step-section-one">
            <Row>
                <Col lg md="12">
                    <h5 className="">Basic Information</h5>
                    <p className="para">
                        This information will be displayed publicly
                    </p>
                </Col>
            </Row>
            <div id="horizontal-line"></div>

            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Job Title</h6>
                    <p className="para">Job titles must be describe one position</p>
                </Col>
                <Col lg="4" md="10">
                    <Form.Control
                        placeholder="e.g. Software Engineer"
                        id="jobTitle"
                        {...register('JobTitle', { required: 'Job title is required' })}
                    />
                    {errors.JobTitle && <span className="error">{errors.JobTitle.message}</span>}
                </Col>
            </Row>
            <div id="horizontal-line"></div>


            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Type of Employment</h6>
                    <p className="para">You must define type of employment for better search.</p>
                </Col>
                <Col lg="4" md="10" className="d-flex flex-column gap-2">
                    {["Full-Time", "Part-Time", "Remote", "Internship", "Contract"].map((item, index) => (
                        <div key={'employment_type' + index}>
                            <input id={item} value={item} {...register("EmploymentType")} type="radio" className="form-check-input" />
                            <label htmlFor={item}>{item}</label>
                        </div>
                    ))}
                </Col>
            </Row>
            <div id="horizontal-line"></div>

            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Salary Range</h6>
                    <p className="para">Please specify the estimated salary range for the role.</p>
                </Col>
                <Col lg="4" md="10">
                    <div className="d-flex align-items-center justify-content-between">
                        <InputGroup>
                            <InputGroup.Text>&#8377;</InputGroup.Text>
                            <Form.Control
                                value={minValue}
                                onChange={handleMinChange}
                                min={0}
                                max={maxValue}
                                step={1000}
                                size="sm"
                                type="number"
                            />
                        </InputGroup>
                        <span className="mx-2">To</span>
                        <InputGroup>
                            <InputGroup.Text>&#8377;</InputGroup.Text>
                            <Form.Control
                                value={maxValue}
                                onChange={handleMaxChange}
                                min={minValue}
                                step={1000}
                                size="sm"
                                type="number"
                            />
                        </InputGroup>
                    </div>
                </Col>
            </Row>
            <div id="horizontal-line"></div>


            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Categories </h6>
                    <p className="para">You can select multiple job categories</p>
                </Col>
                <Col lg="4" md="10">
                    <h6>Select Job Category</h6>
                    <AsyncSelect isMulti cacheOptions onChange={onCategoryChange} defaultOptions loadOptions={fetchCategories} />
                </Col>
            </Row>
            <div id="horizontal-line"></div>

            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Required Skills </h6>
                    <p className="para">Add required skills for the job</p>
                </Col>

                <Col lg="4" md="10" className="d-flex flex-column">
                    <h6>Select Skills</h6>
                    <AsyncSelect isMulti cacheOptions onChange={onSkillsChange} defaultOptions loadOptions={fetchSkills} />
                </Col>
            </Row>
            <div id="horizontal-line"></div>
            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Positions Open</h6>
                    <p className="para">Specify the number of positions required for this job</p>
                </Col>

                <Col lg="4" md="10" className="d-flex flex-column">
                    <Form.Control
                        placeholder="e.g. 5"
                        type="number"
                        min={1}
                        step={1}
                        id="PositionsOpen"
                        {...register('PositionsOpen', { required: 'You must specify number of positions open' })}
                    />
                    {errors.PositionsOpen && <span className="error">{errors.PositionsOpen.message}</span>}
                </Col>
            </Row>

            <div id="horizontal-line"></div>
            <Row className="gap-5 align-items-center">
                <Col lg="3" md="10">
                    <h6 className="">Due Date</h6>
                    <p className="para">Specify the due date for this job</p>
                </Col>

                <Col lg="4" md="10" className="d-flex flex-column">
                    <DatePicker
                        minDate={new Date()}
                        dateFormat={'dd/MM/yyyy'}
                        placeholderText="Select a due date"
                        className="form-control"
                        {...register('DueDate', { required: 'You must specify due date' })}
                        selected={startDate}
                        onChange={(date: any) => {
                            setValue('DueDate', date); 
                            setStartDate(date);
                        }}
                    />
                    {errors.DueDate && <span className="error">{errors.DueDate.message}</span>}
                </Col>
            </Row>

            <div id="horizontal-line"></div>
            <div className="end-btn">
                <Button className="primary-btn" type="submit">Next Step</Button>
            </div>
        </form>
    )
}

export default JobInformation;