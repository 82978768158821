import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { Container } from 'react-bootstrap'
import useScrollToTop from '../../hooks/useScrollTop';

const BlogsAndResources = () => {
    useScrollToTop();
    return (
        <>
            <Header />
            <div className="policies-section">
                <div className="first-row">
                    <h1>BLOGS AND RESOURCES</h1>
                </div>
                <Container>
                    <div className='list-section'>
                        <section className='mb-3 p-3'>
                            <p>
                                At NaukriDaddy, we&#39;re committed to providing valuable insights and resources to both recruiters and
                                job seekers to help them navigate the ever-evolving world of recruitment and career development. Our
                                blog and resources section is your go-to destination for industry trends, best practices, expert advice,
                                and practical tips to enhance your recruitment strategies and career growth. Explore the following
                                categories to discover a wealth of knowledge:
                            </p>
                        </section>


                        <section className='mb-4 p-3'>
                            <article className='pb-3'>
                                <h5>Recruitment Strategies</h5>
                                <p>Stay ahead of the competition with our expert insights into the latest recruitment strategies and tactics.
                                    Learn how to attract top talent, optimize your hiring process, and build a strong employer brand to
                                    attract and retain the best candidates.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Candidate Engagement</h5>
                                <p>Discover proven strategies for engaging with candidates effectively throughout the recruitment process.
                                    From crafting compelling job descriptions to conducting engaging interviews, our resources will help you
                                    create positive candidate experiences that drive better outcomes.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Technology and Innovation</h5>
                                <p>Keep up with the latest trends and innovations in recruitment technology. Explore the potential of AI,
                                    machine learning, and data analytics to revolutionize your recruitment efforts and gain a competitive
                                    edge in today&#39;s digital landscape.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Career Development</h5>
                                <p>Empower yourself with valuable resources to enhance your career development journey. Whether
                                    you&#39;re a seasoned professional looking to advance your career or a recent graduate exploring new
                                    opportunities, our articles and guides cover everything from resume writing to professional networking.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Industry Insights</h5>
                                <p>Gain valuable insights into the current state of the job market, industry trends, and emerging
                                    opportunities. Stay informed about the latest developments in your field and leverage our industry
                                    insights to make informed decisions about your career and recruitment strategies.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Best Practices and Tips</h5>
                                <p>Unlock the secrets to success with our collection of best practices and practical tips. From mastering the
                                    art of job search to acing your interviews, our expert advice will help you navigate every stage of the
                                    recruitment process with confidence.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Case Studies and Success Stories</h5>
                                <p>Learn from real-world examples of successful recruitment campaigns and career journeys. Our case
                                    studies and success stories showcase innovative strategies, inspiring success stories, and lessons learned
                                    from industry leaders and top performers.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>How-To Guides and Tutorials</h5>
                                <p>Explore our step-by-step guides and tutorials to learn how to make the most of NaukriDaddy&#39;s features
                                    and functionalities. Whether you&#39;re a recruiter or a job seeker, our easy-to-follow instructions will help
                                    you maximize your experience on our platform.</p>
                            </article>

                            <article className='pb-3'>
                                <h5>Stay Connected</h5>
                                <p>Subscribe to our blog to receive regular updates, industry insights, and exclusive content delivered
                                    straight to your inbox. Join our community of recruiters, job seekers, and industry professionals to stay
                                    informed, inspired, and empowered on your recruitment and career journey.</p>
                            </article>

                        </section>

                        <section className='p-3'>
                            <p>We&#39;re committed to providing you with the resources you need to succeed in your recruitment efforts
                                and career aspirations. Explore our blog and resources section today and take the next step towards
                                achieving your goals.</p>
                            <p>Happy reading!</p>
                            <p>Sincerely,</p>
                            <p>The NaukriDaddy Team</p>
                        </section>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default BlogsAndResources