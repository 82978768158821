import React, { useEffect, useRef, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import BrandLogo from "../../src/assets/img/Company Logo1.png"
import { Button, Container, Form, Modal } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import CompanyImage1 from '../../src/assets/img/nomad-team.png'
import CompanyImage2 from '../../src/assets/img/nomad-1.png'
import CompanyImage3 from '../../src/assets/img/nomad-2.png'
import SocialAssistant from '../../src/assets/img/Company Logo1.png'
import QuillInput from '../components/common/QuillInput'
import SimiliarJobs from '../components/SimiliarJobs'
import axios from 'axios'
import moment from 'moment'
import useScrollToTop from '../hooks/useScrollTop'

const JobDetails = () => {
	useScrollToTop();
	const searchParams = new URLSearchParams(window.location.search);
	const [similarJobQuery, setSimilarJobQuery] = useState({})

	const [resume, setResume] = useState<any>()
	const resumeInput = useRef<any>()

	const onResumeSelected = (event: any) => {
		setResume(event.target.files[0]);
	}
	const [show, setShow] = useState(false);
	const [newshow, setNewShow] = useState(false);

	const [job, setJob]: any = useState({
		location: [],
		typeOfEmployment: [],
		category: [],
		salaryRange: '',
		skill: []
	})

	const [similarJobs, setSimilarJobs]: any = useState([]);

	const [company, setCompany]: any = useState({})
	const navigate = useNavigate()
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const newHandleClose = () => setNewShow(false);
	const newHandleShow = () => setNewShow(true);

	const fetchCompanyInfo = async (cid: string) => {
		try {

			const { data } = await axios.get("/api/search/company-details/" + cid); 
			setCompany(data)
		} catch (error) {
			console.log(error);

		}
	}

	const fetchSimilarJobs = async () => {
		try {

			const { data } = await axios.post("/api/search/similar-jobs", similarJobQuery);
			setSimilarJobs(data);
		} catch (error) {
			console.log(error);

		}
	}

	const fetchJobInformation = async () => {
		try {

			const initialQueries = {
				title: searchParams.get("title")?.split(',')[0],
				location: searchParams.get("location")?.split(',') || [],
				category: searchParams.get("category")?.split(",") || [],
				skill: searchParams.get("skill")?.split(",") || [],
				salaryRange: searchParams.get("salaryRange")?.split(",") || [],
				typeOfEmployment: searchParams.get("typeOfEmployment")?.split(",") || []
			};
			setSimilarJobQuery(initialQueries)
			const { data } = await axios.get("/api/search/job-details/" + (searchParams.get("internal")));
			setJob(data)
			
			fetchCompanyInfo(data.companyId)
			fetchSimilarJobs()
		} catch (error) {
			console.log(error);

		}
	}

	useEffect(() => {
		// console.log();
		fetchJobInformation()

	}, [])


	return (
		<>
			<div className='job-detail-section'>
				<Header />
				<div className='first-container'>
					<Container>
						<div className='pages-links d-flex'><span className='para'><Link to="/">Home </Link>/ <Link to='/jobs'>Find Talent</Link> / </span> &nbsp;{job?.title}</div>
						<div className='job-title-box primary-border'>
							<div className='left-description'>
								<div className='brand-logo'>
									<img src={job.companyLogo} width={"80px"} alt='' />
								</div>
								<div>
									<h3>{job.title}</h3>
									<p className='para'>{job.company} . {job.location[0]} . {job.typeOfEmployment[0]}</p>
								</div>
							</div>
							<div className='apply-side'>
								{/* <div className='share-icon'><i className="fa-solid fa-share-nodes"></i></div> */}
								{/* <div className='divider-right'></div> */}
								<Button onClick={handleShow} className="primary-btn">
									<Link to=""> Apply</Link>
								</Button>
							</div>
						</div>
					</Container>
				</div>


				{/* main container start */}
				<Container>
					{/* second section start */}


					{/*  second container start */}
					<div className="second-section row">
						<div className="col-lg-8 col-sm-12 mt-3">
							<div className=""><h3>Description</h3></div>
							<div className="para p-right" dangerouslySetInnerHTML={{ __html: job.jobDescription }}></div>
							<div className=""><h3>Responsibilities</h3></div>
							<div className="pb-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									Community engagement to ensure that is supported and actively
									represented online
								</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									Focus on social media content development and publication
								</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">Marketing and strategy support</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									Stay on top of trends on social media plateforms, and suggest
									content ideas to the learn
								</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">Engage with online Community</span>
							</div>

							<div className=""><h3>Who you Are</h3></div>
							<div className="pb-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									You get energy from people and building the ideal work
									enviroment
								</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									You have a sense for beautiful spaces and office experiences
								</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									You are a confident office Manager, ready for added
									Responsibilities
								</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">You're detail-oriented and creative</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">
									You're a growth marketer and know how to run campaigns
								</span>
							</div>

							<div className=""><h3>Nice-To-Haves</h3></div>
							<div className="para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">Fluent in English</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">Project management Skills</span>
							</div>
							<div className="py-1 para">
								<span className="right-icon text-lightgreen">
									<i className="fa-regular fa-circle-check"></i>
								</span>
								<span className="px-2 para">Copy editing Skills</span>
							</div>
						</div>
						<div className="col-lg-4 col-sm-12 mt-3">
							<div className=""><h3>About this role</h3></div>
							<div className="w-100 bg-light-gray px-3 py-3">
								{" "}
								<div className='d-flex gap-1'>
									<h6 className='p-0 mt-0'>{job.applicants} Applied</h6>{" "}
									<span className="para">of {job.capacity} Capacity</span>
								</div>
								<div className="pt-2 d-flex">
									{" "}
									<div className="green-half"></div>
									<div className="gray-half"></div>
								</div>
							</div>
							{/* <div className="d-flex justify-content-between pt-4">
								<span className="text-gray para">Apply Before</span>
								<h6>July 31, 2023</h6>
							</div> */}
							<div className="d-flex justify-content-between pt-4">
								<span className="para">Job Posted On</span>
								<h6>{moment(job.datePosted).format('MMMM DD, YYYY')}</h6>
							</div>
							<div className="d-flex justify-content-between pt-4">
								<span className="para">Job Type</span>
								<h6>{job.typeOfEmployment[0]}</h6>
							</div>
							<div className="d-flex justify-content-between pt-4">
								<span className="para">Salary</span>
								<h6>{job.salaryRange.split('to')[0]} LPA - {job.salaryRange.split('to')[1]} LPA (INR)</h6>
							</div>

							<hr className="mt-5 mb-4" />

							<div className=""><h3>Categories</h3></div>
							<div className='d-flex gap-3'>
								{job.category.map((cat: string, index: number) => <div key={'cat_' + index} className="marketing-btn d-inline-block">
									{cat}
								</div>)}
								{/* <div className="marketing-btn">
									Marketing
								</div>
								<div className="design-btn">
									Design
								</div> */}

							</div>
							<hr className="mt-5 mb-4" />

							<div className="mb-2"><h3>Required Skills</h3></div>
							<div className='d-flex gap-3 flex-wrap'>
								{job.skill.map((skill: string, index: number) => {
									return <div key={'skill_' + index} className="req-skill-btn">
										{skill}
									</div>
								})}

								{/* <div className="req-skill-btn">
									Copywriting
								</div>
								<div className="req-skill-btn">
									Social Media Marketing
								</div>
								<div className="req-skill-btn">
									English
								</div>
								<div className="req-skill-btn">
									Copy Editing
								</div> */}
							</div>
						</div>
					</div>
					{/* benifits section start */}
					<div className="third-cont row mb-2">
						<div className=""><h3>Perks & Benefits</h3></div>
						<p className="para">
							This job comes with several perks and benefits
						</p>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3">
							<span className="d-block mb-1 fs-1 text-blue">
								<i className="fa-solid fa-stethoscope mx-1"></i>
							</span>
							<span className="small-heading py-2">Full Healthcare</span>
							<span className="lh-base para">
								{" "}
								We believe in thriving communities <br /> and that starts with our
								team being happy and healthy.
							</span>
						</div>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3"> <span className="d-block mb-2 fs-1 text-blue">
							<i className="fa-solid fa-water-ladder mx-1"></i>
						</span>
							<span className="small-heading py-2">Unlimited Vacation</span>
							<span className="text-gray lh-base para">
								{" "}
								We believe you should have a flexible schedule that makes space for family, wellness, and fun.
							</span></div>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3"> <span className="d-block mb-2 fs-1 text-blue">
							<i className="fa-solid fa-video mx-1"></i>
						</span>
							<span className="small-heading py-2">Skill Development</span>
							<span className="text-gray lh-base para">
								{" "}
								We believe in always learning and leveling up our skills. Whether it's a conference or online course.
							</span></div>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3"> <span className="d-block mb-2 fs-1 text-blue">
							<i className="fa-solid fa-users-line"></i>
						</span>
							<span className="small-heading py-2">Team Summits</span>
							<span className="text-gray lh-base para">
								{" "}
								Every 6 months we have a full team summit where we have fun, reflect,<br /> and plan for the upcoming quarter.
							</span></div>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3"> <span className="d-block mb-2 fs-1 text-blue">
							<i className="fa-solid fa-mug-hot"></i>
						</span>
							<span className="small-heading py-2">Remote Working</span>
							<span className="text-gray lh-base para">
								{" "}
								You know how you perform your best. Work from home, coffe shop <br />or anywhere when you feel like it.
							</span></div>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3"> <span className="d-block mb-2 fs-1 text-blue">
							<i className="fa-solid fa-tv"></i>
						</span>
							<span className="small-heading py-2"> Computer Benefits</span>
							<span className="text-gray lh-base para">
								{" "}
								We're grateful for all the time and energy each team member puts into getting to work every day.
							</span></div>
						<div className="col-sm-12 col-md-6 col-lg-3 pt-2 mb-3"> <span className="d-block mb-2 fs-1 text-blue">
							<i className="fa-solid fa-hand-holding-heart"></i>
						</span>
							<span className="small-heading py-2">We give Back</span>
							<span className="text-gray lh-base para">
								{" "}
								We anonymously match any donation our employees make (up to $600) so they can support the organizations they care about most times two.
							</span></div>
					</div>
					{/* benifits section end */}

					{/* fourth container start */}
					<div className="fourth-cont row d-flex align-items-center">
						<div className="col-lg-6 col-12 mt-4 mb-4">
							<div className="brand_name">
								<div className="mt-1">
									<img
										alt=""
										height={"70px"}
										src={job.companyLogo}
									></img>
								</div>
								<div className="px-2">
									<h2>{job.company}</h2>
									<div className="simple-btn d-flex align-items-end gap-2">
										<Button className="px-0 bg-transparent" onClick={() => navigate('/company-profile/' + company.id)}>
											<span> Read more about {job.company}</span>{" "}
											<i className="mx-2 fa-solid fa-arrow-right"></i>{" "}
										</Button>
									</div>
								</div>
							</div>
							<p className="para mt-3" dangerouslySetInnerHTML={{ __html: company.companyDescription }}>

							</p>
						</div>
						<div className="col-lg-3 col-8 m-left">
							<img
								alt=""
								// src="https://images.pexels.com/photos/3182827/pexels-photo-3182827.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
								src={CompanyImage1}
								width={"100%"}
								height={"100%"}
								className="rounded-1"
							/>
						</div>
						<div className="col-lg-2 col-4">
							<img
								alt=""
								src={CompanyImage2}
								width={"100%"}
								height={"47%"}
								className="rounded-1 mb-3"
							/>
							<img
								alt=""
								src={CompanyImage3}
								width={"100%"}
								height={"47%"}
								className="rounded-1"
							/>
						</div>
					</div>



				</Container>
				<SimiliarJobs jobs={similarJobs} />
				<Footer />

				<Modal
					show={show}
					onHide={handleClose}
					backdrop="static"
					keyboard={false}
					size="lg"
					className=''
				>
					<Modal.Header closeButton>

					</Modal.Header>
					<Modal.Title>
						<div className="brand_name">
							<div className="icon_assistant">
								<img
									alt=""
									height={"80px"}
									src={SocialAssistant}
								></img>
							</div>
							<div className="">
								<h4 className='p-0 m-0'>
									Social Media Assistant
								</h4>
								<span className="para">
									Dropbox
									<span className="fw-bold rounded-5 align-text-bottom">
										{" "}
										.{" "}
									</span>{" "}
									Delhi, India
									<span className="fw-bold rounded-5 align-text-bottom">
										{" "}
										.{" "}
									</span>{" "}
									Full-Time
								</span>
							</div>
						</div>
						<div id='horizontal-line'></div>
					</Modal.Title>
					<Modal.Body className="">
						<div> <h3 className="fw-bold">Submit your Application</h3></div>
						<p className='para'>The following is required and will only be shared with Nomad</p>
						<Form className="mt-4">
							<Form.Group className="mb-4" controlId="formBasicFullname">
								<Form.Label className="fw-bolder fs-6  p-0">
									Full name
								</Form.Label>
								<Form.Control
									className="rounded-3 py-3"
									type="Fullname"
									placeholder="Enter your fullname"
								/>
							</Form.Group>
							<Form.Group className="mb-4" controlId="formBasicEmail">
								<Form.Label className="fw-bolder fs-6 p-0">
									Email address
								</Form.Label>
								<Form.Control
									className="rounded-3 py-3"
									type="email"
									placeholder="Enter your email address"
								/>
							</Form.Group>

							<Form.Group className="mb-4" controlId="formBasicNumber">
								<Form.Label className="fw-bolder fs-6 p-0">
									Phone number
								</Form.Label>
								<Form.Control
									className="rounded-3 py-3"
									type="Number"
									placeholder="Enter your phone number"
								/>
							</Form.Group>
							<Form.Group className="pb-2" controlId="formBasicTittle">
								<Form.Label className="fw-bolder fs-6 p-0">
									Current or privious job title
								</Form.Label>
								<Form.Control
									className="rounded-3 py-3"
									type="Text"
									placeholder="What's your current or previous job title ?"
								/>
							</Form.Group>
							<div id='horizontal-line'></div>
							<div ><h4 className="fw-bolder my-4">LINKS</h4></div>
							<Form.Group className="mb-4" controlId="formBasicURL">
								<Form.Label className="fw-bolder fs-6 p-0">
									LinkedIn URL
								</Form.Label>
								<Form.Control
									className="rounded-3 py-3"
									type="Text"
									placeholder="Link to your LinkedIn URL"
								/>
							</Form.Group>
							<Form.Group className="pb-2" controlId="formBasicURL">
								<Form.Label className="fw-bolder fs-6 p-0">
									Portfolio URL
								</Form.Label>
								<Form.Control
									className="rounded-3 py-3"
									type="Text"
									placeholder="Link to your portfolio URL"
								/>
							</Form.Group>
							<div id='horizontal-line'></div>
							<Form.Group className="" controlId="exampleForm.ControlTextarea1">
								<Form.Label className="fw-bolder fs-6 p-0">
									Additional information
								</Form.Label>
								<QuillInput />

							</Form.Group>


							<div className="d-flex align-items-center justify-content-between my-3">

								<h5 className=" fw-bolder ">
									Attach your resume
								</h5>

								<Button
									style={{
										border: "3px dashed rgb(45, 150, 231)",
										padding: "16px",
									}}
									className="text-dark rounded-3 bg-transparent fs-5"
								>
									<input onChange={onResumeSelected} ref={resumeInput} type="file" hidden />
									<span onClick={() => resumeInput.current.click()} >
										<span style={{ color: "rgb(45, 150, 231)" }} className="px-2">
											<i className="fa-solid fa-paperclip"></i>
										</span>
										Attach Resume/CV
									</span>
								</Button>
							</div>
							<div id='horizontal-line'></div>
							<Button
								onClick={() => {
									newHandleShow();
									handleClose();
								}}

								className="w-100 primary-btn py-3"
							>
								Submit Application
							</Button>
						</Form>
						<p className="mt-3">
							By sending the request you can confirm that you accept our{" "}
							<Link to='/terms-use' target={'_blank'} style={{ color: "rgb(45, 150, 231)" }}>
								{" "}
								Terms of Service{" "}
							</Link>{" "}
							and{" "}
							<Link to='/privacy-policy' target={'_blank'} style={{ color: "rgb(45, 150, 231)" }}> Privacy Policy.</Link>
						</p>
					</Modal.Body>
				</Modal>

				{/*  application form section end*/}

				{/* sucessful apply form section start */}

				<Modal
					show={newshow}
					onHide={newHandleClose}
					backdrop="static"
					keyboard={false}

					size="lg"
					style={{ marginTop: "120px" }}
				>
					<Modal.Header closeButton></Modal.Header>
					<Modal.Title><h4>You’re not login</h4>
						<p className='para pt-3'>The following is required and will only be shared with Dropbox</p></Modal.Title>
					<Modal.Body className="mt-2">
						<Button className='primary-btn w-100 py-3'><Link style={{ textDecoration: "none", color: "#fff", fontWeight: "700" }} to='/login'>Job Apply with login</Link></Button>
						<Button className='normal-btn bg-transparent py-3 w-100 align-items-center rounded-5 mt-4 fw-bold' style={{ border: "1px solid #12DAFB", color: "#12DAFB" }}>Job Apply without login</Button>
						<p className="mt-3">
							By sending the request you can confirm that you accept our{" "}
							<Link to='/terms-use' target={'_blank'} style={{ color: "rgb(45, 150, 231)" }}>
								{" "}
								Terms of Service{" "}
							</Link>{" "}
							and{" "}
							<Link to='/privacy-policy' target={'_blank'} style={{ color: "rgb(45, 150, 231)" }}> Privacy Policy.</Link>
						</p>
					</Modal.Body>
				</Modal>
			</div>
		</>
	)
}

export default JobDetails