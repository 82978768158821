import axios from 'axios';

// Create axios instance
const axiosInstance = axios.create();

// Refresh token logic
const refreshToken = async () => {
    try {
        const response = await axiosInstance.post('/api/auth/refresh-token', {});
        localStorage.setItem('nd_sess', response.data.accessToken);
        return response.data.accessToken;
    } catch (error) {
        console.error('Failed to refresh token:', error);
        throw error;
    }
};

// Request interceptor
axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('nd_sess');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (error.response) {
            const { status, data } = error.response;
            if (status === 401 && data.command === 'refresh_token') {
                try {
                    const newToken = await refreshToken();
                    const originalRequest = error.config;
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;
                    return axiosInstance(originalRequest);
                } catch (refreshError) {
                    return Promise.reject(refreshError);
                }
            } else if (status === 401 && data.command === 'force_logout') {
                // Force logout logic
                // ...
            }
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;
