import React from "react";
import { Button, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const SimiliarJobs = ({ jobs=[] }: any) => {
	const navigate = useNavigate();

	const openJob = (_source: any) => {
		const searchParams = new URLSearchParams();
		const jobQuery: any = {
			title: [_source.title],
			category: _source.category,
			salaryRange: [_source.salaryRange],
			skill: _source.skill,
			typeOfEmployment: _source.typeOfEmployment,
			location: _source.location,
			internal: [_source.jobId]
		}
		Object.keys(jobQuery).forEach((key: string) => {
			if (jobQuery[key] && jobQuery[key].length) searchParams.set(key, jobQuery[key].join(","));
		})
		window.open("/job-details?" + searchParams.toString());
	};


	return (
		<>
			{jobs.length ? <div className="similiar-jobs container-fluid">
				<div className="container">
					<div className="row">
						<div className="d-flex justify-content-between mb-4">
							<h3>Similiar Jobs</h3>
							<div className="simple-btn d-flex align-items-end gap-2">
								<Button onClick={() => navigate('/jobs')} className="px-0 bg-transparent">
									<span>Show all jobs</span>{" "}
									<i className="mx-2 fa-solid fa-arrow-right"></i>{" "}
								</Button>
							</div>
						</div>
						<Row className="justify-content-between">
							{jobs.map((job: any, index: number) => {
								return <div key={'sim_j' + index} className="special-box gap-6" style={{ cursor: 'pointer' }} onClick={() => openJob(job)}>
									<div className="">
										<img
											alt=""
											height={"65px"}
											src={job.companyLogo}
										></img>
									</div>
									<div className="mx-3">
										<span className="d-block fw-bold small_heading">
											{job.title}
										</span>
										<span className="para">
											{job.company}{" "}
											<span className="rounded-5 align-text-bottom"> . </span> {job.location[0]}
										</span>
										<br />
										<div className="mt-2 d-flex gap-2">
											<div className="full-time-btn">{job.typeOfEmployment[0]}</div>{" "}
											<span className="border-right py-2 mx-1"></span>
											{/* {job.category.map((j: any, n: number) => <div key={'si' + index + '_' + n} className="marketing-btn">{j}</div>)} */}
											<div className="marketing-btn">{job.category[0]}</div>
											{/* <div className="design-btn">Design</div> */}
										</div>
									</div>
								</div>
							})}
							{/* <div className="special-box">
								<div className="px-4 mt-2">
									<img
										alt=""
										height={"50px"}
										src="https://assets-global.website-files.com/60d9fbbfcd9fcb40bad8aac3/64220948e1d680f7fcf791ef_webflow%20icon.png"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										HR Manager
									</span>
									<span className="para">
										Webflow{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div>

							<div className="special-box">
								<div className="">
									<img
										alt=""
										height={"65px"}
										src="https://www.logo.wine/a/logo/Dropbox_(service)/Dropbox_(service)-Icon-Logo.wine.svg"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										Brand Designer
									</span>
									<span className="para">
										Dropbox{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div>
							<div className="special-box">
								<div className="px-4 mt-2">
									<img
										alt=""
										height={"50px"}
										src="https://assets-global.website-files.com/60d9fbbfcd9fcb40bad8aac3/64220948e1d680f7fcf791ef_webflow%20icon.png"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										HR Manager
									</span>
									<span className="para">
										Webflow{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div>
							<div className="special-box">
								<div className="">
									<img
										alt=""
										height={"65px"}
										src="https://www.logo.wine/a/logo/Dropbox_(service)/Dropbox_(service)-Icon-Logo.wine.svg"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										Brand Designer
									</span>
									<span className="para">
										Dropbox{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div>
							<div className="special-box">
								<div className="px-4 mt-2">
									<img
										alt=""
										height={"50px"}
										src="https://assets-global.website-files.com/60d9fbbfcd9fcb40bad8aac3/64220948e1d680f7fcf791ef_webflow%20icon.png"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										HR Manager
									</span>
									<span className="para">
										Webflow{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div>


							<div className="special-box">
								<div className="">
									<img
										alt=""
										height={"65px"}
										src="https://www.logo.wine/a/logo/Dropbox_(service)/Dropbox_(service)-Icon-Logo.wine.svg"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										Brand Designer
									</span>
									<span className="para">
										Dropbox{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div>
							<div className="special-box">
								<div className="px-4 mt-2">
									<img
										alt=""
										height={"50px"}
										src="https://assets-global.website-files.com/60d9fbbfcd9fcb40bad8aac3/64220948e1d680f7fcf791ef_webflow%20icon.png"
									></img>
								</div>
								<div className="">
									<span className="d-block fw-bold small_heading">
										HR Manager
									</span>
									<span className="para">
										Webflow{" "}
										<span className="rounded-5 align-text-bottom"> . </span> New
										Delhi, India
									</span>
									<br />
									<div className="mt-2 d-flex gap-2">
										<div className="full-time-btn">Full-Time</div>{" "}
										<span className="border-right py-2 mx-1"></span>
										<div className="marketing-btn">Marketing</div>
										<div className="design-btn">Design</div>
									</div>
								</div>
							</div> */}
						</Row>
					</div>
				</div>
			</div> : ''}
		</>
	);
};

export default SimiliarJobs;
