import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import QuillInput from "../../common/QuillInput";
import { useForm } from "react-hook-form";
import { PerksAndBenefitsModel } from "../../../types/Job";
import axios from '../../../utils/axios'
import FaIcons from '../../../assets/json/icons.json';
import IconSelect from "../../common/IconSelector";


const PerksAndBenefits = ({ onNext }: any) => {
	const [benefits, setBenefits] = useState<Array<PerksAndBenefitsModel>>([]);
	// const [benefitsForJob, setbenefitsForJob] = useState<Array<PerksAndBenefitsModel>>([]);
	const [show, setShow] = useState(false);
	const { register, reset, handleSubmit, setValue, formState: { errors } } = useForm<PerksAndBenefitsModel>();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const perkAddRule = {
		Title: register('Title', { required: 'This fiield is required' }),
		Description: register('Description', { required: 'This field is required' }),
		Icon: register('Icon', { required: 'This field is required' })
	}


	const removeBenefits = async (id: string) => {
		const index: number = benefits.findIndex((b: any) => b._id == id);
		const newBenefits = [...benefits];
		newBenefits.splice(index, 1);
		setBenefits(newBenefits);
	}

	const onSubmit = async (newBenefit: PerksAndBenefitsModel) => {
		try {
			const { data } = await axios.post('/api/employer/benefits', { ...newBenefit });
			setBenefits([...benefits, { ...data }])
			reset({ Title: '', Description: '', Icon: '' })
			setShow(false);
		} catch (error) {
			console.log(error);
		}
	}


	useEffect(() => {
		const fetchPerksAndBenefits = async () => {
			try {
				const { data } = await axios.get('/api/employer/benefits');
				// setbenefitsForJob(data);
				setBenefits(data)
			} catch (error) {
				console.log(error);
			}
		}
		fetchPerksAndBenefits();
	}, [])

	return (
		<div className="step-section-three">
			<Row>
				<Col lg md="12">
					<h5 className="">Basic Information</h5>
					<p className="para">List out your top perks and benefits.</p>
				</Col>
			</Row>
			<div id="horizontal-line"></div>

			<Row className="gap-5">
				<Col lg="3" md="12">
					<h6 className="">Perks and Benefits</h6>
					<p className="para">
						Encourage more people to apply by sharing the attractive rewards and
						benefits you offer your employees
					</p>
				</Col>
				<Col className="m-auto" lg="7" md="12">
					<div className="normal-btn">
						<Button onClick={handleShow} className="mb-4 py-2 px-3">
							<i className="fa-solid fa-plus"></i> <span>Add Benifit</span>
						</Button>
					</div>
					<div className="d-flex flex-wrap gap-4">
						{benefits.map((b: any, i: number) => {
							return (<div key={i + '_key'} className="binifit-box primary-border">
								<div className="d-flex justify-content-between">
									<div className="health-icon">
										<i className={'fa-solid ' + b.Icon}></i>
									</div>
									<div onClick={() => removeBenefits(b._id)} className="close-btn">
										<i className="fa-solid fa-xmark"></i>
									</div>
								</div>
								<div className="health-heading">{b.Title}</div>
								<p className="health-para" dangerouslySetInnerHTML={{ __html: b.Description }}>
								</p>
							</div>)
						})}

						{/* <div className="binifit-box primary-border">
							<div className="d-flex justify-content-between">
								<div className="health-icon">
									<i className="fa-solid fa-water-ladder"></i>
								</div>
								<div className="close-btn">
									<i className="fa-solid fa-xmark"></i>
								</div>
							</div>
							<div className="health-heading">Unlimited Vacation</div>
							<p className="health-para">
								We believe you should have a flexible schedule that makes space
								for family, wellness, and fun.
							</p>
						</div>
						<div className="binifit-box primary-border">
							<div className="d-flex justify-content-between">
								<div className="health-icon">
									<i className="fa-solid fa-video"></i>
								</div>
								<div className="close-btn">
									<i className="fa-solid fa-xmark"></i>
								</div>
							</div>
							<div className="health-heading">Skill Development</div>
							<p className="health-para">
								We believe in always learning and leveling up our skills.
								Whether it's a conference or online course.
							</p>
						</div> */}
					</div>
				</Col>
			</Row>
			<div id="horizontal-line"></div>

			<div className="end-btn">
				<Button className="primary-btn" type="button" onClick={() => onNext(benefits.map(b => b._id))}  >
					Post Job
				</Button>
			</div>


			<Modal size="lg" show={show} onHide={handleClose} backdrop="static" keyboard={false} >
				<Modal.Header closeButton>
					<Modal.Title>
						<h4 className="">Add Benefit</h4>
						<p className="para">
							The following is required and will only be shared your benefits.
						</p>
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleSubmit(onSubmit)} className="d-flex flex-column gap-3">
						<Form.Group className="mb-3" controlId="formBasicEmail">
							<Form.Label className="p-0">
								<h6>Benefit Title *</h6>
							</Form.Label>
							<Form.Control type="text" {...perkAddRule.Title} placeholder="Enter benefit title" />
							{errors.Title && <span className="error">{errors.Title.message}</span>}
						</Form.Group>
						<Form.Group className="mb-3">
							<Form.Label className="p-0" htmlFor="benefitInfo">
								<h6>Benefit information </h6>
							</Form.Label>
							<textarea className="form-control" placeholder="Add the benefit description that you want to share.." id="benefitInfo" {...register('Description', { required: 'This field is required' })} cols={30} rows={6}></textarea>
							{errors.Description && <span className="error">{errors.Description.message}</span>}
						</Form.Group>


						<Form.Group className="mb-3">
							<Form.Label>Icon</Form.Label>
							<IconSelect icons={FaIcons} onIconChange={(v: any) => setValue('Icon', v)} />
							{errors.Icon && <span className="error">{errors.Icon.message}</span>}
						</Form.Group>

						<hr style={{ color: "#7c8493" }} />
						<Button className="w-100 primary-btn" variant="primary" type="submit" >
							Submit Benefit
						</Button>
						<p className="para mt-2">
							By sending the request you can confirm that you accept our{" "}
							<span style={{ color: "#1FA0FF" }}>Terms of Services </span> and{" "}
							<span style={{ color: "#1FA0FF" }}>Privacy Policy.</span>
						</p>
					</Form>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default PerksAndBenefits;
