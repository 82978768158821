import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import { Button, Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import React from "react";
import NoImage from '../../assets/img/no-image.png'

const EmployerTopbar = () => {

  const navigate = useNavigate();
  const logo = useSelector((state: RootState) => state.employer.Logo);
  const companyName = useSelector((state: RootState) => state.employer.CompanyDetails?.Name);

  const resolveLogo = (suffix: string) => {
    return 'https://naukri-daddy-dev.s3.ap-south-1.amazonaws.com/Logo/' + suffix;
  }

  const CustomToggle = React.forwardRef(({ children, onClick }: any, ref) => {
    return (<Button onClick={(e) => { e.preventDefault(); onClick(e) }} className="px-2 py-0 bg-transparent border-0 text-dark">
      {companyName} <i className="fa-solid fa-chevron-down" ></i>
      {children}
    </Button>
    )
  })

  const logOut = () => { };

  return (
    <>
      <Navbar bg="white" className="employer-topbar p-2" >
        <Container className="d-flex flex-wrap justify-content-between gap-4">
          <div className="left-brand align-items-center">
            <div className="company-icon mt-1">
              <img width={"50px"} src={logo ? resolveLogo(logo) : NoImage} alt="" />
            </div>
            <Dropdown>
              <Dropdown.Toggle as={CustomToggle} />
              <Dropdown.Menu>
                <Dropdown.Item onClick={logOut}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="right-postjob">
            <div className="">
              <Button className="notification"><i className="fa-regular fa-bell"></i></Button>
            </div>
            <div className="">
              <Button onClick={() => navigate('post-job')} className="postjob-btn"><i className="pb-1 fa-solid fa-plus"></i> <span className="">Post a Job</span></Button>
            </div>
          </div>
        </Container>
      </Navbar>
    </>
  );
};

export default EmployerTopbar;
