import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import useScrollToTop from "../../hooks/useScrollTop";

const TermsUse = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <div className="policies-section">
        <div className="first-row">
          <h1>TERMS OF USE</h1>
        </div>
        <Container>
          <div className="above-section">
            <p>
              WE DO NOT ASSURE OR WARRANT ANY INTERVIEW CALLS OR JOB OFFERS
              THROUGH OUR SERVICES. WE DO NOT GUARANTEE EMPLOYMENT OPPORTUNITIES
              FROM ANY EMPLOYER OR ORGANIZATION THAT DOWNLOADS RESUMES, INSERTS
              INFORMATION, OR USES DATA TO CONTACT UserS. UserS ARE CAUTIONED
              AGAINST RESPONDING TO CALLS OR EMAILS SOLICITING PAYMENT FROM
              WEBSITES CLAIMING TO OFFER SIMILAR SERVICES UNDER THE NAME OF OUR
              COMPANY.
            </p>
            <p>
              PLEASE READ THESE TERMS AND CONDITIONS OF USE CAREFULLY. BY
              ACCESSING THIS WEBSITE AND ANY PAGES THEREOF, YOU AGREE TO BE
              BOUND BY THE TERMS AND CONDITIONS OF USE BELOW AND/OR ANY SUCH
              TERMS AND CONDITIONS OF USE AS ARE COMMUNICATED ON THE PAGES
              THEREOF. IF YOU DO NOT AGREE TO THE TERMS AND CONDITIONS OF USE
              BELOW AND/OR ANY SUCH TERMS AND CONDITIONS OF USE AS ARE
              COMMUNICATED ON THE PAGES THEREOF, DO NOT ACCESS THIS WEBSITE, OR
              ANY PAGES THEREOF.
            </p>
            <p>
              These Terms & Conditions of Use are published in accordance with
              the Information Technology Act, 2000 and provisions of Rule 3 (1)
              of the Information Technology (Intermediaries guidelines) Rules,
              2011, regulations, guidelines, bye laws and notifications made
              thereafter that require publishing the rules and regulations,
              Digital Personal Data Protection Act, 2023, privacy policy and
              Terms & Conditions of Use for access or usage of this website. The
              domain name 
              <Link to="www.naukridaddy.com">www.naukridaddy.com </Link>
              (Website) is owned and operated by
              <span className="fw-bold">
                {" "}
                Naukridaddy HR Solutions Private Limited{" "}
              </span>
              (hereinafter referred to as{" "}
              <span className="fw-bold"> ‘Company’ </span>,{" "}
              <span className="fw-bold"> ‘Naukridaddy’ </span> or{" "}
              <span className="fw-bold"> ‘Service Provider’ </span>). By
              accessing this Website or any of its pages, you are agreeing to
              these Terms & Conditions of Use.
            </p>
            <p>
              Naukridaddy, along with its domain{" "}
              <Link to="www.naukridaddy.com">www.naukridaddy.com </Link>,
              including any subdomains thereof (collectively referred to as{" "}
              <span className="fw-bold">‘Site’</span>), is designed exclusively
              for the initial facilitation of communication and information
              exchange among its registered Users (hereinafter referred to as{" "}
              <span className="fw-bold">‘Member(s)’</span>) or unregistered
              Users (hereinafter referred to as{" "}
              <span className="fw-bold"> ‘Visitor(s)’</span>) who possess a
              sincere intent to engage in or initiate contact pertaining to
              authentic, currently available employment opportunities and other
              services aimed at advancing their careers (the{" "}
              <span className="fw-bold">‘Services’</span>). The Members and the
              Visitors shall be collectively referred to as{" "}
              <span className="fw-bold">‘User(s)’</span>.
            </p>
            <p>
              These terms of use pertain to the User's engagement with the Site
              and the User’s utilization of Naukridaddy’s Services. These terms
              of use are legally binding on the User.
            </p>
          </div>
          <div className="list-section">
            <ol>
              <li>ACCESSING THE SITE</li>
              <ol type="a">
                <li>
                  <p>
                    Naukridaddy.com, along with its associated products,
                    services, or subscribed-to items (whether or not remunerated
                    by the User), is expressly intended for the exclusive
                    utilization of its Members. Any unauthorized activities such
                    as copying, downloading, recreating, sharing passwords,
                    sublicensing, or engaging in any form of utilization
                    inconsistent with these terms shall constitute a misuse of
                    the Site, service, or product.
                  </p>
                </li>
                <li>
                  <p>
                    The Company retains the right to take appropriate actions,
                    including but not limited to safeguarding against loss of
                    revenue or reputation, seeking damages, terminating services
                    or access, and reporting such instances to relevant
                    authorities. In the event of any unauthorized copying,
                    misuse, transmission, or crawling of data, photographs,
                    graphics, or information from Naukridaddy.com for purposes
                    other than bona fide intentions, the Company reserves the
                    right to undertake suitable measures, including cessation of
                    access and pursuit of damages.
                  </p>
                </li>
                <li>
                  <p>General Representation and Warranties by the User—</p>
                </li>
                <ol type="i">
                  <li>
                    <p>The User shall have attained the age of 18 years.</p>
                  </li>
                  <li>
                    <p>
                      The User shall be capable of entering into a lawful
                      contract and to be bound by these terms of use.
                    </p>
                  </li>
                  <li>
                    <p>
                      In case the User accepts these terms of use on behalf of a
                      company, limited liability partnership or any other legal
                      entity, the User shall be authorised to bind such entity
                      to these terms of use and, in such cases, the term ‘User’
                      as used in these terms of use shall refer to such entity.
                    </p>
                  </li>
                  <li>
                    <p>
                      The User shall comply with all applicable laws and
                      regulations.
                    </p>
                  </li>
                </ol>
              </ol>

              <li>USER ACCOUNT</li>
              <ol type="a">
                <li>
                  <p>
                    For the purpose of completing the registration process the
                    User shall be required to provide information including but
                    not limited to the name of the User, email address, work and
                    education details, resume/CV and any other information as
                    prompted by the applicable registration form. Further, the
                    User shall indicate a User name for the account and an
                    associated password (hereinafter referred to as{" "}
                    <span className="fw-bold">‘User Account’</span>).
                  </p>
                </li>
                <li>
                  <p>
                    The Users shall also be required to confirm that they have
                    read, and abide by, these terms of use.
                  </p>
                </li>
                <li>
                  <p>
                    The User shall be responsible for maintaining the
                    confidentiality of the Username and password. Additionally,
                    the User shall be responsible for all activities associated
                    with the User Account.
                  </p>
                </li>
                <li>
                  <p>
                    In case where the information provided by the User is
                    untrue, inaccurate, not current or incomplete or the Company
                    has reasonable grounds to believe that such information is
                    untrue, inaccurate, not current or incomplete, or not in
                    accordance with the terms of use, the Company shall have the
                    right to indefinitely suspend or terminate or block access
                    of the User membership on the Site and refuse to provide the
                    User with access to the Site.
                  </p>
                </li>
                <li>
                  <p>
                    The User mobile phone number and/or e-mail address shall be
                    treated as the primary identifier of the User on the Site.
                    The User shall ensure that the updated registered mobile
                    phone number and email address is provided on the Site at
                    all times.
                  </p>
                </li>
                <li>
                  <p>
                    The User agrees that the Company shall not be liable for the
                    activities or consequences of use or misuse of any
                    information in the User Account in cases, including, where
                    the User has failed to update the mobile phone number and/or
                    e-mail address on the Site.
                  </p>
                </li>
              </ol>

              <li>
                GENERAL RESPONSIBILITIES OF THE User
                <ol type="a">
                  <li>
                    <p>
                      The Site shall be exclusively used in pursuance of the
                      abovementioned aim and the Services shall be available for
                      the exclusive use of the Users or such other users as may
                      be prescribed by the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                      Unauthorized reproduction, download, recreation, sharing
                      of passwords, sublicensing, or any form of distribution
                      not in compliance with these terms constitutes misuse of
                      the Site, service, or product. The Company retains the
                      right to take appropriate actions to safeguard against
                      loss of revenue, damage to reputation, or claims for
                      damages. Such actions may include suspending your service
                      or access, as well as reporting the matter to relevant
                      authorities. In the event you are found to be copying or
                      misusing or transmitting or crawling any data or
                      photographs or graphics or any information available on
                      the Site for any purpose other than that being a bonafide
                      purpose, we reserve the right to take such action that we
                      deem fit including stopping access and claiming damages.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Site is a publicly accessible Site, and the Company
                      bears no responsibility for the quality or authenticity of
                      responses. The Company is unable to monitor the responses
                      received in connection with information displayed on the
                      Site. Users are advised to perform their own due
                      diligence, including conducting background checks, to
                      verify the legitimacy of all received responses.
                    </p>
                  </li>
                  <li>
                    <p>
                      By utilizing the Site, you expressly authorize the Company
                      to utilize information pertaining to your actions on the
                      Site for the purpose of displaying advertisements, offers,
                      and other content, sponsored or otherwise, across our
                      services. This authorization is conferred without any
                      requirement for compensation to you. The data and
                      information concerning you are utilized to deliver
                      relevant suggestions and recommendations to both you and
                      other Users.
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company abstains from disclosing personally
                      identifiable information of any individual to external
                      companies or entities, except with explicit consent or
                      when such entities act as authorized agents of the
                      Company. The Company pledges to share such information,
                      within its possession, exclusively in response to legal
                      proceedings, including court orders or subpoenas. Users
                      are strictly forbidden from utilizing the services
                      provided by the Site or the Company in a manner that could
                      compromise the interests and functionality of the Site or
                      the Company. Users agree not to replicate, download,
                      publish, modify, or distribute material on the Site unless
                      specifically authorized by the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                      The User commits to employing the Site for their
                      individual purposes. Unauthorized utilization of content
                      from the Site for the creation of derivative works with
                      commercial intent, without prior written consent from the
                      Company, is expressly prohibited.
                    </p>
                  </li>
                  <li>
                    <p>
                      The User to the Site and/or its affiliated websites does
                      hereby specifically agree that he/she shall, at all times,
                      comply with the requirements of the Information Technology
                      Act, 2000 and Digital Personal Date Protection Act, 2023
                      as also rules, regulations, guidelines, bye laws and
                      notifications made thereunder while assessing or feeding
                      any resume or insertion or information or data into the
                      computers, computer systems or computer network of the
                      Site. The said User of the Site and/or its affiliated
                      websites does further unequivocally declare that in case
                      he violates any provisions of the said acts and/or rules,
                      regulations, guidelines, byelaws and notifications made
                      thereunder, he shall alone be responsible for all his
                      acts, deeds and things and that he alone shall be liable
                      for civil and criminal liability there under or under any
                      other law for the time being in force.
                    </p>
                  </li>
                </ol>
              </li>
              <li>
                LINKS TO THIRD-PARTY SITES, SERVICES AND PRODUCTS
                <ol type="a">
                  <li>
                    <p>
                      Within the Site, the User may encounter links to other
                      websites or applications that are independently owned and
                      managed by third parties. The User’s interaction with
                      these external sites is subject to the terms and
                      conditions, if any, stipulated by those specific websites
                      or applications. The Company does not exercise control
                      over any websites or applications outside of its own Site
                      and therefore disclaims responsibility for the content
                      found on any third-party websites or applications. It is
                      crucial to understand that the Company's inclusion of
                      third-party content or links to third-party websites and
                      applications should not be interpreted as an endorsement
                      or validation of such third-party websites or applications
                      by the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                      Users' correspondence, transactions/offers or related
                      activities with third parties, including Payment
                      Processors, are solely between the User and that third
                      party. Users' correspondence, transactions and usage of
                      the services/offers of such third party shall be subject
                      to the terms and conditions, policies and other service
                      terms adopted/implemented by such third party, and the
                      User shall be solely responsible for reviewing the same
                      prior to transacting or availing of the services/offers of
                      such third party. User agrees that the Company shall not
                      be responsible or liable for any loss or damage of any
                      sort incurred as a result of any such transactions/offers
                      with third parties. Any questions, complaints, or claims
                      related to any third-party product or service should be
                      directed to the appropriate vendor.
                    </p>{" "}
                  </li>
                  <li>
                    <p>
                      The Site amalgamates content that is both originated by
                      the Company and provided by third parties. Notably, the
                      Company does not furnish any warranties concerning the
                      precision, entirety, or quality of content contributed by
                      third parties. Therefore, Users are explicitly advised
                      against placing unwarranted reliance on third-party
                      content when availing themselves of the Company Services
                      made available on the Site.
                    </p>{" "}
                  </li>
                </ol>
              </li>
              <li>USE OF THE SITE </li>
              <ol type="a">
                <li>
                  <p>
                    The Company grants the User limited, personal,
                    non-exclusive, non-transferable and non-sub licensable right
                    to use the Site, subject to the terms of use and privacy
                    policy and the following specific representations and
                    warranties:
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p>
                      The User shall only access the Site using authorized and
                      lawful means;{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company shall not be liable for any failure or default
                      to provide access to the Site on account of any failure or
                      delay by the User to register with the Site for such
                      access;
                    </p>
                  </li>
                  <li>
                    <p>
                      Any configuration or set up of the Devices for access to
                      the Site shall be sole responsibility of the User.
                      <span className="fw-bold">‘Device’</span> means a device, usually electronic, that
                      processes data according to a set of instructions, which
                      may include workstations, personal computers, laptops,
                      netbooks, personal digital assistants, tablets, and
                      smartphones;
                    </p>
                  </li>
                  <li>
                    <p>
                      The Company shall collect, store, process and use the User
                      information in accordance with the Company's Privacy
                      Policy. The use of the Site and submission of Personal
                      Data (as defined in the Privacy Policy) shall be construed
                      as the User giving consent for the collection and use of
                      information disclosed by the User to the Company, in
                      accordance with the Privacy Policy.{" "}
                    </p>
                  </li>
                  <li>
                    <p>
                      The User shall not knowingly include or use any false or
                      inaccurate information in the Personal Information;
                    </p>
                  </li>
                  <li>
                    <p>
                      The User shall not take any action that interferes with,
                      degrades or adversely affects the Company and/or the Site;
                    </p>
                  </li>
                  <li>
                    <p>The User shall not use the Site in a manner that—</p>
                  </li>
                  <ul style={{ listStyle: "circle" }}>
                    <li>
                      <p>
                        is prohibited by any law or regulation, or facilitates
                        the violation of any law or regulation; or{" "}
                      </p>
                    </li>
                    <li>
                      <p>will disrupt a third parties' similar use; </p>
                    </li>
                    <li>
                      <p>violate or tamper with the security of the Site;</p>
                    </li>
                  </ul>
                  <li>
                    <p>
                      he User shall not use the Site, or any portion thereof, to
                      transmit, publish, post, upload, distribute or disseminate
                      any inappropriate, harassing, abusive, defamatory,
                      libellous, obscene, illegal or deceptive content;
                    </p>
                  </li>
                  <li>
                    <p>
                      he User shall ensure that the Site is not used to upload,
                      post, transmit, or otherwise make available any content
                      that contains a virus or any other form of malicious code
                      or data that is likely or intended to have an adverse
                      impact on, or provide unauthorized access to, the Site or
                      any other software, hardware, services or data;
                    </p>
                  </li>
                  <li>
                    <p>
                      The User shall not attempt to gain unauthorised access to
                      any accounts, computer systems or networks connected to
                      the Site, through hacking, or any other means, or obtain
                      or attempt to obtain any materials or information through
                      any means not intentionally made available to the User;
                    </p>
                  </li>
                  <li>
                    <p>
                      The User shall not copy, modify, adapt, translate, or
                      reverse engineer any portion of the Site, its content or
                      materials and/or the services. Further the User shall not
                      use the Site to develop, or to assist anyone in developing
                      a competitive Site, service or for other competitive
                      purposes;
                    </p>
                  </li>
                  <li>
                    <p>The User shall be solely responsible for—</p>
                  </li>
                  <ul style={{ listStyle: "circle" }}>
                    <li>
                      <p>
                        procuring and maintaining the network connections and
                        telecommunications links from the User systems to the
                        Company's data centres.
                      </p>
                    </li>
                    <li>
                      <p>
                        all problems, conditions, delays, delivery failures and
                        all other loss or damage arising from or relating to the
                        User network connections or telecommunications links or
                        caused by the internet;
                      </p>
                    </li>
                  </ul>
                  <li>
                    <p>
                      The User acknowledges that from time to time, the Company
                      may apply Upgrades (as defined hereinafter) to the Site,
                      and that such Upgrades may result in changes to the
                      appearance and/or functionality of the Site. The User may
                      be required to install certain Upgrades or updates to the
                      software in order to continue to access or use the Site or
                      portions thereof. ‘Upgrades’ shall mean and include new
                      versions of, and updates to, the Site whether for the
                      purpose of fixing an error, bug or other issue in the Site
                      or enhancing the functionality of Site.
                    </p>
                  </li>
                  <li>
                    <p>
                      Without explicit and written consent from the Company, you
                      agree not to reproduce, publish, distribute, or otherwise
                      use any data collected on this website for commercial or
                      non-commercial purposes. This includes, but is not limited
                      to, personal information, User-generated content, and any
                      other data accessible on the site. Any unauthorized
                      reproduction or publication of such data is strictly
                      prohibited and may result in legal action.
                    </p>
                  </li>
                  <li>
                    <p>
                      All communications transmitted through the Site, including
                      without limitation the Company's messaging Site shall be
                      monitored for quality assurance, training and other
                      purposes. By accepting these terms of use, the User
                      consents to such monitoring.
                    </p>
                  </li>
                </ol>
              </ol>

              <li>COMPANY’S RIGHTS</li>
              <ol type="a">
                <li>
                  <p>The Company may at its sole and absolute discretion:</p>
                </li>
                <ol type="i">
                  <li>
                    <p>
                      Exercise the authority to curtail, temporarily suspend, or
                      permanently terminate User's access to any or all segments
                      of the Company's Site or its Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Exercise discretion in either declining, relocating, or
                      deleting any content that a User may choose to submit.
                    </p>
                  </li>
                  <li>
                    <p>
                      Perform the action of relocating or deleting any content
                      accessible on the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                      Institute comprehensive principles and boundaries
                      governing the utilization of the Site and the Services.
                    </p>
                  </li>
                  <li>
                    <p>
                      Assign its rights and liabilities to all User accounts
                      hereunder to any entity. Further post the assignment,
                      intimation of such assignment shall be sent to all User’s
                      registered email id.{" "}
                    </p>
                  </li>
                </ol>

                <li>
                  <p>
                    In the event of any User's contravention of these terms of
                    use, or if the Company has reasonable grounds to believe
                    that a User has engaged in unlawful or improper utilization
                    of the Site or the Company Services, the Company, at its
                    sole and absolute discretion, and without any prior notice
                    to the User, reserves the right to:
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p>
                      Restrict, suspend, or terminate the User's access to all
                      or specific portions of the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                      Deactivate or obliterate the User's account and all
                      associated information contained therein.
                    </p>
                  </li>
                  <li>
                    <p>
                      Remove any content posted by the User on the Company Site.
                    </p>
                  </li>
                  <li>
                    <p>
                      Additionally, undertake technical and legal actions as it
                      deems necessary to address the situation.
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    Users hereby grant their consent to receive various forms of
                    communication, including announcements, administrative
                    notifications, and promotional materials from the Company or
                    any entities affiliated with the Company, including its
                    partners and licensors.
                  </p>
                </li>
              </ol>

              <li>PRIVACY POLICY </li>
              <ol type="a">
                <li>
                  <p>
                    All information collected from Users, including but not
                    limited to personal information, anonymised data and payment
                    information, if any, is subject to the Company’s Privacy
                    Policy which is available at [●].
                  </p>
                </li>
              </ol>

              <li>FEEDBACK </li>
              <ol type="a">
                <li>
                  <p>
                    As a participant, the User agrees to use careful, prudent,
                    and good judgment when leaving feedback for other Users of
                    the Site. In the event the feedback violates these terms of
                    use, is inappropriate or violates the propriety or privacy
                    of another User, the Company, in its sole discretion, may
                    delete User’s feedback or any User postings; and/or report
                    to law enforcement authorities any actions that may be
                    illegal, and any reports it receives of such conduct. As
                    mandated by the law or at the Company's sole discretion, the
                    Company shall cooperate with law enforcement agencies in any
                    investigation of alleged illegal activity on the Site.
                  </p>
                </li>
                <li>
                  <p>
                    Reporting inappropriate use of feedback: The User may
                    contact the Company regarding any inappropriate use of
                    feedback via-email at [●];
                  </p>
                </li>
                <li>
                  <p>
                    The Company does not and cannot review every posting made on
                    the Site. These terms of use do not require the Company to
                    monitor, police or remove any postings or other information
                    submitted by the User or any other User and the Company
                    shall not be responsible for any ensuing liability.
                  </p>
                </li>
              </ol>

              <li>INTELLECTUAL PROPERTY RIGHTS </li>
              <ol type="a">
                <li>
                  <p>
                    The User acknowledges that any and all intellectual property
                    rights (including but not limited to all trademark,
                    copyright, patent, service marks, etc.) and other
                    proprietary rights in relation to the Site including without
                    limitation any derivatives, improvements or modifications
                    which ownership is directly attributable to the Company
                    (expressly excluding any personal information, intellectual
                    property which belongs to a third party) and any
                    suggestions, ideas, enhancement requests, feedback,
                    recommendations or other information provided by the User or
                    any other party relating to the Site or the services shall
                    vest completely and fully with the Company within and beyond
                    the territory of India and the User shall have no right or
                    claim to such intellectual property in any manner
                    whatsoever.
                  </p>
                </li>
                <li>
                  <p>
                    The Site amalgamates content generated by the Company, its
                    partners, affiliates, licensors, associates, and Users. The
                    intellectual property rights, (<span className="fw-bold">‘Intellectual Property
                    Rights’</span>), governing all foundational software underpinning
                    the Site and the material presented within it, inclusive of,
                    though not limited to, software, advertisements, written
                    content, photographs, graphics, images, illustrations,
                    trademarks, logos, audio or video clips, and animations, are
                    the exclusive property of the Company, its partners,
                    licensors, and associates. Users are expressly prohibited
                    from modifying, publishing, transmitting, participating in
                    the transfer or sale of, reproducing, crafting derivative
                    works from, distributing, publicly performing, publicly
                    displaying, or in any manner exploiting any materials or
                    content featured on the Site, be it in its entirety or in
                    part, without obtaining prior written authorization from the
                    Company.
                  </p>
                </li>
                <li>
                  <p>
                    Users may request permission to use any of the Company
                    content by writing to [●].
                  </p>
                </li>
                <li>
                  <p>
                    Users shall bear exclusive responsibility for all content,
                    whether publicly disseminated or privately transmitted, that
                    they upload, post, send via email, transmit, or otherwise
                    share on the Site (‘Users' Content’). Each User affirms and
                    guarantees that they possess full ownership of all
                    Intellectual Property Rights associated with their User's
                    Content and assure that no aspect of their User's Content
                    infringes upon the rights of any third party. Furthermore,
                    Users pledge not to showcase or employ the names, logos,
                    marks, labels, trademarks, copyrights, or other intellectual
                    and proprietary rights belonging to third parties on the
                    Site. Users consent to indemnify and safeguard the Company,
                    along with its directors, personnel, affiliates, and
                    assignees, against any and all expenses, damages, losses,
                    and liabilities, including legal costs and attorney fees,
                    arising from any third-party claims, particularly those
                    related to the violation of Intellectual Property Rights due
                    to the display or utilization of names, logos, marks,
                    labels, trademarks, copyrights, or other intellectual and
                    proprietary rights on the Site, whether by the User directly
                    or through their actions or inactions.
                  </p>
                </li>
                <li>
                  <p>
                    Users hereby grant to the Company and its affiliates,
                    partners, licensors and associates a worldwide, irrevocable,
                    royalty-free, non-exclusive, sub-licensable license to use,
                    reproduce, create derivative works of, distribute, publicly
                    perform, publicly display, transfer, transmit, and/or
                    publish Users' Content for any of the following purposes:
                  </p>
                </li>
                <ol type="i">
                  <li>
                    <p>displaying Users' Content on the Site</p>
                  </li>
                  <li>
                    <p>
                      distributing Users' Content, either electronically or via
                      other media, to other Users seeking to download or
                      otherwise acquire it, and/or
                    </p>
                  </li>
                  <li>
                    <p>
                      storing Users' Content in a remote database accessible by
                      end Users, for a charge.
                    </p>
                  </li>
                  <li>
                    <p>
                      This license shall apply to the distribution and the
                      storage of Users' Content in any form, medium, or
                      technology.
                    </p>
                  </li>
                </ol>
                <li>
                  <p>
                    The names, logos, marks, labels, trademarks, copyrights, and
                    intellectual and proprietary rights displayed on the Site
                    and owned by any individual (including Users), organization,
                    or third party are unequivocally acknowledged as the
                    exclusive property of their respective owners. Any disputes,
                    controversies, or matters arising concerning these names,
                    logos, marks, labels, trademarks, copyrights, or
                    intellectual and proprietary rights should be promptly and
                    directly directed to the respective rights holders, with
                    notice to the Company.
                  </p>
                </li>
              </ol>

              <li>DISCLAIMER OF WARRANTIES AND LIABILITIES</li>
              <ol type="a">
                <li>
                  <p>The User understands and agrees that the Company provides the Site on an 'as-is' and 'as available' basis. The User agrees that use of the Site is at User’s risk. All warranties including without limitation, the implied warranties of merchantability, fitness for a particular purpose, for title and non-infringement are disclaimed and excluded.</p>
                </li>
                <li>
                  <p>No representations, warranties or guarantees whatsoever are made by the Company as to the </p>
                </li>
                <ol type="i">
                  <li><p>accuracy, adequacy, reliability, completeness, suitability or applicability of the information to a particular situation;</p></li>
                  <li><p>the quality of any Services, content, information, or other material on the Site will meet the User’s expectations or requirements;</p></li>
                  <li><p>any errors in the Site will be corrected; or</p></li>
                  <li><p>other warranties relating to performance, non-performance, or other acts or omissions of the Company, its officers, directors, employees, affiliates, agents, licensors, or suppliers.</p></li>
                </ol>
                <li>
                  <p>The Company does not make any representation or warranty regarding the reliability, accuracy, completeness, correctness, or usefulness of third-party content, and disclaims all liabilities arising from or related to third-party content.</p>
                </li>
                <li>
                  <p>The Company does not warrant that any of the software used and or licensed in connection with the Site will be compatible with other third-party software or devices nor does it warrant that operation of the Site and the associated software will not damage or disrupt other software or hardware.</p>
                </li>
                <li>
                  <p>Notwithstanding anything contained elsewhere in these terms of use, in no event shall the Company be liable to the User or anyone claiming under the User for any costs or loss incurred or suffered by the User or anyone claiming under the User, including but not limited to any special, exemplary, consequential, incidental, punitive or indirect damages on any theory of liability, whether in contract, tort (including without limitation negligence), strict liability or otherwise. In no event or circumstance shall The Company be under any liability to make good any loss whether by way of any monetary payment or otherwise.</p>
                </li>
                <li>
                  <p>The Company or anyone else involved in administering, distributing or providing the Site further explicitly disclaims any and all liability for any mistakes, omissions, interruptions, deletion of files or email, errors, defects, viruses computer viruses or other harmful, disabling computer code, computer instructions, circuitry or other technological means whose purpose is to disrupt, damage or interfere with any computer and communications facilities or equipment (‘Harmful Code’) that may be transferred to the User’s Devices when accessing the Site. By way of clarification, Harmful Code shall include, without limitation, any code containing viruses, Trojan horses, worms or like destructive code or code that was intentionally written to self-replicate. The User is advised to obtain and use appropriate anti-virus and security software and to take all other appropriate measures to safeguard the integrity of User’s Devices.</p>
                </li>
              </ol>

              <li>
              USE OF NAUKRIDADDY.COM </li>
                <ol type="1">
                  <li>
                    <p>
                    he User represents, warrants, and covenants that its use of NaukriDaddy.com shall not be done in a manner so as to:
                    </p>
                  </li>
                  <li>
                    <p>
                    ccess the Platform for purposes of extracting content to be used for training a machine learning or AI model without the express prior written permission.
                    </p>
                  </li>
                  <li>
                    <p>
                    nterfere with or disrupt computer networks connected to the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                    mpersonate any other person or entity, or make any misrepresentation as to your employment by or affiliation with any other person or entity.
                    </p>
                  </li>
                  <li>
                    <p>
                    orge headers or in any manner manipulate identifiers to disguise the origin of any User information.
                    </p>
                  </li>
                  <li>
                    <p>
                    nterfere with or disrupt the use of the Site by any other User, nor "stalk," threaten, or in any manner harass another User.
                    </p>
                  </li>
                  <li>
                    <p>
                    Use the Site in such a manner as to gain unauthorized entry or access to the computer systems of others.
                    </p>
                  </li>
                  <li>
                    <p>
                    eproduce, copy, modify, sell, store, distribute, or otherwise exploit for any commercial purposes the Site, or any component thereof, without express written consent from the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                    se content from the Site for derivative works with a commercial motive without prior written consent of the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                    Use any device, software, or routine to interfere or attempt to interfere with the proper working of the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                    Impose an unreasonable or disproportionately large load on the Site infrastructure.
                    </p>
                  </li>
                  <li>
                    <p>
                    Spam the Site or the Company by indiscriminately and repeatedly posting content or forwarding mail that may be considered spam.
                    </p>
                  </li>
                  <li>
                    <p>
                    Access data not intended for you or log into a server or account that you are not authorized to access.
                    </p>
                  </li>
                  <li>
                    <p>
                    Constitute an act of reverse engineering, decompiling, disassembling, deciphering, or otherwise attempting to derive the source code for the Site or any related technology or any part thereof.
                    </p>
                  </li>
                  <li>
                    <p>
                    Engage in "framing," "mirroring," or otherwise simulating the appearance or function of the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                    Attempt to probe, scan or test the vulnerability of a system or network.
                    </p>
                  </li>
                  <li>
                    <p>
                    Use automated means to crawl and/or scrape content from the Site and manually scrape content from the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                    Access the Site except through the interfaces expressly provided by the Company.
                    </p>
                  </li>
                  <li>
                    <p>
                    Attempt or breach security or authentication measures without proper authorization.
                    </p>
                  </li>
                  <li>
                    <p>
                    Provide deeplinks into the Site without prior permission of the Company. Extracting data from the Site using any automated process such as spiders, crawlers, etc., or through any manual process for a purpose which has not been authorized in writing.
                    </p>
                  </li>
                  <li>
                    <p>
                    Upload, post, email, transmit or otherwise make available either directly or indirectly, any unsolicited bulk e-mail or unsolicited commercial e-mail.
                    </p>
                  </li>
                  <li>
                    <p>
                    Subscribers shall under no circumstance sublicense, assign, or transfer the License, and any attempt at such sublicense, assignment, or transfer is void.
                    </p>
                  </li>
                  <li>
                    <p>
                    Constitute hosting, modifying, uploading, posting, transmitting, publishing, or distributing any material or information:
                    </p>
                  </li>
                  <li>
                    <p>
                    For which you do not have all necessary rights and licenses.
                    </p>
                  </li>
                  <li>
                    <p>
                    Which infringes, violates, breaches or otherwise contravenes the rights of any third party, including any copyright, trademark, patent, rights of privacy or publicity or any other proprietary right.
                    </p>
                  </li>
                  <li>
                    <p>
                    Which contains a computer virus or other code, files, or programs intending to disrupt or interfere with the functioning of the Site, or that of other computer systems.
                    </p>
                  </li>
                  <li>
                    <p>
                    That is grossly harmful, harassing, invasive of another's privacy, hateful, disparaging, relating to money laundering or unlawful, or which may potentially be perceived as being harmful, threatening, abusive, harassing, defamatory, libelous/blasphemous, vulgar, obscene, or racially, ethnically, or otherwise unlawful.
                    </p>
                  </li>
                  <li>
                    <p>
                    Which constitutes or encourages conduct that would constitute a criminal offense, give rise to other liability, or otherwise violate applicable law.
                    </p>
                  </li>
                  <li>
                    <p>
                    That deceives or misleads the addressee about the origin of such messages or communicates any information that is grossly offensive or menacing in nature.
                    </p>
                  </li>
                  <li>
                    <p>
                    That belongs to another person and to which the User does not have any right to.
                    </p>
                  </li>
                  <li>
                    <p>
                    That harms minors in any way.
                    </p>
                  </li>
                  <li>
                    <p>
                    That threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign states, or public order or causes incitement to the commission of any cognizable offense or prevents the investigation of any offense or is insulting any other nation.
                    </p>
                  </li>
                </ol>
             
              <li>
                INDEMNIFICATION
               
              </li>
              <ol type='a'>
                <li><p>By accepting these terms of use and using the Site, the User agrees that the User shall defend, indemnify and hold the Company, its directors, shareholders, officers and other representatives harmless from and against any and all claims, costs, damages, losses, liabilities and expenses (including attorneys' fees and costs) arising out of or in connection with: </p></li>
                <li><p>User’s access to the Site,</p></li>
                <li><p>User’s use of the services/features of the IoT devices and/or Site, or </p></li>
                <li><p>User’s violation or breach of these terms of use or any applicable law or regulation;</p></li>
                <li><p>User’s violation of any rights of any third party including intellectual property or other right of any person or entity; or </p></li>
                <li><p>any third-party claims based upon the content of any communications transmitted by the User.</p></li>
              </ol>
              <li>
                TERMINATION </li>
                <ol type="a">
                  <li>
                    <p>
                    These terms will continue to apply until terminated by either the Company or the User as set forth below.
                    </p>
                  </li>
                  <li>
                    <p>
                    Termination by the User: If the User elects to not to be bound by these terms of use, the User may terminate the relationship with the Company by unsubscribing to the Site.
                    </p>
                  </li>
                  <li>
                    <p>
                    Termination by the Company: The Company may at its discretion and at any time with or without notice, terminate or suspend the terms of use, with or without cause if:
                    </p>
                  </li>
                  <ol type="i">
                    <li><p>User is in breach of any of the provisions of the terms of use, the Privacy Policy or any other terms, conditions, or policies that may be applicable to the User; </p></li>
                    <li><p>The Company is mandated to do so in accordance with the law; or</p></li>
                    <li><p>The Company has elected to discontinue, with or without reason, access to the Site (or any part thereof) either in general or specifically to the User.</p></li>
                  </ol>
                  <li>
                    <p>
                   The Company shall not be liable to the User or any third party for any such termination.
                    </p>
                  </li>
                </ol>
                <li>CONSEQUENCES OF TERMINATION</li>
                <ol type="a">
                  <li><p>Once the User Account has been terminated, any and all content will be irretrievably deleted by the Company, except to the extent that the Company is obliged to maintain or permitted to retain in accordance with law.</p></li>
                  <li><p>The limited licences or assignment granted for use to the User in accordance with these terms of use shall stand terminated, effective immediately.</p></li>
                  <li><p>The Company, in its sole discretion, may initiate appropriate legal proceedings against the User, if necessary, in the event the termination is on account of User’s breach of these terms of use.</p></li>
                  <li><p>Termination shall not affect User’s liability or obligations arising prior to such termination and for the sake of avoidance of any doubt, it is hereby clarified that deletion, uninstallation of the Site and/or discontinuation of the Company’s Services, shall not discharge the User from the responsibility, obligation and liability to repay outstanding amount(s), if any, in accordance with these terms of use.</p></li>
                </ol>
                <li>GOVERNING LAW</li>
                 <ol type="a">
                  <li><p>These terms of use shall be governed and construed under the laws of India and the courts of New Delhi shall have exclusive jurisdiction.</p></li>
                 
                </ol>
                <li>DISPUTE RESOLUTION</li>
                 <ol type="a">
                  <li><p>The competent courts situated in New Delhi shall possess exclusive jurisdiction to adjudicate upon all disputes arising from or associated with the Services offered by The Company. This jurisdiction encompasses matters pertaining to the construction, validity, interpretation, and enforceability of these terms of use, as well as the rights and responsibilities of the User(s) and the Company. Furthermore, these courts shall have the exclusive authority to grant interim or preliminary remedies in the event of any dispute referred to arbitration, as detailed below. All such concerns and queries shall be subject to governance and interpretation in accordance with the laws of the Republic of India.</p></li>
                  <li><p>In the event of any legal dispute which may arise, the party raising the dispute shall provide a written notification (<span className="fw-bold">‘Notification’</span>) to the other party. On receipt of Notification, the parties shall first try to resolve the dispute through discussions. In the event that the parties are unable to resolve the dispute within 15 (fifteen) days of receipt of Notification, the dispute shall be settled by arbitration.</p></li>
                  <li><p>The place of arbitration shall be New Delhi, India. All arbitration proceedings shall be conducted in English and in accordance with the provisions of the Arbitration and Conciliation Act, 1996, as amended from time to time</p></li>
                  <li><p>The arbitration award will be final and binding on the Parties, and each Party will bear its own costs of arbitration and equally share the fees of the arbitrator unless the arbitral tribunal decides otherwise. The arbitrator shall be entitled to pass interim orders and awards, including the orders for specific performance and such orders would be enforceable in competent courts. The arbitrator shall give a reasoned award.</p></li>
                  <li><p>Nothing contained in these terms of use shall prevent the Company from seeking and obtaining interim or permanent equitable or injunctive relief,  specific performance or any other relief available to safeguard the Company’s interest prior to, during or following the filing of arbitration proceedings or pending the execution of a decision or award in connection with any arbitration proceedings from any court having jurisdiction to grant the same. The pursuit of equitable or injunctive relief shall not constitute a waiver on the part of the Company to pursue any remedy for monetary damages through the arbitration described herein.</p></li>
                </ol>
                <li>MISCELLANEOUS</li>
                 <ol type="a">
                  <li><p>17.1.To the extent that anything in or associated with the Site is in conflict or inconsistent with these terms of use, these terms of use shall take precedence and prevail. The Company’s failure to enforce any provision of these terms of use shall not be deemed a waiver of such provision nor of the right to enforce such provision. The Company’s rights under these terms of use shall survive any discontinuance of the access or use of the Site.</p></li>
                  <li><p>17.2.If any provision of these terms of use is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.</p></li>
                  <li><p>17.3.Any notices to the User from the Company regarding the Site or these terms of use shall be posted on the Site or sent by email or regular mail.</p></li>
                  <li><p>The Company reserves the right to assign any rights as it deems fit to any third party whatsover.</p></li>
                </ol>
                <li>GRIEVANCE REDRESSAL OFFICER</li>
                <p>The Company is committed to addressing User grievances promptly. For internal resolution, Users can contact customer support. If dissatisfied, escalate the matter to the Grievance Officer. External authorities, such as the Data Protection Board of India, may be approached if the resolution is not satisfactory. The Company pledges fair and timely redressal, and no retaliatory action will be taken against Users for raising grievances in good faith. The Company reserves the right to modify the grievance redressal mechanism, with any changes communicated on the Site. For assistance, contact [•] at [•].</p>
              
            </ol>
          </div>
          <div className="d-flex align-items-center justify-content-center fw-bold">
            <h4>Thank You for using the Site!</h4>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default TermsUse;
