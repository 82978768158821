import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import UserSearch from '../../assets/img/user-search.png'
 // Import the CSS for styling

interface Slide {
  title: string;
  description: string;
  image: string;
}

interface SliderProps {
  slides: Slide[];
}

const Slider: React.FC<SliderProps> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const navigate = useNavigate()
  const goToPrevious = () => {
    const isFirstSlide: boolean = currentIndex === 0;
    const newIndex: number = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide: boolean = currentIndex === slides.length - 1;
    const newIndex: number = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="find-content">
      <div className="find-opportunity">
        {currentIndex > 0 && (
          
          <button onClick={goToPrevious} className="left-arrow-btn p-0 m-0 border-none">
          <i className="fa-solid fa-angle-left"></i>
        </button>
        )}
        {/* Content for the left side (text and button) */}
       
        <div className="icon-user">
									<img src={UserSearch} alt="" />
								</div>
          <h4>{slides[currentIndex].description}</h4>
          <div className="d-flex my-4 gap-1">
									<div className={currentIndex === 0 ? 'active black-dot' : 'black-dot'}></div>{" "}
									<div className={currentIndex === 1 ? 'active black-dot' : 'black-dot'}></div>
									<div className={currentIndex === 2 ? 'active black-dot' : 'black-dot'}></div>
									<div className={currentIndex === 3 ? 'active black-dot' : 'black-dot'}></div>
								</div>
                <div className="line-with-circles"></div>
								
								<Button onClick={() => navigate('/signup')} className="join-btn">Join Now</Button>

       
        {currentIndex < slides.length - 1 && (
       
           <button onClick={goToNext} className="right-arrow-btn p-0 m-0 border-none">
           <i className="fa-solid fa-angle-right"></i>
         </button>
          
        )}
      </div>
      <div className="right-image">
        <img width={'100%'} height={'100%'} src={slides[currentIndex].image} alt={slides[currentIndex].title} />
       
      </div>
    </div>
  );
};

export default  Slider