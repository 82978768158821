import Header from "../components/Header";

const PageNotFound = () => {

    return (
        <>
            <Header />
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                background: 'rgb(238, 250, 247)'
            }}>
                <div style={{ borderRight: '3px solid #ddd', paddingRight: '35px' }}>
                    <i style={{
                        color: '#1FA0FF',
                        fontSize: '58px'
                    }} className="fa-regular fa-face-sad-tear"></i></div>
                <h3 style={{ fontFamily: 'EpilogueVariable', paddingLeft: '24px' }} className="mt-2">Page Not Found</h3>
            </div>
        </>
    )
}

export default PageNotFound;