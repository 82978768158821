import { Col, Row } from "react-bootstrap";
import OverviewSettings from "./settings/Overview";
import SocialLinks from "./settings/SocialLinks";
import TeamSettings from "./settings/Team";
import { useEffect, useState } from "react";
import Benifits from "./settings/Benifits";
import { useNavigate } from "react-router-dom";
import axios from "../../utils/axios";

const EmployerSettings = () => {

  const [activeTab, setActiveTab] = useState('overview');
  const [settingData, setSetting] = useState({});
  const navigate = useNavigate();

  const onSettingOverview = (data: any) => setSetting({ ...settingData, ...data });
  const onSettingSocial = (data: any) => setSetting({ ...settingData, ...data });
  const onSettingTeams = (data: any) => setSetting({ ...settingData, ...data });

  const postSetting = async (data: any) => {
    try {
      const { data } = await axios.post('/api/job/saveSetting', settingData);
      navigate('/employer/jobs')
    } catch (error) {
      console.log(error);
    }
  }

  const getEmployerSettings = async () => {
    try {
      const { data } = await axios.get('/api/employer/profile', settingData);
      console.log(data);

    } catch (error) {
      console.log(error);

    }
  }

  useEffect(() => {
    getEmployerSettings()
  }, [])

  return (
    <>
      <div className="setting-section">
        {/* setting section header start */}
        <div className="setting-header">
          <div className="heading">Settings</div>
          <Row className="setting-navbar">
            <Col
              lg="4"
              md="9"
              xsm="12"
              className="setting-nav d-flex justify-content-between flex-wrap"
            >
              <div onClick={() => setActiveTab('overview')} className={activeTab == 'overview' ? "nav-items active" : "nav-items"}>Overview</div>
              <div onClick={() => setActiveTab('social')} className={activeTab == 'social' ? "nav-items active" : "nav-items"}>Social Links</div>
              <div onClick={() => setActiveTab('team')} className={activeTab == 'team' ? "nav-items active" : "nav-items"}>Team</div>
              {/* <div onClick={() => setActiveTab('benifits')} className={activeTab == 'benifits' ? "nav-items active" : "nav-items"}>Benefits</div> */}
            </Col>
          </Row>
        </div>
        {/* setting section header end */}

        {/* setting-section-body start */}
        <section className="setting-body">
          {activeTab == 'overview' ? <OverviewSettings onNext={onSettingOverview} setActiveTab={setActiveTab} /> : ''}
          {activeTab == 'social' ? <SocialLinks onNext={onSettingSocial} setActiveTab={setActiveTab} /> : ''}
          {activeTab == 'team' ? <TeamSettings onNext={postSetting} setActiveTab={setActiveTab} /> : ''}
          {/* {activeTab == 'benifits' ? <Benifits onNext={postSetting} setActiveTab={setActiveTab} /> :''} */}
        </section>
        {/* setting-section-body end */}
      </div>
    </>
  );
};

export default EmployerSettings;
