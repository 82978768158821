import StaticLogo from '../assets/img/logo_small.png'
import ModelLogo from '../assets/img/model.png'
import ModelAvatar from '../assets/img/avatar.png'
import { Link } from 'react-router-dom'

export default function LoginBanner() {
    return (
        <>
            <div className='logo'>
              <Link to='/'>
              <img src={StaticLogo} alt="Logo" />
              </Link>
            </div>
            <div className='model-image'>
                <img className='reduced-image img-fluid mx-auto' src={ModelLogo} alt='Model' />
            </div>
            <div className='review-box'>
                <div className='circle-image'><img src={ModelAvatar} alt='' /></div>
                <div className='client'>Adam Sandler</div>
                <div className='about-client'>Lead Engineer at Wipro</div>
                <div className='description mt-3'>
                    <div className='quote'>“</div>
                    <div className='review'> <span>“Great platform for the job seeker that searching for new career heights.”</span> </div>
                </div>
            </div>
        </>
    )
}