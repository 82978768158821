import { Button, Col, } from 'react-bootstrap'
import { useState } from "react";
import LoginBanner from "../components/LoginBanner";
import JobseekerSignup from '../components/jobseeker/Signup';
import EmployerSignup from '../components/employer/Signup';
import SuccessSignup from '../components/SuccessSignup';
import { Link } from 'react-router-dom';

const Signup = () => {
    const [isJobseeker, setIsJobseeker] = useState(true);
    const [showSuccess, setShowSuccess] = useState(false);

    const triggerGoogleSignup = () => {
        window.location.href = "/api/auth/google";
    }

    const onSuccess = () => {
        setShowSuccess(true)
    }

    return (
        // <Container fluid>
        <div className='signup d-flex flex-wrap'>
            <Col className='left' lg={5} style={{ background: '#eefaf7', position: 'relative', overflow: 'hidden' }}>
                <LoginBanner />
            </Col>
            <Col md={12} sm={12} lg={7} className='form-section'>
                <div className="login_section">
                    <div className='d-flex justify-content-center'>
                        <Button onClick={() => setIsJobseeker(true)} className={'type-switch-btn px-3 mx-2 py-2' + (isJobseeker ? ' active' : '')}>Job Seeker</Button>
                        <Button onClick={() => setIsJobseeker(false)} className={'type-switch-btn px-3  border-0 py-2' + (!isJobseeker ? ' active' : '')}>Company</Button>
                    </div>
                    <h3 className='text-center py-3'>Get more opportunities</h3>
                    {isJobseeker ? <><Button onClick={triggerGoogleSignup} style={{ padding: "12px 0px", border: "1px solid #12DAFB", fontSize: "15px" }} className='w-100 bg-transparent text-dark fw-bolder rounded-5'> <span className='px-3 w-2'><img src='https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png' alt='' width={"18px"} /></span>Sign Up with Google</Button>
                        <div className="divider">
                            <span className="divider-line"></span>
                            <span className="divider-text">Or sign up with email</span>
                            <span className="divider-line"></span>
                        </div></> : ''}
                    {isJobseeker ? <JobseekerSignup /> : !showSuccess ? <EmployerSignup onSuccess={onSuccess} /> : <SuccessSignup />}
                    <div style={{ fontSize: "15px" }} className=' mt-3 px-3 text-dark'>By clicking 'Continue', you acknowledge that you have read and accept the <Link to='/terms-use' target={'_blank'}  style={{ color: "#1FA0FF" }}>Terms of Services </Link> and <Link to='/privacy-policy' target={'_blank'} style={{ color: "#1FA0FF" }}>Privacy Policy.</Link></div>
                </div>
            </Col>
        </div>
    )
}


export default Signup