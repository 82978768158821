import { Button, Col, Row } from "react-bootstrap";
import QuillInput from "../../common/QuillInput";
import { useForm } from "react-hook-form";
import { JobDescriptionModel } from "../../../types/Job";

const JobDescription = ({ setStep, onNext, data = {} }: any) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<JobDescriptionModel>(data);

    const onSubmit = (data: any) => {
        onNext && onNext({ JobDescription: { ...data } });
        setStep && setStep('perks');
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="step-section-two">
            <Row>
                <Col lg md="12">
                    <h5 className="">Details</h5>
                    <p className="para">
                        Add the description of the job, responsibilities, who you are, and nice-to-haves.
                    </p>
                </Col>
            </Row>
            <div id="horizontal-line"></div>

            <Row className="gap-5">
                <Col lg="3" md="12">
                    <h6 className="">Job Descriptions</h6>
                    <p className="para">Job titles must be describe one position</p>
                </Col>
                <Col lg="8" md="12">
                    <QuillInput onChange={(value: string) => setValue('Description', value)} placeholder="Enter job description" />
                </Col>
            </Row>
            <div id="horizontal-line"></div>


            <Row className="gap-5">
                <Col lg="3" md="12">
                    <h6 className="">Responsibilities </h6>
                    <p className="para">Outline the core responsibilities of the position</p>
                </Col>
                <Col lg="8" md="12">
                    <QuillInput onChange={(value: string) => setValue('Responsibilities', value)} placeholder="Enter core responsibilities" />
                </Col>
            </Row>
            <div id="horizontal-line"></div>


            <Row className="gap-5">
                <Col lg="3" md="12">
                    <h6 className="">Who You Are </h6>
                    <p className="para">Add your preferred candidates qualifications</p>
                </Col>
                <Col lg="8" md="12">
                    <QuillInput onChange={(value: string) => setValue('RequiredQualifications', value)} placeholder="Enter qualifications" />
                </Col>
            </Row>
            <div id="horizontal-line"></div>

            <Row className="gap-5">
                <Col lg="3" md="12">
                    <h6 className="">Nice-To-Haves </h6>
                    <p className="para">Add nice-to-have skills and qualifications for the role to encourage a more diverse set of candidates to apply</p>
                </Col>
                <Col lg="8" md="12">
                    <QuillInput onChange={(value: string) => setValue('NiceToHaves', value)} placeholder="Enter nice-to-have skills" />
                </Col>
            </Row>
            <div id="horizontal-line"></div>
            <div className="end-btn">
                <Button className="primary-btn" type="submit">Next Step</Button>
            </div>
        </form>
    )
}

export default JobDescription;