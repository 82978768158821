import React, { useState, useEffect, useRef } from 'react';

interface AutoCompleteProps {
    suggestionCallback: (keyword: string) => Promise<string[]>;
    onValue?: (values: string[]) => void;
    placeholder: string;
    defaultValues: string;
}

const Autocomplete = ({ suggestionCallback, onValue, placeholder, defaultValues }: AutoCompleteProps) => {
    const [activeSuggestion, setActiveSuggestion] = useState(0);
    const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState((defaultValues ? defaultValues + ',' : null) || '');
    const searchInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        const loadSuggestions = async () => {
            const lastTypedWord = input.split(',').pop()?.trim() || '';
            if (lastTypedWord) {
                const suggestions = await suggestionCallback(lastTypedWord);
                setFilteredSuggestions(suggestions);
                setShowSuggestions(true);
            } else {
                setShowSuggestions(false);
            }
        };

        const debounceId = setTimeout(loadSuggestions, 300); // Debounce the API call
        return () => clearTimeout(debounceId); // Cleanup on unmount or input change
    }, [input, suggestionCallback]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInput(newValue);

        const newSelectedValues = newValue.endsWith(',') ? newValue.slice(0, -1).split(',').map(val => val.trim()).filter(val => val !== '') : newValue.split(',').map(val => val.trim()).filter(val => val !== '');
        onValue && onValue(newSelectedValues);
    };

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            if (filteredSuggestions.length > 0) {
                selectSuggestion(filteredSuggestions[activeSuggestion]);
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault(); // Prevent the cursor from moving
            setActiveSuggestion(activeSuggestion === 0 ? filteredSuggestions.length - 1 : activeSuggestion - 1);
        } else if (e.key === 'ArrowDown') {
            e.preventDefault(); // Prevent the cursor from moving
            setActiveSuggestion(activeSuggestion === filteredSuggestions.length - 1 ? 0 : activeSuggestion + 1);
        }
    };

    const selectSuggestion = (suggestion: string) => {
        const newInputValue = input.replace(/[^,]*$/, suggestion + ', ');
        setInput(newInputValue);
        setFilteredSuggestions([]);
        setShowSuggestions(false);
        setActiveSuggestion(0);
        const newSelectedValues = newInputValue.split(',').map(val => val.trim()).filter(val => val !== '');
        onValue && onValue(newSelectedValues);
        searchInputRef.current?.focus();
    };

    const onClick = (suggestion: string) => {
        selectSuggestion(suggestion);
    };

    return (
       <>
            <input
                type="text"
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={placeholder}
                value={input}
                ref={searchInputRef}
                className="autocomplete-input"
            />
            {showSuggestions && input && filteredSuggestions.length>0 && (
                <ul className="suggestions-list">
                    {filteredSuggestions.map((suggestion, index) => (
                        <li
                            className={index === activeSuggestion ? "suggestion-active" : ""}
                            key={suggestion}
                            onClick={() => onClick(suggestion)}
                        >
                            {suggestion}
                        </li>
                    ))}
                </ul>
            )}
        </>
    );
};

export default Autocomplete;
