import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { checkSession } from "../../utils/common";

const RouteGuard = ({ children, setOn }: any) => {
    const navigate = useNavigate();
    const { hasSession, userType } = checkSession();

    useEffect(() => {
        if (!hasSession) {
            navigate('/');
        } else if (setOn === 'employer' && userType !== 'rc') {
            navigate('/jobseeker/profile');
        } else if (setOn === 'jobseeker' && userType !== 'js') {
            navigate('/employer/dashboard');
        }
    }, [navigate, hasSession, userType, setOn]);

    return children;
};

export default RouteGuard;
