import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Button, Container } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props: any) => {
    const { onClick, currentSlide } = props;
    return (
        <>
            {currentSlide < 8 ? <div className="right-click-btn" onClick={onClick}>
                <i className="fa-solid fa-arrow-right"></i>
            </div> : ''}
        </>
    );
}

const PreviousArrow = (props: any) => {
    const { onClick, currentSlide } = props;
    return (
        <>
            {currentSlide ? <div className="left-click-btn" onClick={onClick}>
                <i className="fa-solid fa-arrow-left"></i>
            </div> : ''}
        </>
    );
}

const CompaniesByIndustry = () => {
    const [industries, setIndustries] = useState<any>([]);
    const [activeIndustry, setActiveIndustry] = useState("");
    const [totalComp, setTotalComp] = useState(0);
    const [companiesByIndustry, setCompaniesByIndustry] = useState([]);

    const navigate = useNavigate();

    const fetchTopIndustries = async () => {
        try {
            const { data } = await axios.get("/api/search/company-industries");
            setActiveIndustry(data[0].key);
            getCompaniesByIndustry(data[0].key);
            setIndustries(data);
        } catch (error) {
            console.log(error);
        }
    };

    const getCompaniesByIndustry = async (industry: string) => {
        try {
            setActiveIndustry(industry);
            const { data } = await axios.get("/api/search/companies-by-industry?key=" + encodeURIComponent(industry));
            setTotalComp(data.totalHits);
            setCompaniesByIndustry(data.companies);
        } catch (error) { }
    };

    const viewMoreByIndustry = () => navigate("/find-companies?industry=" + encodeURIComponent(activeIndustry));

    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        nextArrow: <NextArrow />,
        prevArrow: <PreviousArrow />
    };

    useEffect(() => {
        fetchTopIndustries()
    }, []);


    return (
        <Container>
            <h3>Companies by Industry</h3>
            <Slider {...settings} className="category-slider">
                {industries?.map((industry: any, index: number) => {
                    return (
                        <div
                            onClick={() => getCompaniesByIndustry(industry.key)}
                            className={
                                activeIndustry == industry.key
                                    ? "slider-box primary-border active"
                                    : "slider-box primary-border"
                            } style={{minHeight:'200px'}}
                        >
                            <div className="slider-tech-icon">
                                <i className="fa-solid fa-pen-ruler"></i>
                            </div>
                            <h4>{industry.key}</h4>
                        </div>
                    );
                })}
            </Slider>

            <div className="results-section">
                <div className="">
                    <div className="result-icon">
                        <i className="fa-solid fa-pen-ruler"></i>
                    </div>
                    <h4>{totalComp} Results</h4>
                </div>

                <div className="category-boxes">
                    {companiesByIndustry.map((company: any) => {
                        return (
                            <div className="category-box primary-border">
                                <div className="company-image">
                                    <img
                                        src={company._source.companyLogo}
                                        alt=""
                                        width={"70px"}
                                    />
                                </div>
                                <h4>{company._source.company}</h4>
                                <div className="gray-bg-btn">
                                    {company._source.jobCount} Jobs
                                </div>
                            </div>
                        );
                    })}

                </div>
                <div className="simple-btn d-flex align-items-end gap-2">
                    <Button
                        onClick={viewMoreByIndustry}
                        className="px-0 bg-transparent"
                    >
                        <span>View more {activeIndustry} companies</span>{" "}
                        <i className="mx-2 fa-solid fa-arrow-right"></i>{" "}
                    </Button>
                </div>
            </div>
        </Container>
    )

}

export default CompaniesByIndustry;
