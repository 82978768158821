import { Button, FormGroup, FormLabel, Row } from 'react-bootstrap'
import axios from "../../utils/axios";
import { Link, useNavigate } from 'react-router-dom';
import { useState } from "react";
import { useForm } from 'react-hook-form';

interface EmployerSignupData {
    CompanyName: string;
    CompanyEmail: string;
    ContactNumber: string;
    Identification: string;
    Password: string;
}

const EmployerSignup = ({ onSuccess }: any) => {
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<EmployerSignupData>();
    const [errorMsg, setErrMessage] = useState('');

    const onSubmit = async (signupData: EmployerSignupData) => {
        try {
            setErrMessage('');
            const { data } = await axios.post('/api/employer/register', signupData);
            if (data.status === 'conflict') return setErrMessage(data.message);
            onSuccess && onSuccess()
        } catch (error) {
            console.error(error);
        }
    }

    const rules = {
        CompanyName: register('CompanyName', {
            required: "Company name is required",
            maxLength: { value: 50, message: 'Company name cannot have more than 50 characters' },
        }),
        CompanyEmail: register('CompanyEmail', {
            required: "Company email is required",
            pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address"
            }
        }),
        ContactNumber: register('ContactNumber', {
            required: "Company contact is required"
        }),
        Identification: register('Identification', {
            required: "GST/CIN is required"
        }),
        Password: register('Password', {
            required: "Password is required",
        })
    }

    return (

        <form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>Company Name</FormLabel>
                <input {...rules.CompanyName} className='form-control rounded-5' type="text" placeholder='Enter your company name' />
                {errors.CompanyName && <span className="error">{'' + errors.CompanyName.message}</span>}
            </FormGroup>


            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>Email Address</FormLabel>
                <input {...rules.CompanyEmail} className='form-control rounded-5' type="text" placeholder='Enter official email address' />
                {errors.CompanyEmail && <span className="error">{'' + errors.CompanyEmail.message}</span>}
            </FormGroup>


            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>Contact Number</FormLabel>
                <input {...rules.ContactNumber} className='form-control rounded-5' type="text" placeholder='Enter contact number' />
                {errors.ContactNumber && <span className="error">{'' + errors.ContactNumber.message}</span>}
            </FormGroup>

            <FormGroup className='mb-3'>
                <FormLabel className='fw-bolder text-dark'>GST/CIN Number</FormLabel>
                <input {...rules.Identification} className='form-control rounded-5' type="text" placeholder='Enter GST or CIN number' />
                {errors.Identification && <span className="error">{'' + errors.Identification.message}</span>}
            </FormGroup>


            <FormGroup className='mb-1'>
                <FormLabel className='fw-bolder text-dark'>Password</FormLabel>
                <input {...rules.Password} className='form-control rounded-5' type="password" placeholder='Enter password' />
                {errors.Password && <div className="error">{'' + errors.Password.message}</div>}
            </FormGroup>

            <Button className='login_button w-100 rounded-5 mt-2' type="submit">Continue</Button>
            {errorMsg ? <div className="error">{'' + errorMsg}</div> : ''}
            <p className='text-secondary fs-6 pt-3 px-3'>Already have an account?  <Link className='px-2 fw-bold text-decoration-none' style={{ color: "#1FA0FF", textDecoration: "none" }} to="/login">Login</Link></p>
        </form>
    )
}


export default EmployerSignup;