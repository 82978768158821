import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Nomad from "../../src/assets/img/Nomad.png";
import SearchComponent from "../components/Search";
import axios from "../utils/axios";
import useScrollToTop from "../hooks/useScrollTop";
import NoResults from "../components/NoResults";
import ReactPaginate from "react-paginate";

function FilterCompany() {
	useScrollToTop();
	const truncateString = (str: string, maxLength: number, appendStr = '...') => {
		if (str.length <= maxLength) {
			return str;
		}
		return str.substring(0, maxLength - appendStr.length) + appendStr;
	}

	const [collapsable, setCollapsable] = useState<any>({
		industry: true,
		companySize: true,
		companyType: true
	})

	const [query, setQuery] = useState<any>({
		term: [],
		location: [],
		industry: [],
		companyType: [],
		companySize: [],
		pageNumber: 1
	});

	const [companies, setCompanies] = useState<any>([]);

	const [filters, setFilters] = useState<any>({});
	const [total, setTotal] = useState(0);
	const [totalPages, setTotalPages] = useState(0);

	const navigate = useNavigate();


	const handleIndustryChange = (industry: string) => {
		const newIndustries = query.industry.includes(industry)
			? query.industry.filter((c: string) => c !== industry)
			: [...query.industry, industry];
		onFilterChange({ industry: newIndustries });
	};

	const handleCompanySizeChange = (companySize: string) => {
		const newValues = query.companySize.includes(companySize)
			? query.companySize.filter((c: string) => c !== companySize)
			: [...query.companySize, companySize];
		onFilterChange({ companySize: newValues });
	};

	const handleCompanyTypeChange = (companyType: string) => {
		const newValues = query.companyType.includes(companyType)
			? query.companyType.filter((c: string) => c !== companyType)
			: [...query.companyType, companyType];
		onFilterChange({ companyType: newValues });
	};

	const updateUrlWithQueries = (queries: any) => {
		const searchParams: any = new URLSearchParams();
		Object.keys(queries).forEach((key: string) => {
			if (queries[key] && queries[key].length) searchParams.set(key, queries[key].join(","));
		})
		navigate(`/find-companies?${searchParams.toString()}`);
		// onSearchTrigger(undefined, undefined, query)
	};

	useEffect(() => {
		onSearchTrigger(undefined, undefined, query);
	}, [query]);

	const onFilterChange = (newFilter: any) => {
		const updatedQuery = { ...query, ...newFilter };
		setQuery(updatedQuery);
		updateUrlWithQueries(updatedQuery);
	};

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const initialQueries = {
			term: searchParams.get("term")?.split(',') || [],
			location: searchParams.get("location")?.split(',') || [],
			industry: searchParams.get("industry")?.split(",") || [],
			companyType: searchParams.get("companyType")?.split(",") || [],
			companySize: searchParams.get("companySize")?.split(",") || [],
			pageNumber: 1
		};
		setQuery(initialQueries);
		onSearchTrigger(undefined, undefined, initialQueries);
	}, []);

	// Similar functions for employment types, skills, and salary ranges

	const onSearchTrigger = async (inp1?: string, inp2?: string, iq?: any) => {
		try {
			if (inp1) setQuery({ ...query, term: inp1.split(',') })
			if (inp2) setQuery({ ...query, location: inp2.split(',') })
			let options: any = { ...iq };

			let finalFilter: any = iq ? { ...options } : { ...query };
			// finalFilter.pageNumber = 1;
			const { data } = await axios.post("/api/search/companies", finalFilter);

			setFilters(data?.aggregations);
			setCompanies(data.companies);
			setTotal(data.totalHits);
			setTotalPages(data.totalPages);
		} catch (error) {
			console.log(error);
		}
	};

	const onPageChange = ({ selected }: any) => {
		setQuery({ ...query, pageNumber: selected + 1 });
		onSearchTrigger()
	}

	const handleCollapse = (key: any) => {
		setCollapsable({ ...collapsable, [key]: !collapsable[key] })
	}
	return (
		<>
			<div className="filter-company-section">
				<Header />
				{/* first row start */}
				<div className="first-container">
					<div className="find-talent-upper">
						<div>
							<h1>
								{" "}
								Find your{" "}
								<span className="">
									dream companies
								</span>
							</h1>
						</div>

						<div className="big-para">
							Find your next career at companies like HubSpot, Nike, and Dropbox
						</div>

						<SearchComponent onSearchTrigger={onSearchTrigger} isJobSearch={false} page={"company"} />
					</div>
					<p className="para">Popular : Meta, Microsoft, Google, Twitter</p>
				
				</div>
				{/* second_main container- start */}
				<div className="container">
					{/* second container- start */}
					{companies.length !== 0 && <div className="allJobs-container ">
						<div className="dropdown-section">
							<div className="d-flex justify-content-between">
								<h6>Company Type </h6>{" "}
								<Button onClick={() => { handleCollapse('companyType') }} className="mt-1">
									<i className={collapsable.companyType ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.companyType && (
								<form className="mb-3">
									{filters?.company_type_agg?.buckets.map(
										(ct: any, index: number) => {
											return (
												<div key={"ct_" + index}>
													<input
														checked={query.companyType.includes(
															ct.key
														)}
														onChange={() => handleCompanyTypeChange(ct.key)}
														type="checkbox"
														name="CompanyType"
														id={ct.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={ct.key + "_checkboxes"}
													>
														{truncateString(ct.key, 18)}
														{"(" + ct.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}
								</form>
							)}

							<div className="d-flex justify-content-between">
								<h6>Industry </h6>{" "}
								<Button onClick={() => { handleCollapse('industry') }} className="mt-1">
									<i className={collapsable.industry ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.industry && (
								<form className="mb-3">
									{filters?.industry_type_agg?.buckets.map(
										(ind: any, index: number) => {
											return (
												<div key={"cind_" + index}>
													<input
														checked={query.industry.includes(
															ind.key
														)}
														onChange={() => handleIndustryChange(ind.key)}
														type="checkbox"
														name="Industry"
														id={ind.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={ind.key + "_checkboxes"}
													>
														{truncateString(ind.key, 18)}
														{"(" + ind.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}
								</form>
							)}

							<div className="d-flex justify-content-between">
								<h6>Company Size </h6>{" "}
								<Button onClick={() => { handleCollapse('companySize') }} className="mt-1">
									<i className={collapsable.companySize ? "fa-solid fa-chevron-up" : "fa-solid fa-chevron-down"}></i>
								</Button>
							</div>
							{collapsable.companySize && (
								<form className="">
									{filters?.companySize_type_agg?.buckets.map(
										(cs: any, index: number) => {
											return (
												<div key={"cs_" + index}>
													<input
														checked={query.companySize.includes(
															cs.key
														)}
														onChange={() => handleCompanySizeChange(cs.key)}
														type="checkbox"
														name="CompanySize"
														id={cs.key + "_checkboxes"}
													/>
													<label
														className="para px-2"
														htmlFor={cs.key + "_checkboxes"}
													>
														{cs?.key.split("to")[0]}-
														{cs?.key.split("to")[1]}
														{"(" + cs?.doc_count + ")"}
													</label>
												</div>
											);
										}
									)}
								</form>
							)}

						</div>
						<div className="allJobs-section">
							<div className="first-row">
								<div className="all-jobs">
									<h3 className="">All Companies</h3>
									<p className="para">Showing  {companies.length} results of {total}</p>
								</div>
								<div className="sorted-by">
									<span className="para">Sort by : </span>
									<span>
										<select className="">
											<option>Most relevant</option>
										</select>
									</span>

								</div>
							</div>

							
							{companies.map((company: any, index: number) => {
								return (
								<div key={"companies_" + index} className="company-box primary-border">
									<div className="d-flex gap-4 align-items-center" >
										<div className="brand-icon">
											<img src={company._source.companyLogo} width={"70px"} alt=""></img>
										</div>
										<div>
											<h4>{company._source.company} <span className="rounded-5 align-text-bottom"> . </span>
												<span className="para">
													{company?._source?.location[0]}
												</span></h4>
											<p className="para">
												{
													company._source.companyDescription.slice(0, 80) + "..."
												}
											</p>
											{company?._source?.industry.map((category: any) => {
												return (
													<div className="design-btn">{category}</div>
												);
											})}
										</div>

									</div>
									<div className="jobs">
										<div className="gray-bg-btn"> {company._source.jobCount} Jobs</div>
									</div>
								</div>)
							})}

							{totalPages > 1 && <ReactPaginate
								breakLabel="..."
								nextLabel="Next"
								onPageChange={onPageChange}
								pageRangeDisplayed={5}
								pageCount={totalPages}
								previousLabel="Previous"
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
							/>}
						</div>
					</div>}

					{companies.length == 0 && <NoResults/>}
				</div>

				<Footer />
			</div>
		</>
	);
}

export default FilterCompany;
