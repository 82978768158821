import { Button, Col, Form, Modal, Row } from "react-bootstrap"
import CelestineProfile from "../../../assets/img/celestine-avatar.png";
import Reynaud from "../../../assets/img/reynout-avatar.png";
import Arienne from "../../../assets/img/Arienne-avatar.png";
import Bernard from "../../../assets/img/Bernard Alexander.png";
import Christine from "../../../assets/img/Christine Jhonson.png";
import Aaron from "../../../assets/img/Aaron-avatar.png";
import { useState,useRef } from "react";
import { SettingTeamModel } from "../../../types/setting";
import axios from "../../../utils/axios";
import { useForm } from "react-hook-form";


const TeamSettings = ({activeTabs,onNext,data={}}:any) => {
    const [show, setShow] = useState(false); 
     const { register, handleSubmit, setValue, formState: { errors } } = useForm<SettingTeamModel>(data);
     const [resume, setResume] = useState<any>()
     const resumeInput = useRef<any>()
     const [logo,setLogo] = useState<any>()
     const onResumeSelected = (event: any) => {
        const reader = new FileReader()
        setLogo(event.target.files[0])
        reader.onloadend = () => {
            setResume(reader.result);  
          };
      
          // Read the selected file as a data URL
          if (event.target.files[0]) {
            reader.readAsDataURL(event.target.files[0]); 
            
          }
     }
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const postTeamSetting = async (data: any) => {
       try {
        	// const { data : {url} } = await axios.post('/api/employer/getSignedUrl',{name:logo.name,contentType :logo.type});
            // let formData1 = new FormData()
            // formData1.append('file',logo)
            // let response  = await axios.put(url,formData1)
            // console.log(response);
         let response = await axios.post('/api/employer/saveTeamSetting',data)
       } catch (error) {
         console.log(error);
         
       }
    }

    return (
  
    <div className="socialLink-section">
        <Row className="d-flex justify-content-between">
            <Col lg="3" md="12" style={{ paddingRight: "30px" }}>
                <h5 className="">Basic Information</h5>
                <p className="para">Add team members of your company.</p>
            </Col>
            <Col lg="9" md="12">
                <div className="team-header">
                    <div className="total-members">50 Members</div>
                    <div className="filter-btn d-flex gap-4">
                        <div className="add-member-btn">
                            <Button onClick={handleShow}>
                                <i className="fa-solid fa-plus"></i>{" "}
                                <span>Add Members</span>
                            </Button>
                        </div>
                        <div className="roomy-btn">
                            <Button>
                                <i className="ri-function-fill"></i>
                            </Button>
                        </div>
                        <div className="grip-btn">
                            <Button>
                                <i className="fa-solid fa-grip-lines"></i>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="member-box-section d-flex gap-4 flex-wrap mt-4">
                    <div className="member-box">
                        <div className="member-profile">
                            <img src={CelestineProfile} alt="" />
                        </div>
                        <div className="member-name">
                            <h5>Célestin Gardinier</h5>
                        </div>
                        <div className="member-position para">CEO & Co-Founder</div>
                        <div className="member-social-link para">
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                    </div>

                    <div className="member-box">
                        <div className="member-profile">
                            <img src={Reynaud} alt="" />
                        </div>
                        <div className="member-name">
                            <h5> Reynaud Colbert</h5>
                        </div>
                        <div className="member-position para">Co-Founder</div>
                        <div className="member-social-link para">
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                    </div>

                    <div className="member-box">
                        <div className="member-profile">
                            <img src={Arienne} alt="" />
                        </div>
                        <div className="member-name">
                            <h5>Arienne Lyon </h5>
                        </div>
                        <div className="member-position para">
                            Managing Director
                        </div>
                        <div className="member-social-link para">
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                    </div>

                    <div className="member-box">
                        <div className="member-profile">
                            <img src={Bernard} alt="" />
                        </div>
                        <div className="member-name">
                            <h5>Bernard Alexander</h5>
                        </div>
                        <div className="member-position para">
                            Managing Director
                        </div>
                        <div className="member-social-link para">
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                    </div>

                    <div className="member-box">
                        <div className="member-profile">
                            <img src={Christine} alt="" />
                        </div>
                        <div className="member-name">
                            <h5>Christine Jhonson</h5>
                        </div>
                        <div className="member-position para">
                            Managing Director
                        </div>
                        <div className="member-social-link para">
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                    </div>

                    <div className="member-box">
                        <div className="member-profile">
                            <img src={Aaron} alt="" />
                        </div>
                        <div className="member-name">
                            <h5>Aaron Morgan</h5>
                        </div>
                        <div className="member-position para">
                            Managing Director
                        </div>
                        <div className="member-social-link para">
                            <i className="fa-brands fa-instagram"></i>
                            <i className="fa-brands fa-linkedin"></i>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        {/* <div id="horizontal-line"></div>
        <div className="end-btn">
            <Button className="primary-btn">Save Changes</Button>
        </div> */}


        <Modal
        size='xl'
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
          <h4 className="">New Team Member</h4>
            <p className="para">
            Add new team member in your company.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form onSubmit={handleSubmit(postTeamSetting)} className="step-section-one">
            <div className="d-flex flex-wrap  justify-content-between">
            <Form.Group style={{width:"49%"}} className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="p-0">
                <h6> Full name </h6>
              </Form.Label>
              <Form.Control type="text"
              id="FullName" {...register('FullName',{ required : 'Full Name is required !'})}  placeholder="Enter your fullname" />
            </Form.Group>
            <Form.Group style={{width:"49%"}} className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="p-0">
                <h6>Email address </h6>
              </Form.Label>
              <Form.Control type="email" {...register('Email',{ required : 'Email is required !'})}
              placeholder="Enter your email address" />
            </Form.Group>
            <Form.Group style={{width:"49%"}} className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="p-0">
                <h6>Phone number </h6>
              </Form.Label>
              <Form.Control type="number" {...register('PhoneNumber',{ required : 'Phone Number is required !'})}
               placeholder="Enter your phone number" />
            </Form.Group>
            <Form.Group style={{width:"49%"}} className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="p-0">
                <h6>Current Role </h6>
              </Form.Label>
              <Form.Control type="text"  {...register('CurrentRole',{ required : 'Current Role is required !'})} 
               placeholder="What’s your current job title?" />
            </Form.Group>

            </div>
            <div id="horizontal-line"></div>

            <h5 className="mb-4">LINKS</h5>

           
            <div className="d-flex justify-content-between">
            <Form.Group style={{width:"49%"}} className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="p-0">
                <h6>LinkedIn URL</h6>
              </Form.Label>
              <Form.Control type="text" {...register('Links.LinkedInUrl',{ required : 'Linked Url is required !'})}
               placeholder="Link to your LinkedIn URL" />
            </Form.Group>
            <Form.Group style={{width:"49%"}} className="mb-3 " controlId="formBasicEmail">
              <Form.Label className="p-0">
                <h6>Instagram URL
             </h6>
              </Form.Label>
              <Form.Control type="text" {...register('Links.InstagramUrl',{ required : 'Instagram Url is required !'})}
              placeholder="Link to your portfolio URL" />
            </Form.Group>
            </div>
            <div id="horizontal-line"></div>
            <div className="d-flex justify-content-between align-items-center">
                <div><h5>Attach Member Picture</h5></div>
                <div className="company-file">
                    <div className="file-logo">
                        <i className="fa-solid fa-image"></i>
                    </div>
                    <div style={{ color: "#515B6F" }} className="">
                        <span style={{ color: "#1FA0FF", fontWeight: "500" }}>
                        <input onChange={onResumeSelected} ref={resumeInput} type="file" hidden />
                         <span onClick={() => resumeInput.current.click()} > 
                          
                          Click to replace{" "}
                            </span>
                         
                        </span>
                        or drag and drop
                    </div>
                    <p className="para">SVG, PNG, JPG or GIF (max. 400 x 400px)</p>
                </div>
            </div>
            <div id="horizontal-line"></div>
            <Button
              className=" primary-btn"
              variant="primary"
              type="submit"
            >
          Add New Member
            </Button>
            <p className="para mt-3">
              By sending the request you can confirm that you accept our{" "}
              <span style={{ color: "#1FA0FF" }}>Terms of Services </span> and{" "}
              <span style={{ color: "#1FA0FF" }}>Privacy Policy.</span>
            </p>
         </form>
        </Modal.Body>
       
        
      </Modal>
    </div>)
}

export default TeamSettings;