import React from 'react'
import { Button } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import CheckIcon from "../assets/img/check-arrow image.png"
const SuccessMailSend = ({ goBack }: any) => {
  const navigate = useNavigate();
  return (
    <>
      <div className='suceess-section'>
        <div className='success-box'>
          <div className='check-arrow'><img src={CheckIcon} alt="" /></div>
          <h4 className=''>Link Sent to Your Inbox</h4>
          <div className='para'>We've sent you a link to your email address. Kindly use that link to reset your password.</div>
          <Button onClick={() => goBack && goBack('login')} type="button" className='mail-btn w-75 m-auto rounded-5 '>Back to Login</Button>
          {/* <div className='para'>Back to <a style={{ textDecoration: "none", color: "#377DFF", gap: "10px" }} href='javascript:void(0)'>Login</a></div> */}
        </div>
      </div>
    </>
  )
}

export default SuccessMailSend