import Footer from '../../components/Footer'
import Header from '../../components/Header'
import { Container } from 'react-bootstrap'
import useScrollToTop from '../../hooks/useScrollTop';

const GuidesAndTutorials = () => {
    useScrollToTop();
    return (
        <>
            <Header />
            <div className="policies-section">
                <div className="first-row">
                    <h1>HOW-TO GUIDES AND TUTORIALS</h1>
                </div>
                <Container>
                    <div className='list-section'>
                        <section className='mb-4 p-3'>
                            <p>
                                Whether you&#39;re new to NaukriDaddy or looking to explore advanced features, our comprehensive how-
                                to guides and tutorials are here to help you make the most of our platform. From recruiters seeking to
                                optimize their hiring process to job seekers aiming to land their dream job, our step-by-step instructions
                                will guide you through every aspect of using NaukriDaddy effectively. Explore the following guides to get
                                started:
                            </p>
                        </section>


                        <section className='mb-4 p-3'>
                            <h4 className='mb-3'>For Recruiters:</h4>
                            
                            <article>
                                <h5>1. Getting Started with NaukriDaddy:</h5>
                                <ul>
                                    <li>Learn how to create your recruiter account and set up your company profile.</li>
                                    <li>Understand the different subscription plans and features available to recruiters.</li>
                                    <li>Explore our onboarding resources to familiarize yourself with the platform.</li>
                                </ul>
                            </article>
                            <article >
                                <h5>2. Posting Jobs:</h5>
                                <ul>
                                    <li>Step-by-step instructions on how to post job listings on NaukriDaddy.</li>
                                    <li>Tips for optimizing your job descriptions to attract top talent.</li>
                                    <li>Understanding the importance of keywords and job tags for better visibility.</li>
                                </ul>
                            </article>
                            <article >
                                <h5>3. Managing Applications:</h5>
                                <ul>
                                    <li>Guide to managing applications received through NaukriDaddy.</li>
                                    <li>Utilizing our applicant tracking system (ATS) features to streamline your hiring workflow.</li>
                                    <li>Best practices for organizing and prioritizing candidate applications.</li>
                                </ul>
                            </article>
                            <article>
                                <h5>4. Utilizing AI Matching:</h5>
                                <ul>
                                    <li>Understanding how NaukriDaddy's AI matching technology works to match you with the most
                                        suitable candidates.</li>
                                    <li>Tips for refining your search criteria to improve matching accuracy.</li>
                                    <li>Leveraging AI insights to make data-driven hiring decisions.</li>
                                </ul>
                            </article>
                            <article>
                                <h5>5. Networking and Employer Branding:</h5>
                                <ul>
                                    <li>Strategies for building your employer brand on NaukriDaddy and networking with potential
                                        candidates.</li>
                                    <li>Showcase your company culture and values to attract top talent.</li>
                                    <li>Engaging with candidates through messaging and networking features.</li>
                                </ul>
                            </article>
                        </section>

                        <section className='mb-4 p-3'>
                            <h4 className='mb-3'>For Job Seekers:</h4>
                            <article>
                                <h5>1. Creating Your Job Seeker Profile</h5>
                                <ul>
                                    <li>Instructions on how to create a job seeker profile on NaukriDaddy.</li>
                                    <li>Tips for optimizing your profile to attract recruiters.</li>
                                    <li>Showcasing your skills, experience, and achievements effectively.</li>
                                </ul>
                            </article>
                            <article >
                                <h5>2. Job Search and Filtering</h5>
                                <ul>
                                    <li>How to search for job listings on NaukriDaddy and use advanced filtering options to find relevant
                                        opportunities.</li>
                                    <li>Setting up job alerts to receive notifications for new job openings.</li>
                                    <li>Exploring niche job categories and industries to discover hidden opportunities.</li>
                                </ul>
                            </article>
                            <article >
                                <h5>3. Applying for Jobs</h5>
                                <ul>
                                    <li>Step-by-step guide to applying for jobs through NaukriDaddy, including submitting your resume and
                                        cover letter.</li>
                                    <li>Understanding the application process and timeline.</li>
                                    <li>Tips for customizing your application for each job posting.</li>
                                </ul>
                            </article>
                            <article>
                                <h5>4. Career Development Resources</h5>
                                <ul>
                                    <li>Overview of the career development resources available on NaukriDaddy, such as resume building
                                        tools, interview preparation guides, and skill assessment tests.</li>
                                    <li>Accessing online courses and certifications to enhance your skills and qualifications.</li>
                                    <li>Networking with industry professionals and mentors for career advice and guidance.</li>
                                </ul>
                            </article>
                        </section>

                        <section className='p-3'>
                            <p>We&#39;re committed to providing you with the resources you need to succeed in your recruitment efforts
                                and career aspirations. Explore our how-to guides and tutorials today and take the next step towards
                                achieving your goals.</p>
                            <p>Happy learning!</p>

                            <p>Sincerely,</p>
                            <p>The NaukriDaddy Team</p>
                        </section>
                    </div>
                </Container>
            </div>
            <Footer />
        </>
    )
}

export default GuidesAndTutorials