import React, { useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from '../utils/axios'
import SuccessMailSend from "./SuccessMailSend";

const ResetPassword = ({ goBack }: any) => {
	const { register, handleSubmit, formState: { errors } } = useForm<{ Email: string }>()
	const [err, setErr] = useState('');
	const [isSent, setIsSent] = useState(false)

	const sendResetLink = async ({ Email }: any) => {
		try {
			setErr('')
			const { data } = await axios.post('/api/auth/reset-password-link', { Email });
			if (data.message == 'not_registered') return setErr('You are not registered with this email on NaukriDaddy!')
			if (data.message == 'not_verified') return setErr('You have not verified your email with us!');
			setIsSent(true)
		} catch (error) {
			console.log(error);
		}
	}
	return (
		<>
			{isSent ? <SuccessMailSend goBack={goBack} /> : <div className="suceess-section reset-section">
				<div className="success-box reset-box">
					<h4 className="">Reset Your Password</h4>
					<Form style={{ width: "100%" }} onSubmit={handleSubmit(sendResetLink)}>
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label className="p-0">
								<h6 className="p-0 m-0">Email Address</h6>
							</Form.Label>
							<Form.Control
								className="w-100 rounded-5"
								type="email"
								placeholder="Enter email address"
								{...register('Email', {
									required: "Email is required",
									pattern: {
										value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
										message: "Invalid email address"
									}
								})}
							/>
							{errors.Email && <span className="error">{'' + errors.Email.message}</span>}
							{err && <span className="error">{'' + err}</span>}
						</Form.Group>
						<Button type="submit" className="mail-btn w-75 rounded-5 mt-4 m-auto">
							Submit
						</Button>
					</Form>

					<div className="para">
						Back to{" "}
						<a href="javascript:void(0)" onClick={() => goBack && goBack('login')} style={{ textDecoration: "none", color: "#377DFF", gap: "10px" }}>Login</a>
					</div>
				</div>
			</div>}
		</>
	);
};

export default ResetPassword;
