import React from "react";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import useScrollToTop from "../../hooks/useScrollTop";

const FraudAlert = () => {
  useScrollToTop();
  return (
    <>
      <Header />
      <div className="policies-section">
        <div className="first-row">
          <h1>FRAUD ALERT</h1>
        </div>
        <Container>
          <div className="list-section">
            <ol>
              <li>Company Overview</li>
              <p>
                <span className="fw-bold">
                  Naukridaddy HR Solutions Private Limited
                </span>
                , a company incorporated under the provisions of the Companies
                Act, 2013 and having its registered office at A-88 Adhyapak
                Nagar Nangloi, Nangloi, West Delhi, New Delhi, Delhi-110041,
                India (hereinafter referred to as{" "}
                <span className="fw-bold">‘Naurkridaddy’</span> or{" "}
                <span className="fw-bold">‘Company’</span> which expression
                shall include its successors, nominees, agents and permitted
                assigns).
              </p>
              <p>
                Naukridaddy, along with its domain
                <Link to="https://naukridaddy.com/signup">
                  {" "}
                  https://naukridaddy.com/signup{" "}
                </Link>
                , including any subdomains thereof (collectively referred to as{" "}
                <span className="fw-bold">‘Site’</span>), is designed
                exclusively for the initial facilitation of communication and
                information exchange among its registered users (hereinafter
                referred to as
                <span className="fw-bold">‘Member(s)’</span>) or unregistered
                users (hereinafter referred to as
                <span className="fw-bold"> ‘Visitor(s)’</span>) who possess a
                sincere intent to engage in or initiate contact pertaining to
                authentic, currently available employment opportunities and
                other services aimed at advancing their careers (the{" "}
                <span className="fw-bold">‘Services’</span>). The Members and
                the Visitors shall be collectively referred to as
                <span className="fw-bold"> ‘User(s)’</span>.
              </p>
              <li>Background</li>
              <p>
                The Fraud Alert Policy (the{" "}
                <span className="fw-bold">‘Policy’</span>) is implemented to
                foster the formulation of controls aimed at detecting and
                preventing fraudulent activities against the Company. The
                Company is committed to promoting uniform organizational conduct
                by offering guidelines and delegating responsibility for both
                the creation of controls and the undertaking of investigations.
              </p>
              <li>Objective of the Policy</li>
              <p>
                This Fraud Alert Policy is established to protect{" "}
                <span className="fw-bold">Naukridaddy</span> and its Users from
                fraudulent activities. Our goal is to create a secure
                environment for our Users and to foster awareness about
                potential fraud risks.
              </p>
              <li>Reporting of Unauthorized or Suspicious Activities</li>
              <p>
                Users are encouraged to report any unauthorized or suspicious
                activities immediately. Reports can be made 24x7 via:
              </p>
              <p>
                {" "}
                Email: [
                <Link to="mailto:fraudreport@naukridaddy.com">
                  fraudreport@naukridaddy.com
                </Link>
                ]
              </p>
              <p>
                {" "}
                Dedicated Hotline: [
                <Link to="tel:1800-XXX-XXXX">1800-XXX-XXXX</Link>]
              </p>
              <p>
                {" "}
                Online Reporting Portal: [
                <Link to="www.naukridaddy.com/reportfraud">
                  www.naukridaddy.com/reportfraud
                </Link>
                ]
              </p>
              <li>Customer Rights and Obligations</li>
              <p>Users are responsible for maintaining the confidentiality of their account information. Prompt reporting of any unauthorized activity is crucial. Users should regularly update passwords and monitor their account for irregularities.</p>
              <li>Liability in Event of Fraud</li>
              <p>In cases of unauthorized transactions, User liability will be determined based on the timing of the report and the nature of the incident. Cases involving User negligence may result in different liability implications compared to third-party breaches.</p>
              <li>Investigation and Resolution Process</li>
              <p>Upon receiving a fraud report, Naukridaddy will:</p>
              <ol type="i">
                <li><p>Initiate an immediate investigation.</p></li>
                <li><p>Freeze affected accounts to prevent further unauthorized activity.</p></li>
                <li><p>Communicate with the affected User throughout the investigation process.</p></li>
              </ol>
              <li>Preventive Measures and Customer Awareness</li>
              <p>Naukridaddy is committed to educating Users about fraud prevention through:</p>
              <ol type="i">
                <li><p>Regular updates and tips on our social media channels.</p></li>
                <li><p>Alerts and notifications on our platform.</p></li>
                <li><p>Educational content on our website.</p></li>
              </ol>
              <li>Policy Review and Amendments</li>
              <p>This policy will be reviewed periodically to ensure compliance with regulatory changes and evolving fraud patterns. Amendments will be communicated to Users through our website and email notifications.</p>
              <li>Contact Information and Support</li>
              <p>For support and assistance regarding fraud, Users can contact us at:</p>
              <p>
                {" "}
                Email: [
                <Link to="mailto:support@naukridaddy.com">
                support@naukridaddy.com
                </Link>
                ]
              </p>
              <p>
                {" "}
                Dedicated Hotline: [
                <Link to="tel:1800-XXX-XXXX">1800-XXX-XXXX</Link>]
              </p>
            
              <li>Rights and Obligations of Naukridaddy</li>
              <p>Naukridaddy is committed to:</p>
              <ol type="i">
                <li><p>Ensuring the security of our platform.</p></li>
                <li><p>Resolving disputes fairly and promptly.</p></li>
                <li><p>Compensating Users where applicable, in accordance with our terms and conditions.</p></li>
              </ol>
              <li>Confidentiality</li>
              <p>All fraud reports and investigations will be handled with strict confidentiality.</p>
              <li>Retaliation Protection</li>
              <p>Naukridaddy prohibits retaliation against anyone who reports fraud in good faith.</p>
              <li>Compliance with Legal Standards</li>
              <p>This policy is designed to comply with relevant legal and regulatory standards.</p>
            </ol>
          </div>

          <div className="d-flex align-items-center justify-content-center fw-bold">
            <h4>Thank You for using the Site!</h4>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default FraudAlert;
