import React, { useState } from "react";
import { Button, Col, Form, FormGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from '../utils/axios';
import CheckIcon from "../assets/img/check-arrow image.png"
import LoginBanner from "../components/LoginBanner";
import { useNavigate, useParams } from "react-router";

const SetNewPassword = () => {
    const { register, handleSubmit, watch, formState: { errors } } = useForm<{
        Password: string;
        ConfirmPassword: string;
    }>();
    const [err, setErr] = useState('');
    const [isSent, setIsSent] = useState(false);

    // Watch the password field to compare it later with the confirm password field
    const password = watch("Password");

    const params = useParams()
    const navigate = useNavigate()

    const updatePassword = async (formData: { ConfirmPassword: string; Password: string; }) => {
        try {
            if (formData.Password !== formData.ConfirmPassword) {
                setErr("Passwords do not match");
                return;
            }

            setErr('');
            const { data } = await axios.post('/api/auth/set-password', { Password: formData.Password, Token: params.token });
            if (data.message == 'success') setIsSent(true);
            else setErr(data.message)
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='login d-flex'>
            <Col className='left' md={5} sm={5} style={{ background: '#eefaf7', position: 'relative', height: '100vh' }}>
                <LoginBanner />
            </Col>
            <Col sm={7} md={7} className='form-section'>
                <div className="suceess-section reset-section">
                    {!isSent && <div className="success-box reset-box">
                        <h4>Set New Password</h4>
                        <Form style={{ width: "100%" }} onSubmit={handleSubmit(updatePassword)}>
                            <FormGroup className="mb-3" controlId="formBasicPassword">
                                <Form.Label>
                                    <h6>New Password</h6>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter new password"
                                    {...register('Password', {
                                        required: "New password is required",
                                    })}
                                />
                                {errors.Password && <span className="error">{errors.Password.message}</span>}
                            </FormGroup>
                            <FormGroup controlId="formConfirmPassword">
                                <Form.Label>
                                    <h6>Confirm Password</h6>
                                </Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Re-enter your password"
                                    {...register('ConfirmPassword', {
                                        validate: value => value === password || "The passwords do not match"
                                    })}
                                />
                                {errors.ConfirmPassword && <span className="error">{errors.ConfirmPassword.message}</span>}
                            </FormGroup>
                            {err && <span className="error">{err}</span>}
                            <Button type="submit" className="mail-btn w-75 rounded-5 mt-4 m-auto">
                                Submit
                            </Button>
                        </Form>
                    </div>}
                    {isSent && <div className="success-box reset-box">
                        <div className='check-arrow'><img src={CheckIcon} alt="" /></div>
                        <h4 className=''>Password Changed</h4>
                        <div className='para'>Your password has been reset successfully.</div>
                        <Button onClick={() => navigate('/login')} type="button" className='mail-btn w-75 m-auto rounded-5 '>Back to Login</Button></div>}
                </div>
            </Col>
        </div>
    );
};

export default SetNewPassword;
