import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { Button, Container } from 'react-bootstrap'
import { Bar, Doughnut } from 'react-chartjs-2'
import 'chart.js/auto'
import Bulb from '../assets/img/Bulb.png'
import Rocket from '../assets/img/Rocket.png'
import HorizontalBar from '../assets/img/HorizontalBar.png'

const SelfAnalysis = () => {
	const getBackgroundColor = (value: any) => {
		if (value > 10) return '#16A799'; // Green for 'Strong'
		if (value > 5) return '#FFB836'; // Yellow for 'Moderate'
		return '#FF6550'; // Red for 'Weak'
	};

	const skillData = {
		'Logic': 12,
		'Coding': 7,
		'Soft Skill': 3,
		'Problem Solving': 11,
	};

	const data1 = {
		labels: Object.keys(skillData),
		datasets: [{
			label: 'Skill Level',
			data: Object.values(skillData),
			backgroundColor: Object.values(skillData).map(getBackgroundColor), // Map skill values to colors
			borderColor: Object.values(skillData).map(value => getBackgroundColor(value).replace(/0\.5\)/, '1)')), // More opaque borders
			borderWidth: 1,
		}],
	};

	const options: any = {
		responsive: true,
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: false, // Set to false since you only have one dataset
			},
			title: {
				display: true,
				text: 'Feature Assessment'
			}
		},
		scales: {
			x: {
				grid: {
					display: false,
				},
			},
			y: {
				display: false,
				grid: {
					display: false,
				},
				beginAtZero: true,
			},
		},
	};

	return (
		<>
			<Header />
			<Container className='self-analysis-container'>
				<div className='first-section-wish'>
					<p className='para'><b>NaukriDaddy Self Assessment</b> is a comprehensive framework that leverages GenAI technology to provide detailed feedback on interview performance. Through advanced natural language processing and camera analysis, candidates receive insights into their strengths, weaknesses, and root causes of potential rejection. The framework also assists interviewers in formulating relevant questions. Based on the candidate's overall score, the platform suggests suitable job opportunities and personalized skill improvement courses, empowering candidates to enhance their skills and succeed in future interviews.</p>
					{/* <div> */}
					{/* <h4>Good Morning, Manny</h4> */}
					{/* <p className='para'>Here is job applications status from July 19 - July 25.</p> */}
					{/* </div> */}
					{/* <div className='calender'>July 19 -  July 25  <i className="fa-solid fa-calendar"></i></div> */}
				</div>


				<div className="d-flex justify-content-between mb-5 flex-wrap">
					<div className="job-statics-box primary-border">

						
						<div className="d-flex justify-content-between p-3">
							<div>
								<h5>Interview Analysis</h5>
								<p className="para">Showing Jobstatistic Sep 01-08</p>
							</div>
							<div className="year-btn">
								<Button className="active">Week</Button>
								<Button>Month</Button>

							</div>
						</div>
						<div className="job-statistic-navbar my-2">
							<div className="nav-job d-flex justify-content-between">
								<div className="nav-item active">Overall Interview Analysis</div>
							</div>
						</div>

						<div className="dount-chat-section">
							<div className='dount-chart' style={{ width: '65%' }}>
								<Bar data={data1} options={options}></Bar>
							</div>
							<div className='skill-data primary-border rounded-0'>
								<div className='d-flex justify-content-between align-items-center'>
									<h6>Logic</h6>
									<div className='arrow-icon'><i className="fa-solid fa-arrow-right"></i></div>
								</div>

								<div className='d-flex justify-content-between align-items-center'>
									<h6>Soft Skill</h6>
									<div className='arrow-icon'><i className="fa-solid fa-arrow-right"></i></div>
								</div>
								<div className='d-flex justify-content-between align-items-center'>
									<h6>Coding</h6>
									<div className='arrow-icon'><i className="fa-solid fa-arrow-right"></i></div>
								</div>
								<div className='d-flex justify-content-between align-items-center'>
									<h6>Problem Solving</h6>
									<div className='arrow-icon'><i className="fa-solid fa-arrow-right"></i></div>
								</div>
								<div className='d-flex justify-content-between align-items-center'>
									<h6>Behaviour Analysis </h6>
									<div className='arrow-icon'><i className="fa-solid fa-arrow-right"></i></div>
								</div>
								<div className='d-flex justify-content-between align-items-center'>
									<h6>Case Adaptation</h6>
									<div className='arrow-icon'><i className="fa-solid fa-arrow-right"></i></div>
								</div>
							</div>
						</div>
					</div>
					<div className="open-job-applicant-summary">
						<div className="open-job-box primary-border">
							<h5>Job Open</h5>
							<div className="d-flex align-items-end">
								<div className="big-number">12</div>
								<p className="para fs-5 px-2">Jobs Opened</p>
							</div>
						</div>
						<div className="applicant-summary-box primary-border">
							<h5>Applicant sumary</h5>
							<div className="d-flex align-items-end ">
								<div className="big-number">67</div>
								<p className="para fs-5 px-2">Applicants </p>
							</div>
							<div className="mb-3">
								<img width={300} height={20} src={HorizontalBar} alt="HorizontalBar" />
							</div>
							<div className='d-flex gap-3 flex-wrap'>
									<div className='review-part-box'>
										<div className='box-review'></div>
										<div className='big-para'>In-review</div>
									</div>
									<div className='review-part-box'>
										<div className='box-hired'></div>
										<div className='big-para'>Hired</div>
									</div>
									<div className='review-part-box'>
										<div className='box-shortlisted'></div>
										<div className='big-para'>Shortlisted</div>
									</div>
									<div className='review-part-box'>
										<div className='box-rejected'></div>
										<div className='big-para'>Rejected</div>
									</div>
									<div className='review-part-box'>
										<div className='box-interview'></div>
										<div className='big-para'>Interview</div>
									</div>
									
								</div>
						</div>
					</div>
				</div>

				<div className='big-buttons my-5'>
					<Button style={{ width: "48%", padding: "38px" }} className="review-button justify-content-between">

						<h4>Interviewed (18)</h4>
						<i className="fa-solid fa-angle-right"></i>
					</Button>
					<Button style={{ width: "48%", padding: "38px" }} className="schedule-button justify-content-between">

						<h4>Interview details</h4>
						<i className="fa-solid fa-angle-right"></i>
					</Button>
				</div>

				<div className='bottom-chats-section'>
					<div className='skill-analysis-part primary-border'>
						{/* <h4>Skill Analysis Chart</h4> */}
						<div className='dount-chart'>
							<Doughnut data={{
								labels: ['Tech', 'Hard Skill', 'Soft Skill', 'Speak'],
								datasets: [
									{
										label: 'Dataset 1',
										data: [78, 65, 101, 115],
										backgroundColor: ['#7B61FF', '#26A4FF', '#16A799', '#FFB836'],
									}
								]
							}} options={{
								plugins: {
									legend: {
										position: 'bottom',
									},
									title: {
										display: true,
										text: 'Skill Analysis'
									}
								}
							}}></Doughnut>
						</div>
					</div>
					<div className='feature-assessment-part primary-border'>
						{/* <h4>Feature Assessment-part</h4> */}
						<div className='d-flex justify-content-between'>
							<div className='dount-chart' style={{ width: '60%' }}>
								<Bar data={data1} options={options}></Bar>
							</div>
							<div className='up-skill-section'>
								<div className='up-skill primary-border rounded-0'>
									<img width={60} src={Rocket} alt="UpSkills" className='mb-2' />
									<div className='d-flex gap-3 '>
										<h6>Up-skills for you</h6>
										<div className='arrow-icon'><i style={{ color: '#1fa0ff' }} className="fa-solid fa-arrow-right"></i></div>
									</div>
								</div>
								<div className='up-skill primary-border rounded-0'>
									<img width={60} src={Bulb} alt="Suggestions" className='mb-2' />
									<div className='d-flex gap-3 '>
										<h6>Suggestion for you</h6>
										<div className='arrow-icon'><i style={{ color: '#1fa0ff' }} className="fa-solid fa-arrow-right"></i></div>
									</div>
								</div>




							</div>
						</div>
					</div>
				</div>
			</Container>    <Footer />
		</>
	)
}

export default SelfAnalysis