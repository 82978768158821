import Select from 'react-select';
import { useState } from 'react';

function formatIconLabel(iconName: string): string {
    return iconName.replace(/^fa-/, '') // Remove 'fa-' prefix
        .split('-') // Split by '-'
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize
        .join(' '); // Join with space
}

const IconOption = ({ data, innerProps }: any) => (
    <div {...innerProps} className="d-flex align-items-center" style={{ padding: '8px 12px', cursor: 'pointer' }}>
        <span className='text-primary'><i className={`fa-solid ${data.value} fa-lg`}></i></span>
        <span className="ms-2" style={{ fontSize: '1rem' }}>{formatIconLabel(data.label)}</span>
    </div>
);

const SingleValue = ({ data }: any) => (
    <div className="d-flex align-items-center" style={{ cursor: 'pointer' }}>
        <span className='text-primary'><i className={`fa-solid ${data.value} fa-lg`}></i></span>
        <span className="ms-2" style={{ fontSize: '1rem' }}>{formatIconLabel(data.label)}</span>
    </div>
);

const filterIcons = (candidate: any, input: any) => {
    return candidate.data.keys.some((key: string) =>
        key.toLowerCase().includes(input.toLowerCase())
    );
};

const IconSelect = ({ icons, onIconChange }: any) => {
    const [selectedOption, setSelectedOption] = useState(null);

    const handleChange = (selectedOption: any) => {
        setSelectedOption(selectedOption);
        onIconChange && onIconChange(selectedOption.value);
    };

    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            boxShadow: "none",
            border: "1px solid #ced4da",
        }),
        option: (provided: any, state: any) => ({
            ...provided,
            color: state.isSelected ? '#fff' : '#495057',
            backgroundColor: state.isFocused ? '#007bff' : 'transparent',
            padding: '10px 20px',
            borderBottom: '1px solid #eff2f7',
            fontSize: '0.9rem',
        }),
        singleValue: (provided: any) => ({
            ...provided,
            color: '#495057',
        }),
    };

    // Preparing icon options, including the keys for filtering
    const iconOptions = icons.map((icon: any) => ({
        value: icon.icon,
        label: formatIconLabel(icon.icon),
        keys: icon.keys,
    }));

    return (
        <Select
            value={selectedOption}
            onChange={handleChange}
            options={iconOptions}
            components={{ Option: IconOption, SingleValue }}
            styles={customStyles}
            filterOption={filterIcons}
        />
    );
};

export default IconSelect;
