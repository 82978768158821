import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import { Link } from 'react-router-dom'
import { Container } from 'react-bootstrap'
import useScrollToTop from '../../hooks/useScrollTop'

const GriveancePolicy = ()=> {
  useScrollToTop();
  return (
    <>
    <Header/>
    <div className='policies-section'>
        <div className='first-row'>
            <h1> GRIVEANCE POLICY</h1>
        </div>
        <Container>
          <div className="list-section">
            <ol>
              <li>Company Overview</li>
              <p>
                <span className="fw-bold">
                  Naukridaddy HR Solutions Private Limited
                </span>
                , a company incorporated under the provisions of the Companies
                Act, 2013 and having its registered office at A-88 Adhyapak
                Nagar Nangloi, Nangloi, West Delhi, New Delhi, Delhi-110041,
                India (hereinafter referred to as{" "}
                <span className="fw-bold">‘Naurkridaddy’</span> or{" "}
                <span className="fw-bold">‘Company’</span> which expression
                shall include its successors, nominees, agents and permitted
                assigns).
              </p>
              <p>
                Naukridaddy, along with its domain
                <Link to="https://naukridaddy.com/signup">
                  {" "}
                  https://naukridaddy.com/signup{" "}
                </Link>
                , including any subdomains thereof (collectively referred to as{" "}
                <span className="fw-bold">‘Site’</span>), is designed
                exclusively for the initial facilitation of communication and
                information exchange among its registered users (hereinafter
                referred to as
                <span className="fw-bold">‘Member(s)’</span>) or unregistered
                users (hereinafter referred to as
                <span className="fw-bold"> ‘Visitor(s)’</span>) who possess a
                sincere intent to engage in or initiate contact pertaining to
                authentic, currently available employment opportunities and
                other services aimed at advancing their careers (the{" "}
                <span className="fw-bold">‘Services’</span>). The Members and
                the Visitors shall be collectively referred to as
                <span className="fw-bold"> ‘User(s)’</span>.
              </p>
              <li>Purpose of the Policy</li>
              <p>
                The Policy’s delineated grievance process establishes a framework for the Users to voice their concerns with reference to any issue, concern, problem, or claim (perceived or actual) that an User or individual or community group wants addressed by the Company in a formal manner (the <span className='fw-bold'>‘Grievance(s)’</span>) thereby enhancing transparency in the internal handling of Grievances. By doing so, the Company aspires to minimize conflicts and fortify relationships with the Users and other external stakeholders.
              </p>
              <li>Scope of the Policy</li>
              <p>
              The outlined procedure for the grievance mechanism is applicable to all Users and external stakeholders associated with our Site. It is pertinent to note that this procedure specifically excludes grievances raised by internal stakeholders, including employees, who are advised to refer to the Company's internal grievance standards.
              </p>
              <li>Grievance Reporting Channels</li>
              <p>
              The dissemination of this procedure to the Users and external stakeholders is intended to elevate awareness and foster transparency regarding the means through which the Users and external stakeholders can formally articulate their Grievances. Multiple formal channels will be instituted to facilitate the formal expression of concerns by external stakeholders.
              </p>
              <p className='fw-bold mt-3'>Stakeholders can connect with the Grievance Redressal Officer</p>
              <p className='fw-bold'>Name: [●]</p>
              <p className='fw-bold'>Designation: [●]</p>
              <p className='fw-bold'>Email: [●]</p>
              
              <li>Grievance Redressal Officer</li>
             <ol type='a'>
                <li><p>Should the User wish to register any complaints or Grievances pertaining to:</p></li>
                <ol type='i'>
                    <li><p>Any User content believed to contravene the terms of use or any other policy of the Company (with the exception of infringements on Intellectual Property Rights), and the appropriate mechanism for reporting such claims.</p>
                    </li>
                    <li><p>the User's access to the Site.</p></li>
                    <li><p>any User content perceived as prima facie inappropriate, defamatory towards the complainant or an individual on whose behalf the User is submitting the complaint, or displaying elements of electronic impersonation, which may include digitally altered images of such individual, kindly convey the same to the Company by means of written correspondence directed to [●].</p></li>
                </ol>
                <li><p>In the complaint or grievance, the User shall include the following information:</p></li>
                <ol type='i'>
                    <li><p>User name, contact details, address and email address;</p></li>
                    <li><p>User’s relation to the subject matter of the complaint, that is, complainant or person acting on behalf of an affected person;</p></li>
                    <li><p>The name and age of the person aggrieved or affected by the subject matter of the complaint. The User acting on behalf of the aggrieved shall provide an authorisation form in this regard stating that the User is authorised to act on behalf of such person and to provide such person's personal information to the Company in relation to the complaint/grievance;</p></li>
                    <li><p>Description of the complaint or grievance with clear identification of the User content in relation to which such complaint or grievance is made;</p></li>
                    <li><p>A statement that User believes, in good faith, that the User content violates these terms of use;</p></li>
                    <li><p>A statement that the information provided in the complaint or grievance is accurate.</p></li>
                </ol>
                <li><p>The Company respects the Intellectual Property Rights of others. All names, logos, marks, labels, trademarks, copyrights or intellectual and proprietary rights on the Site belonging to any person (including User), entity or third party are recognized as proprietary to the respective owners. The User is requested to communicate to The Company a written notice/ intimation if the User notices any act of infringement on the Site, which must include the following information:</p></li>
                <ol type='i'>
                    <li><p>A clear identification of the copyrighted work allegedly infringed;</p></li>
                    <li><p>A clear identification of the allegedly infringing material on the Site;</p></li>
                    <li><p>User’s details including name, address, e-mail address and phone number;</p></li>
                    <li><p>A statement that the User believes, in good faith, that the use of the copyrighted material allegedly infringed on the Site is not authorized by User’s agent or the law;</p></li>
                    <li><p>A statement that the information provided in the notice is accurate, and that the signatory is authorized to act on behalf of the owner of an exclusive copyright right that is allegedly infringed;</p></li>
                    <li><p>User’s signature or a signature of User’s authorized agent.</p></li>
                    <li><p>The aforesaid notices can be sent to the Company by email at [●].</p></li>
                </ol>
                <li><p>Upon the receipt of such a complaint, grievance, or notice, the Company retains the inherent right to conduct an investigation and/or take such measures as it deems appropriate. The Company may reach out to the User for the purpose of soliciting additional information or seeking the User's collaboration in the investigative process. This engagement may encompass the validation of assertions made in the User's complaint, grievance, or notification. The User acknowledges that their prompt cooperation in the investigation would substantially facilitate the resolution of the matter at hand.</p></li>
                
             </ol>
              <li className='pt-2'>Stages of Grievance post submission</li>
              <table className='table table-bordered table-hover mt-3'>
                <thead>
                <tr className='table-active'>
                    <th className='center'>S.No.</th>
                    <th className='text-center' style={{width:"20%"}}>Stage</th>
                    <th className='text-center'>Action</th>
                </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className='text-center'>
                            1.
                        </th>
                        <th>New Grievance</th>
                        <td>Upon receipt, all Grievances via the Grievance Redressal Officer are initially designated as "New" before progressing to the subsequent stage.</td>
                    </tr>
                    <tr>
                        <th className='text-center'>
                           2.
                        </th>
                        <th>Acknowledgement of Grievance</th>
                        <td>Following the "New" stage, every Grievance must be labelled as "Acknowledged," and a confirmation is to be dispatched to the User through email, as appropriate.</td>
                    </tr>
                    <tr>
                        <th className='text-center'>
                            3.
                        </th>
                        <th>Pending</th>
                        <td>All Grievances post “Acknowledge” has to be marked as “Pending”. In the pending stage the Grievance Redressal Officer investigates the Grievance to provide resolution to the User.</td>
                    </tr>
                    <tr>
                        <th className='text-center'>
                            4.
                        </th>
                        <th>Resolved</th>
                        <td>Upon completion of the investigation, the resolution of the Grievance aligns with the Company's terms and conditions, typically concluded within a span of two weeks from the date of reception of the Grievance. 

The User is duly informed about the avenues available should dissatisfaction persist. In the correspondence conveying the resolution via letter or email, the User is apprised that the Company will consider the matter closed unless a response is received within 8 weeks from the date of the provided resolution. 

Any Grievance subsequently becoming sub judice can be transitioned to the "resolved" status.</td>
                    </tr>
                    <tr>
                        <th className='text-center'>
                            5.
                        </th>
                        <th>Closed</th>
                        <td>A Grievance is considered as disposed off and is “closed” when
<p>(a) the request in the Grievance has been acceded to fully;</p>
<p> (b)where the User has indicated in writing, acceptance of the response;</p>
<p>(c)where the User has not responded further within 8 weeks of the Company’s written resolution response;</p>
<p>(d)where the Grievance Redressal Officer has certified that the Company has discharged its contractual, statutory and regulatory obligations and therefore closes the Grievance.</p></td>
                    </tr>
                </tbody>
              </table>
              <li>Storing of Grievances</li>
              <p>All records, including any grievance forms, investigation notes, interviews and minutes of meetings will be securely filed and confidentiality is maintained for all parties involved.</p>
              
              <li>Policy Review and Amendments</li>
              <p>This policy will be reviewed periodically to ensure compliance with regulatory changes and evolving fraud patterns. Amendments will be communicated to Users through our website and email notifications.</p>
              
             
            </ol>
          </div>

          <div className="d-flex align-items-center justify-content-center fw-bold">
            <h4>Thank You for using the Site!</h4>
          </div>
        </Container>
    </div>
    <Footer/>
    </>
  )
}

export default GriveancePolicy