import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Button, Container } from "react-bootstrap";
import Dashboard_screen from "../../src/assets/img/dashboard-screen.png";
import SearchComponent from "../components/Search";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import useScrollToTop from "../hooks/useScrollTop";
import CompaniesByIndustry from "../components/CompaniesByIndustry";


const FindCompany = () => {
	const [companies, setCompanies] = useState<any>({});

	const navigate = useNavigate();
	useScrollToTop();
	const fetchCompanies = async () => {
		try {
			const { data } = await axios.get("/api/search/companies");
			setCompanies(data);
		} catch (error) {
			console.log(error);
		}
	};

	const searhCompanies = (inp1: string, inp2: string) => {
		if (!inp1) return;
		let searchStr = "?term=" + encodeURIComponent(inp1);
		if (inp2) searchStr += "&location=" + encodeURIComponent(inp2);
		navigate("/find-companies" + searchStr);
	};


	useEffect(() => {
		fetchCompanies();
	}, []);



	return (
		<>
			<div className="find-company-section">
				<Header />
				{/* first row start */}
				<div className="first-container">
					<div className="find-talent-upper">
						<div>
							<h1>
								{" "}
								Find your <span className="">dream companies</span>
							</h1>
						</div>

						<div className="big-para">
							Find your next career at companies like HubSpot, Nike, and Dropbox
						</div>

						<SearchComponent
							onSearchTrigger={searhCompanies}
							page={"companies"}
						/>
					</div>
					<p className="para">Popular : Meta, Microsoft, Google, Twitter</p>
				</div>
				<Container>
					{/* recommended companies section */}
					<div className="recommended-section">
						<div className="first-row">
							<h3>Recommended Companies</h3>
							<p className="para">
								Based on your profile, company preferences, and recent activity
							</p>
						</div>
						<div className="company-boxes">
							{companies?.hits?.hits?.map((company: any, index: number) => {
								return (
									<div className="company-box primary-border">
										<Link to={"/company-profile/" + company._source.id}>
											<div className="brand-job">
												<div className="brand-icon">
													<img
														src={company?._source.companyLogo}
														width={"70px"}
														alt=""
													></img>
												</div>
												<div className="gray-bg-btn">
													{" "}
													{company?._source.jobCount} Jobs
												</div>
											</div>
											<h4>{company?._source.company}</h4>
											{company?._source.industry.map((industry: any) => {
												return <div className="marketing-btn d-inline-block">{industry}</div>;
											})}
											<p className="para mt-2">
												{company._source.companyDescription}
											</p>
										</Link>
									</div>
								);
							})}
							{/* <div className="company-box primary-border">
                <Link to="/company-profile">
                  <div className="brand-job">
                    <div className="brand-icon">
                      <img src={Divy} width={"70px"} alt=""/>
                    </div>
                    <div className="gray-bg-btn"> 3 Jobs</div>
                  </div>
                  <h4>Name Brand</h4>

                  <div className="marketing-btn d-inline-block">Marketing</div>

                  <p className="para mt-2">
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ea
                    tempore enim sunt provident laboriosam vel consequatur
                    eveniet, veritatis similique atque ratione mollitia modi et
                    alias a esse quam ipsum reiciendis blanditiis soluta
                    voluptatem vitae. Neque quibusdam blanditiis adipisci veniam
                    mollitia vitae nam commodi ducimus sequi!
                  </p>
                </Link>
              </div> */}
						</div>
					</div>

					{/* post-job poster section */}

					<div className="posting-job-poster">
						<div className="start-post-job">
							<div className="">
								<h1>Start posting jobs today</h1>
							</div>
							<p>Start your job posting to get candidates</p>
							<Button onClick={() => navigate("/signup?target=employer")}>
								<Link to="/signup">Sign Up For Free</Link>
							</Button>
						</div>
						<div className="dashboard-img">
							<img src={Dashboard_screen} width={"500px"} alt="" />
						</div>
					</div>
				</Container>
				<div className="footer-section">
					<CompaniesByIndustry />
				</div>
				<Footer />
			</div>
		</>
	);
};

export default FindCompany;
