import { Button } from "react-bootstrap";

const EmployerDashboard = () => {
  return (
    <>
      <div className="dashboard-section">
        <div className="dash-top">
          <div className="">
            <h4>Good Day!, Naukridaddy </h4>
            <p className="para">
              Here is your job listings statistic report from July 19 - July 25.
            </p>
          </div>
          <div className="date-btn">
            <Button>
              {" "}
              July 19 - July 25 <i className="fa-solid fa-calendar-week"></i>{" "}
            </Button>
          </div>
        </div>
        <div className="big-buttons">
          <Button className="review-button">
            <div className="big-number">76</div>
            <p>New candidates to review</p>
            <i className="fa-solid fa-angle-right"></i>
          </Button>
          <Button className="schedule-button">
            <div className="big-number">24</div>
            <p>Schedule for today</p>
            <i className="fa-solid fa-angle-right"></i>
          </Button>
          <Button className="interview-button">
            <div className="big-number">11</div>
            <p>Interviews Done</p>
            <i className="fa-solid fa-angle-right"></i>
          </Button>
        </div>
        <div className="d-flex justify-content-between mt-2 flex-wrap">
          <div className="job-statics-box primary-border">
            <div className="d-flex justify-content-between p-3">
              <div>
                <h5>Job statistics</h5>
                <p className="para">Showing Jobstatistic Jul 19-25</p>
              </div>
              <div className="year-btn">
                <Button className="active">Week</Button>
                <Button>Month</Button>
                <Button>Year</Button>
              </div>
            </div>
            <div className="job-statistic-navbar my-2">
              <div className="nav-job d-flex justify-content-between">
                <div className="nav-item active">Overview</div>
                <div className="nav-item">Jobs </div>
                <div className="nav-item">Applied</div>
              </div>
            </div>

            <div className="d-flex justify-content-center">
              <h4>TBD</h4>
            </div>
          </div>
          <div className="open-job-applicant-summary d-flex flex-column gap-4">
            <div className="open-job-box primary-border">
              <h5>Job Open</h5>
              <div className="big-number">
                <span className="fw-bold">12</span>
                <span className="para fs-5 px-2">Jobs Opened</span>
              </div>
            </div>
            <div className="applicant-summary-box primary-border">
              <h5>Applicant sumary</h5>
              <div className="big-number">
                <span className="fw-bold">67</span>
                <span className="para fs-5 px-2">Applicants </span>
              </div>
              <div className="d-flex justify-content-center p-4">
                <h4>Chart</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="job-update-box primary-border">
          <div className="update-header d-flex justify-content-between">
            <h5>Job Updates</h5>
            <div className="simple-btn">
              <Button className="px-0 bg-transparent">
                <span>View All</span>{" "}
                <i className="px-1 fa-solid fa-arrow-right"></i>{" "}
              </Button>
            </div>
          </div>
          <div className="d-flex p-4 justify-content-between">
            <div className="job-list-box primary-border">
              <div className="d-flex justify-content-between">
                <div className="brand-icon"><img width={"40px"} src="https://static-00.iconduck.com/assets.00/dropbox-icon-512x435-vh5gehsg.png" alt="" /></div>
                <div><Button className="full-time-btn">Full-Time</Button></div>
              </div>
              <h6 className="m-0">Brand Designer</h6>
              <p className="para">Dropbox <span className=""> . </span>Delhi, India</p>
              <div className="d-flex gap-2">
                <Button className="business-btn">Business</Button>
                <Button className="design-btn">Design</Button>
              </div>
              <div className="d-flex">
                <div className="green-slider"></div>
                <div className="gray-slider"></div>
              </div>
              <div className="d-flex gap-1"><h6>5 Applied</h6> <span className="para"> of 10  Capacity</span></div>
            </div>


            <div className="job-list-box primary-border">
              <div className="d-flex justify-content-between">
                <div className="brand-icon"><img width={"40px"} src="https://static-00.iconduck.com/assets.00/dropbox-icon-512x435-vh5gehsg.png" alt="" /></div>
                <div><Button className="full-time-btn">Full-Time</Button></div>
              </div>
              <h6 className="m-0">Brand Designer</h6>
              <p className="para">Dropbox <span className="dot"> . </span>Delhi, India</p>
              <div className="d-flex gap-2">
                <Button className="business-btn">Business</Button>
                <Button className="design-btn">Design</Button>
              </div>
              <div className="d-flex">
                <div className="green-slider"></div>
                <div className="gray-slider"></div>
              </div>
              <div className="d-flex gap-1"><h6>5 Applied</h6> <span className="para"> of 10  Capacity</span></div>
            </div>

            <div className="job-list-box primary-border">
              <div className="d-flex justify-content-between">
                <div className="brand-icon"><img width={"40px"} src="https://static-00.iconduck.com/assets.00/dropbox-icon-512x435-vh5gehsg.png" alt="" /></div>
                <div><Button className="full-time-btn">Full-Time</Button></div>
              </div>
              <h6 className="m-0">Brand Designer</h6>
              <p className="para">Dropbox <span className="dot"> . </span>Delhi, India</p>
              <div className="d-flex gap-2">
                <Button className="business-btn">Business</Button>
                <Button className="design-btn">Design</Button>
              </div>
              <div className="d-flex">
                <div className="green-slider"></div>
                <div className="gray-slider"></div>
              </div>
              <div className="d-flex gap-1"><h6>5 Applied</h6> <span className="para"> of 10  Capacity</span></div>
            </div>


            <div className="job-list-box primary-border">
              <div className="d-flex justify-content-between">
                <div className="brand-icon"><img width={"40px"} src="https://static-00.iconduck.com/assets.00/dropbox-icon-512x435-vh5gehsg.png" alt="" /></div>
                <div><Button className="full-time-btn">Full-Time</Button></div>
              </div>
              <h6 className="m-0">Brand Designer</h6>
              <p className="para">Dropbox <span className="dot"> . </span>Delhi, India</p>
              <div className="d-flex gap-2">
                <Button className="business-btn">Business</Button>
                <Button className="design-btn">Design</Button>
              </div>
              <div className="d-flex">
                <div className="green-slider"></div>
                <div className="gray-slider"></div>
              </div>
              <div className="d-flex gap-1"><h6>5 Applied</h6> <span className="para"> of 10  Capacity</span></div>
            </div>
           
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployerDashboard;
