import React, { useState } from "react";

import StaticLogo from '../assets/img/Dark Transparent.png'
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";

function Footer() {
	const [email, setEmail] = useState('');
	const [message, setMessage] = useState('');
	const [error, setError] = useState('');
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setEmail(value);
		if (!validateEmail(value)) {
			setError('Please enter a valid email address');
		} else {
			setError('');
		}
	};

	const validateEmail = (email: string) => {
		// Regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	};

	const handleSubmit = async () => {
		if (!validateEmail(email)) {
			setTimeout(() => {
				setError('Please enter a valid email address');
			}, 4000)
		} else {
			await axios.post('/api/no-auth/subscribe', { Email: email });
			setMessage(`Thank you for subscribing. You will now start receiving our latest news and job updates.`);
			setTimeout(() => {
				setMessage('');
			}, 10000); // 10 seconds in milliseconds
			setEmail('');
			setError('');
		}
	};
	return (
		<>
			<div className="container-fluied footer">
				<div className="container">
					<div className="row">
						<div className="col-lg-4 col-md-6 col-sm-12">
							<div className="brands_logo">
								<Link to='/' className="">
									<img src={StaticLogo} alt="Logo" width='200px' />
								</Link>
							</div>
							<div className="para  mt-4" style={{ paddingRight: "30px", color: 'rgb(255, 255, 255)' }}>
								Great plateform for the job seeker that passionate  about
								startups. Find your dream job easier.
							</div>
						</div>

						<div className="col-lg-2 col-md-6 col-sm-12">
							<h4>About</h4>
							<p className="para"><Link to=''> Companies</Link></p>
							<p className="para"><Link to='/pricing-plans'> Pricing </Link></p>
							<p className="para"><Link to=''> Terms </Link></p>
							<p className="para"><Link to=''> Projects</Link></p>
							<p className="para"><Link to='/fraud-alert'> Fraud alert </Link></p>
							<p className="para"><Link to='/griveance-policy'> Griveance Policy </Link></p>
							<p className="para"><Link to='/cookies-policy'> Cookies policy </Link></p>

						</div>
						<div className="col-md-6 col-lg-2 col-sm-12">
							<h4>Resources</h4>
							<p className="para"><Link to='/help-docs'> Help Docs</Link></p>
							<p className="para"><Link to='/guides-and-tutorials'> Guide</Link></p>
							<p className="para"><Link to='/blogs-and-resources'>Resources</Link></p>
							<p className="para"><Link to=''> Contact Us</Link></p>
							<p className="para"><Link to='/terms-use'> Terms of use</Link></p>
							<p className="para"><Link to='/privacy-policy'> Privacy Policy</Link></p>

						</div>
						<div className="col-md-6 col-lg-4 col-sm-12">
							<h4 style={{ marginBottom: "40px" }}>Get job notifications</h4>
							<div style={{ marginRight: "130px", color: '#fff' }} className="">
								The latest job news, articles sent to
								your  inbox  weekly.
							</div>
							<div className="d-flex gap-2 mt-4">
								<div className="input-email-box">

									<i className="fa-solid fa-magnifying-glass"></i>{" "}
									<input
										className=""
										placeholder="Email Address"
										type="email"
										value={email}
										onChange={handleInputChange}

									></input>{" "}
								</div>
								<Button onClick={handleSubmit} className="primary-btn">
									Subscribe
								</Button>
							</div>
							<div className="mt-1 p-2  text-danger">{error}</div>
							<div style={{ fontSize: "17px", color: "#fff" }}>{message}</div>


						</div>
						<div id="horizontal-line"></div>
						<div className="copyright d-flex flex-wrap gap-3">
							<div className="copy-text">&copy; {new Date().getFullYear()} <span className="fw-bold">NaukriDaddy</span> All rights reserved.</div>
							<div className="social-icons d-flex">
								<a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/profile.php?id=100094839247157&sk=about" className="icon"> <i className="fa-brands fa-facebook-f"></i></a>
								<a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/naukridaddy" className="icon"> <i className="fa-brands fa-instagram"></i></a>
								<a rel="noopener noreferrer" target="_blank" href="https://www.threads.net/@naukridaddy" className="icon"><i className="fa-brands fa-threads"></i></a>
								<a rel="noopener noreferrer" target="_blank" href="https://www.linkedin.com/company/94835533/admin/feed/posts" className="icon"> <i className="fa-brands fa-linkedin-in"></i></a>
								<a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCfppxt3D2-dKVuv2xXTNEsg" className="icon"> <i className="fa-brands fa-youtube"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Footer;
