import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const QuillInput = ({ placeholder = '', onChange, maxLength = 500 }: any) => {
    const [text, setText] = useState('');

    const quillModule = {
        toolbar: [
            ['bold', 'italic'], 
            [{ 'list': 'ordered' }, { 'list': 'bullet' }], 
            ['link'] 
        ]
    };

    const handleChange = (content: any, delta: any, source: any, editor: any) => {
        const currentText = editor.getText();
        const adjustedLength = currentText.trimEnd().length;

        if (adjustedLength <= maxLength) {
            setText(currentText);
            onChange && onChange(content);
        }
    };

    useEffect(() => {
        if (onChange) {
            onChange(text);
        }
    }, [text, onChange]);

    return (
        <>
            <ReactQuill
            modules={quillModule}
                onChange={handleChange}
                className='quill-input'
                placeholder={placeholder}
                theme="snow"
            />
            <div style={{ color: "#A8ADB7", fontSize: "15px" }} className="d-flex text-light-gray justify-content-between">
                <span className="py-2">Maximum {maxLength} characters</span>
                <span className="py-2">{text.trimEnd().length}/{maxLength}</span>
            </div>
        </>
    )
}

export default QuillInput;
