import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import DataTable, { type TableColumn } from "react-data-table-component";
import axios from "../../utils/axios";
import moment from 'moment'

const EmployerJoblisting = () => {

    const [jobList, setJobList] = useState([]);

    const columns: Array<TableColumn<any>> = [
        {
            name: "Roles",
            selector: (row: any) => row.JobTitle
        },
        {
            name: "Status",
            // selector: (row: any) => row.JobStatus,
            cell(row, rowIndex, column, id) {
                const variant = row.JobStatus == 'Live' ? 'outline-success' : 'outline-danger'
                return (<Button type="button" variant={variant} className="rounded-pill">{row.JobStatus}</Button>)
            },
        },
        {
            name: "Date Posted",
            selector: (row: any) => moment(row.CreatedAt).format('DD MMM YYYY')
        },
        {
            name: "Due Date",
            selector: (row: any) => row.DueDate && moment(row.DueDate).format('DD MMM YYYY')
        },
        {
            name: "Job Type",
            // selector: (row: any) => row.EmploymentType[0],
            cell(row, rowIndex, column, id) {

                const variant = row.EmploymentType[0] == 'Full-Time' ? 'outline-primary' : 'outline-warning'
                return (<Button type="button" variant={variant} className="rounded-pill">{row.EmploymentType[0]}</Button>)
            }
        },
        {
            name: "Applicants",
            selector: (row: any) => row.ApplicantsCount
        },
        {
            name: "Needs",
            cell({ ApplicantsSelected, ApplicantsNeeded }, rowIndex, column, id) {

                return (<span>{ApplicantsSelected}/<span className={ApplicantsSelected != ApplicantsNeeded ? 'text-muted' : ''}>{ApplicantsNeeded}</span></span>)
            },
        },
        {
            name: "Action",
            selector: (row: { dot: any; }) => '...'
        },
    ]

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                const { data } = await axios.get('/api/job/list');
                setJobList(data)
                console.log(data);

            } catch (error) {
                console.log(error);

            }
        }

        fetchJobs()
    }, [])

    return (<div className="joblist-section">
        <div className="joblist-header">
            <div className="">
                <h4>Job Lists</h4>
            </div>
            <div className="">
                <div className="search-box primary-border">
                    <i className="fa-solid fa-magnifying-glass"></i> <input type="search" placeholder="Search Applicants"></input>
                </div>
                <Button className="filter-btn primary-border">
                    <i className="fa-solid fa-arrow-down-short-wide"></i> Filter
                </Button>
            </div>
        </div>
        <DataTable columns={columns} data={jobList} fixedHeader pagination ></DataTable>
    </div>)
};

export default EmployerJoblisting;
