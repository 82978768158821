import { Button, Col, FormGroup, FormLabel, } from 'react-bootstrap'
import LoginBanner from "../components/LoginBanner";
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from "../utils/axios";
import { useForm } from 'react-hook-form';
import ResetPassword from '../components/ResetPassword';
import EmailVerified from '../components/EmailVerified';


interface LoginData {
    Email: string;
    Password: string;
}

const Login2 = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const authSuccess = queryParams.get('auth_success');
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm<LoginData>()
    const [isJobseeker, setIsJobseeker] = useState((queryParams.get('t') === 'employer' || queryParams.get('target') === 'employer') ? false : true);
    const [message, setMessage] = useState('');
    const [screen, setScreen] = useState(queryParams.get('a') === 'success' ? 'verified' : 'login')

    useEffect(() => {
        const checkLoginSuccess = async () => {
            try {
                const { data } = await axios.post('/api/auth/google/login', { token: authSuccess });
                localStorage.setItem('nd_sess', data.accessToken)
                if (data.incompleteInfo) return navigate('/complete-candidate-info')
                else navigate('/dashboard');
            } catch (error) {
                navigate('/')
            }
        }

        if (authSuccess) checkLoginSuccess();
    }, [authSuccess, navigate])

    const triggerGoogleLogin = () => {
        window.location.href = "/api/auth/google";
    }

    const onSubmit = async (loginData: LoginData) => {
        try {
            setMessage('')
            const { data } = await axios.post('/api/auth/login', loginData);
            localStorage.setItem('nd_sess', data.accessToken);
            if (data.userType === 'Employer') {
                localStorage.setItem('ut', 'rc')
                navigate('/employer/dashboard');
            } else {
                localStorage.setItem('ut', 'js')
                if (data.incompleteInfo) return navigate('/complete-candidate-info')
                else navigate('/jobseeker/profile');
            }

        } catch (error: any) {
            if (error.response) {
                if (error.response.data.message === 'unverified_email') {
                    setMessage('You have not verified your email adress. Please follow the link sent on your email address to verify.')
                } else {
                    setMessage('Invalid email or password');
                }
            }
        }
    }

    const rules = {
        Email: register('Email', {
            required: "Email is required",
            pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: "Invalid email address"
            }
        }),
        Password: register('Password', {
            required: "Password is required",
        })
    }

    return (
        // <Container fluid>
        <div className='login d-flex'>
            <Col className='left' md={5} sm={5} style={{ background: '#eefaf7', position: 'relative', height: '100vh' }}>
                <LoginBanner />
            </Col>
            <Col sm={7} md={7} className='form-section'>

                {screen == 'login' && <div style={{ paddingTop: "70px" }} className="login_section">
                    <div className='d-flex justify-content-center'>
                        <Button onClick={() => setIsJobseeker(true)} className={'type-switch-btn px-3 mx-2 py-2' + (isJobseeker ? ' active' : '')}>Job Seeker</Button>
                        <Button onClick={() => setIsJobseeker(false)} className={'type-switch-btn px-3  border-0 py-2' + (!isJobseeker ? ' active' : '')}>Company</Button>
                    </div>
                    <div className='text-center py-3 heading'><span>Welcome back</span></div>
                    {isJobseeker ? <> <Button onClick={triggerGoogleLogin} style={{ padding: "12px 0px", border: "1px solid #12DAFB", fontSize: "15px" }} className='w-100 bg-transparent text-dark fw-bolder rounded-5'> <span className='px-3 w-2'><img src='https://static-00.iconduck.com/assets.00/google-icon-2048x2048-czn3g8x8.png' alt='' width={"18px"} /></span>Login with Google</Button>
                        <div className="divider">
                            <span className="divider-line"></span>
                            <span className="divider-text">Or login with email</span>
                            <span className="divider-line"></span>
                        </div></> : ''}
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <FormGroup className='mb-2'>
                            <FormLabel className='fw-bolder text-dark'>Email Address</FormLabel>
                            <input {...rules.Email} className='form-control rounded-5' type="text" placeholder='Enter email address' />
                            {errors.Email && <span className="error">{'' + errors.Email.message}</span>}
                        </FormGroup>
                        <FormGroup className='mb-3'>
                            <FormLabel className='fw-bolder text-dark'>Password</FormLabel>
                            <input {...rules.Password} className='form-control rounded-5' type="password" placeholder='Enter password' />
                            {errors.Password && <div className="error">{'' + errors.Password.message}</div>}
                        </FormGroup>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center mt-2 gap-2'><input className='largerCheckbox' type='checkbox' />
                                <p className='text-secondary  py-0 m-0'>Remember me</p></div>
                            <button className='forget-btn' type='button' onClick={() => setScreen('reset')}>Reset Password</button>
                        </div>
                        {message ? <p className='error mt-3'>{message}</p> : ''}
                        <Button type="submit" className='login_button w-100 rounded-5'>Login</Button>
                        <p className='text-secondary fs-6 mt-3 '>Don't have an account?  <Link className='px-2 fw-bold' style={{ color: "#1FA0FF" }} to="/signup">Sign Up</Link></p>
                    </form>
                </div>}

                {screen == 'reset' && <ResetPassword goBack={setScreen} />}

                {screen == 'verified' && <EmailVerified goBack={setScreen} />}
            </Col>
        </div>
        // </Container>
    )
}


export default Login2